import { TRPCClientError } from '@trpc/client';
import * as Sentry from '@sentry/browser';

export const ErrorMessages = {
  unknown: 'Ein Fehler ist aufgetreten. Bitte warten Sie eine Minute und versuchen Sie es dann erneut.',
  rate_limited: 'Zu viele Anfragen. Bitte versuchen Sie es später erneut.',
  wrong_email_or_password: 'Die E-Mail-Adresse oder das Passwort ist falsch.',
  wrong_username_or_password: 'Der Benutzername oder das Passwort ist falsch.',
  wrong_password: 'Das eingegebene Passwort ist falsch.',
  email_exists: 'Diese E-Mail-Adresse ist bereits registriert.',
  passwords_dont_match: 'Die Passwörter stimmen nicht überein.',
  account_not_activated: 'Ihr Account ist noch nicht aktiviert. Bitte überprüfen Sie Ihren Posteingang.',
  account_activation_error:
    'Bei der Aktivierung des Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Gegebenenfalls ist das Konto bereits aktiviert.',
  account_locked:
    'Ihr Account wurde aus einem oder mehreren Gründen gesperrt. Bitte respektieren Sie unsere Entscheidung. Wir werden darauf weder telefonisch noch per E-Mail weiter eingehen.',
  invalid_activation_token: 'Der Link zum Bestätigen der E-Mail-Adresse scheint ungültig zu sein.',
  invalid_email_change_token: 'Der Link zum Bestätigen der neuen E-Mail-Adresse scheint ungültig zu sein.',
  email_change_not_possible: 'Eine Änderung auf diese E-Mail-Adresse ist nicht möglich. Bitte wählen Sie eine andere.',
  logo_too_small: 'Das Bild muss mindestens 400 Pixel breit oder hoch sein.',
  logo_file_too_big: 'Das Bild darf maximal 5 MB groß sein.',
  logo_invalid_mime_type: 'Das Bild muss im PNG-, JPEG-, WEBP- oder SVG-Format vorliegen.',
  invalid_vat_id:
    'Die Umsatzsteuer-ID ist ungültig oder nicht auf die angegebene Adresse registriert. Bitte prüfen Sie die Daten.',
  missing_job_title: 'Bitte geben Sie den Anzeigentitel an.',
  missing_job_description: 'Bitte geben Sie die Jobbeschreibung an.',
  invalid_job_description: 'Die angegebene Jobbeschreibung ist ungültig.',
  missing_job_locations: 'Bitte geben Sie den oder die Arbeitsort/e an.',
  missing_occupation_types: 'Bitte geben Sie das oder die Beschäftigungsverhältnis/se an.',
  job_cannot_be_edited_anymore: 'Diese Anzeige kann nicht mehr bearbeitet werden.',
  job_cannot_be_deleted_anymore: 'Diese Anzeige kann nicht mehr gelöscht werden. Eventuell wurde sie bereits gelöscht.',
  advertiser_data_outdated: 'Die Stamm- oder Rechnungsdaten sind nicht mehr aktuell.',
  terms_not_accepted: 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen.',
  paypal_error: 'Bei der Zahlung mit PayPal ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  generic_support: 'Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support.',
  open_jobs_or_invoices_current:
    'Ihr Account kann derzeit nicht gelöscht werden, da Sie entweder Anzeigen online und/oder Rechnungen offen haben.',
  unknown_coupon: 'Dieser Code ist unbekannt. Bitte prüfen Sie ggf. die Schreibweise.',
  coupon_already_used: 'Dieser Code wurde bereits eingelöst.',
  coupon_already_exists: 'Dieser Code existiert bereits für den gewählten Service.',
  coupon_expired: 'Dieser Code ist abgelaufen.',
  lexoffice_invoice_error: 'Ein Problem beim Erstellen oder Bearbeiten der Rechnung in Lexoffice ist aufgetreten.',
  geolocation_no_permission:
    'Dein Standort konnte nicht ermittelt werden. Bitte prüfe deine Browser- oder Geräte­einstellungen.',
  geolocation_not_supported: 'Dein Browser unterstützt die Standortbestimmung nicht.',
  vat_id_already_set: 'Die Umsatzsteuer-ID wurde bereits festgelegt und kann nicht mehr geändert werden.',
  too_many_jobletter_subscriptions:
    'Du bist bereits für zu viele Jobletter angemeldet. Bitte melde dich sich von einem Jobletter ab, um dich für einen anderen anzumelden.',
  existing_jobletter_subscription_for_city: 'Du bist bereits für den Jobletter in dieser Stadt angemeldet.',
  invalid_jobletter_confirmation_token:
    'Der Link zum Bestätigen der Anmeldung scheint ungültig oder abgelaufen zu sein.',
  invalid_email: 'Die angegebene E-Mail-Adresse ist ungültig.',
  jobletter_no_location_selected: 'Bitte wähle einen Ort aus.',
  invalid_jobletter_opt_out_token:
    'Der Link zum Abmelden scheint ungültig zu sein. Eventuell wurdest du bereits abgemeldet.',
  maintenance_mode: 'Wir führen derzeit Wartungsarbeiten durch. Bitte versuchen Sie es später erneut.',
  free_option_already_used:
    'Sie haben diesen Monat bereits die maximale Anzahl an kostenlosen Stellenanzeigen veröffentlicht.',
  // general tRPC errors
  trpc_bad_request: 'Bitte prüfen Sie Ihre Eingaben.',
  trpc_unauthorized: 'Sie sind nicht autorisiert, diese Aktion auszuführen. Bitte melden Sie sich an.',
  trpc_forbidden: 'Sie haben keinen Zugriff auf diese Ressource.',
  trpc_not_found: 'Die angeforderte Ressource wurde nicht gefunden.',
  trpc_timeout: 'Die Anfrage ist abgelaufen. Bitte versuchen Sie es erneut.',
  trpc_conflict: 'Die Anfrage konnte nicht ausgeführt werden, da sie mit einem bereits vorhandenen Eintrag kollidiert.',
  trpc_precondition_failed: 'Ein erforderlicher Wert fehlt oder ist ungültig.',
  trpc_payload_too_large: 'Die hochgeladene Datei ist zu groß.',
  trpc_method_not_supported:
    'Die angeforderte Methode ist für diese Ressource nicht verfügbar. Bitte versuchen Sie es erneut.',
  trpc_unprocessable_content: 'Die Anfrage konnte nicht verarbeitet werden. Bitte prüfen Sie Ihre Eingaben.',
  trpc_too_many_requests: 'Sie haben zu viele Anfragen gesendet. Bitte versuchen Sie es später erneut.',
  trpc_client_closed_request: 'Die Anfrage wurde abgebrochen.',
  trpc_internal_server_error: 'Ein interner Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
} as const;

export const transformTRPCErrorToMessage = (e: any) => {
  if (e instanceof TRPCClientError) {
    if (e.data?.apiError?.code) {
      return ErrorMessages[e.data.apiError.code as keyof typeof ErrorMessages];
    } else if (typeof e.data?.code === 'string') {
      Sentry.captureException(e);
      return ErrorMessages[`trpc_${e.data.code.toLowerCase()}` as keyof typeof ErrorMessages];
    }
  }
  return ErrorMessages.unknown;
};
