import { AdvertiserType, Salutation } from '@prisma/client';
import { useCallback, useEffect, useState } from 'react';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { useCreateAdvertiser, useRequestNewActivationEmail } from '../hooks/query/Advertiser';
import { TRPCClientError } from '@trpc/client';
import { Link, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { MainOutletContext } from '../MainOutlet';

type Field =
  | 'businessName'
  | 'businessAppendix'
  | 'salutation'
  | 'firstName'
  | 'lastName'
  | 'street'
  | 'zip'
  | 'city'
  | 'country'
  | 'phone'
  | 'email'
  | 'password'
  | 'terms';
const useSignupController = () => {
  const navigate = useNavigate();
  const { service } = useOutletContext<MainOutletContext>();
  const [searchParams] = useSearchParams();
  const [advertiserType, setAdvertiserType] = useState<AdvertiserType | null>(
    service.advertiserTypes.length === 1 ? service.advertiserTypes[0] : null
  );
  const [businessName, setBusinessName] = useState<string>('');
  const [businessNameAppendix, setBusinessNameAppendix] = useState<string>('');
  const [salutation, setSalutation] = useState<Salutation | null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<keyof typeof Constants.COUNTRY_LIST>('DE');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [terms, setTerms] = useState<boolean>(false);
  const [error, setError] = useState<(string | React.ReactNode)[] | null>(null);
  const [errorFields, setErrorFields] = useState<Field[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const { mutate: signup, isLoading: isSigningUp } = useCreateAdvertiser({
    onSuccess: () => setSuccess(true),
    onError: (e) => {
      const errors = new Set<string>();
      if (e instanceof TRPCClientError) {
        if (e.data?.zodError) {
          for (const issue of e.data.zodError.issues) {
            if (issue.path[0] === 'businessName') errors.add('Bitte geben Sie einen Unternehmensnamen ein.');
            if (issue.path[0] === 'salutation') errors.add('Bitte wählen Sie eine Anrede aus.');
            if (issue.path[0] === 'firstName') errors.add('Bitte geben Sie Ihren Vornamen ein.');
            if (issue.path[0] === 'lastName') errors.add('Bitte geben Sie Ihren Nachnamen ein.');
            if (issue.path[0] === 'street') errors.add('Bitte geben Sie Ihre Straße ein.');
            if (issue.path[0] === 'zip') errors.add('Bitte geben Sie Ihre Postleitzahl ein.');
            if (issue.path[0] === 'city') errors.add('Bitte geben Sie Ihren Wohnort ein.');
            if (issue.path[0] === 'phone') {
              if (issue.code === 'too_small') errors.add('Bitte geben Sie Ihre Telefonnummer ein.');
              else errors.add('Die angegebene Telefonnummer ist ungültig.');
            }
            if (issue.path[0] === 'email') {
              if (issue.code === 'too_small') errors.add('Bitte geben Sie Ihre E-Mail-Adresse ein.');
              else errors.add('Die angegebene E-Mail-Adresse ist ungültig.');
            }
            if (issue.path[0] === 'password') {
              if (issue.code === 'too_small') errors.add('Bitte geben Sie ein Passwort mit mindestens 8 Zeichen ein.');
              else
                errors.add(
                  'Das angegebene Passwort ist ungültig. Es muss mindestens 8 Zeichen lang sein, sowie mindestens eine Zahl, einen Buchstaben und ein Sonderzeichen (außer Leerzeichen) enthalten.'
                );
            }
          }

          if (errors.size > 0) {
            setError(Array.from(errors));
            setErrorFields(e.data.zodError.issues.map((issue: any) => issue.path[0]));
            return;
          }
        } else if (e.data?.apiError) {
          const errorCode = e.data.apiError.code as keyof typeof ErrorMessages;
          if (errorCode === 'account_not_activated') {
            setError([
              <>
                <p>
                  <strong>Sie sind bereits registriert</strong>, aber Sie haben Ihren Account{' '}
                  <strong>noch nicht aktiviert</strong>!
                </p>
                <p className="!mb-0">
                  Falls Sie die E-Mail zur Aktivierung nicht erhalten haben, können Sie{' '}
                  <span onClick={() => requestNewActivationEmail({ email })} className="underline cursor-pointer">
                    diese erneut anfordern
                  </span>
                  .
                </p>
              </>,
            ]);
            setErrorFields([]);
            return;
          } else if (errorCode === 'email_exists') {
            setError([
              <>
                <p>
                  Sie sind bereits mit der E-Mail-Adresse <strong>{email}</strong> registriert.
                </p>
                <p className="!mb-0">
                  Falls Sie Ihr Passwort nicht mehr wissen, können Sie{' '}
                  <Link to="/passwort-vergessen" className="underline">
                    hier ein neues anfordern
                  </Link>
                  .
                </p>
              </>,
            ]);
            setErrorFields([]);
            return;
          }
        }
        setError([transformTRPCErrorToMessage(e)]);
        setErrorFields([]);
      }
    },
  });

  const { mutate: requestNewActivationEmail } = useRequestNewActivationEmail({
    onSuccess: () => {
      navigate(`/registrieren?resent=${encodeURIComponent(email)}`);
    },
    onError: (e) => {
      setError([transformTRPCErrorToMessage(e)]);
      setErrorFields([]);
    },
  });

  useEffect(() => {
    if (searchParams.get('resent')?.length && Constants.EMAIL_REGEX.test(searchParams.get('resent')!)) {
      setSuccess(true);
      setEmail(searchParams.get('resent')!);
    } else {
      setSuccess(false);
      setEmail('');
    }
  }, [searchParams]);

  useEffect(() => {
    if (advertiserType === AdvertiserType.PRIVATE) {
      setBusinessName('');
      setBusinessNameAppendix('');
    }
  }, [advertiserType]);

  useEffect(() => {
    if (service.advertiserTypes.length === 1) {
      setAdvertiserType(service.advertiserTypes[0]);
    }
  }, [service.advertiserTypes]);

  useEffect(() => {
    if (error || success) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error, success]);

  const handleBack = useCallback(() => {
    if (advertiserType === null || service.advertiserTypes.length === 1) {
      navigate('/');
      return;
    }
    setAdvertiserType(null);
    setBusinessName('');
    setBusinessNameAppendix('');
    setSalutation(null);
    setFirstName('');
    setLastName('');
    setStreet('');
    setZip('');
    setCity('');
    setCountry('DE');
    setPhone('');
    setEmail('');
    setPassword('');
    setTerms(false);
    setSuccess(false);
    setError(null);
    setErrorFields([]);
  }, [advertiserType, navigate, service.advertiserTypes.length]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    // check if phone contains anything else than +, 0-9, (, ), -, /, space
    if (!/^[+0-9() -/]+$/.test(phone)) {
      setError(['Die angegebene Telefonnummer ist ungültig.']);
      setErrorFields(['phone']);
      return;
    }
    if (!advertiserType) {
      setError(['Bitte wählen Sie gewerblich oder privat.']);
      setErrorFields([]);
      return;
    }
    if (!salutation) {
      setError(['Bitte wählen Sie eine Anrede.']);
      setErrorFields(['salutation']);
      return;
    }
    if (!terms) {
      setError(['Bitte akzeptieren Sie die AGB und Datenschutzerklärung.']);
      setErrorFields(['terms']);
      return;
    }
    if (advertiserType === AdvertiserType.COMPANY && !businessName.trim().length) {
      setError(['Bitte geben Sie einen Unternehmensnamen ein.']);
      setErrorFields(['businessName']);
      return;
    }

    signup({
      advertiserType,
      businessName: advertiserType === AdvertiserType.COMPANY ? businessName.trim() : null,
      businessAppendix:
        advertiserType === AdvertiserType.COMPANY && businessNameAppendix.trim().length
          ? businessNameAppendix.trim()
          : null,
      salutation,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      street: street.trim(),
      zip: zip.trim(),
      city: city.trim(),
      country,
      phone: Helpers.parsePhoneNumber(phone),
      email: email.trim(),
      password,
    });
  };

  return {
    service,
    salutationOptions: Constants.SALUTATION_OPTIONS,
    advertiserType,
    setAdvertiserType,
    businessName,
    setBusinessName,
    businessNameAppendix,
    setBusinessNameAppendix,
    salutation,
    setSalutation,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    street,
    setStreet,
    zip,
    setZip,
    city,
    setCity,
    country,
    setCountry,
    phone,
    setPhone,
    email,
    setEmail,
    password,
    setPassword,
    terms,
    setTerms,
    error,
    errorFields,
    passwordVisible,
    setPasswordVisible,
    success,
    isSigningUp,
    handleBack,
    handleSubmit,
  };
};

export default useSignupController;
