import { Constants } from '@jobmatic/shared/utils';
import Button from '../../components/Button';
import Note from '../../components/Note';
import useDashboardUploadLogoController from '../../controllers/Dashboard/UploadLogo';
import { AdvertiserType } from '@prisma/client';
import clsx from 'clsx';

const DashboardUploadLogoView: React.FC = () => {
  const controller = useDashboardUploadLogoController();

  if (controller.advertiser?.type !== AdvertiserType.COMPANY) return null;
  return (
    <>
      <div className="w-full lg:w-3/4">
        {controller.success === 'upload' && (
          <Note type="success" message="Das Logo wurde erfolgreich hochgeladen." className="mb-8" />
        )}
        {controller.success === 'delete' && (
          <Note type="success" message="Das Logo wurde erfolgreich gelöscht." className="mb-8" />
        )}
        {controller.error && (
          <Note
            type="error"
            message={controller.error.map((e) => (
              <p>{e}</p>
            ))}
            className="mb-8"
          />
        )}
        <div>
          <p>
            Laden Sie hier Ihr Firmenlogo hoch, um Ihre Stellenanzeige hervorzuheben und Ihr Unternehmen optimal zu
            präsentieren. Mit dem Upload bestätigen Sie, Rechteinhaber/in zu sein und gestatten uns die
            Veröffentlichung.
          </p>
          <p>
            Mögliche Dateiformate: JPG, PNG, WEBP, SVG
            <br />
            mind. 400 Pixel breit oder hoch, max. 5 MB
          </p>
        </div>

        <div className="flex flex-col sm:flex-row items-start sm:items-end gap-4">
          <div
            className="w-64 h-64 border border-border flex items-center justify-center mt-16"
            {...controller.dropzone.getRootProps()}>
            <input
              {...controller.dropzone.getInputProps()}
              accept={Constants.ALLOWED_LOGO_MIME_TYPES.join(',')}
              multiple={false}
            />
            {controller.currentLogo || controller.logo ? (
              <img
                src={`data:${(controller.logo || controller.currentLogo)!.mimeType};base64,${
                  (controller.logo || controller.currentLogo)!.base64
                }`}
                alt="Logo"
                className="w-56 h-56 object-contain cursor-pointer"
              />
            ) : (
              <div className="w-56 h-56 flex items-center justify-center bg-light cursor-pointer">
                <p className="text-center p-4">
                  {controller.dropzone.isDragActive ? 'Datei hier ablegen' : 'Datei auswählen oder hier ablegen'}
                </p>
              </div>
            )}
          </div>
          {controller.currentLogo && !controller.logo ? (
            <Button
              title={controller.isDeleting ? 'Wird gelöscht...' : 'Logo löschen'}
              onClick={controller.handleDelete}
              disabled={controller.isUploading || controller.isDeleting || !controller.currentLogo}
              className={clsx(controller.isDeleting && '!cursor-wait')}
            />
          ) : (
            controller.logo !== null && (
              <Button
                title={controller.isUploading ? 'wird hochgeladen...' : 'Logo hochladen'}
                onClick={controller.handleSubmit}
                disabled={controller.isUploading || controller.isDeleting || !controller.logo}
                className={clsx(controller.isUploading && '!cursor-wait')}
              />
            )
          )}
        </div>
      </div>
      <Button
        title="zurück"
        onClick={controller.handleBack}
        kind="solid-secondary"
        disabled={controller.isUploading}
        className="mt-12"
      />
    </>
  );
};

export default DashboardUploadLogoView;
