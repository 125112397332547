import Button from '../components/Button';
import useActivateAccountController from '../controllers/ActivateAccount';
import PageHeading from '../components/PageHeading';

const ActivateAccountView: React.FC = () => {
  const controller = useActivateAccountController();

  return (
    <>
      <PageHeading>{controller.success ? 'Account aktiviert' : 'Registrieren'}</PageHeading>
      <div className="w-full lg:w-3/4">
        {controller.success && (
          <div>
            <p>Ihr Account wurde erfolgreich aktiviert.</p>
            <p>Sie können sich nun mit Ihren Zugangsdaten einloggen.</p>
          </div>
        )}
        {controller.error && <div>{controller.error}</div>}
        <Button onClick={controller.handleGoToLogin} title={controller.error ? 'Home' : 'Login'} className="mt-12" />
      </div>
    </>
  );
};

export default ActivateAccountView;
