import { useMemo } from 'react';
import { useGetOwnAdvertiser } from '../../hooks/query/Advertiser';
import dayjs from 'dayjs';
import { useGetServerConfig } from '../../hooks/query/System';
import { useGetOwnJobs } from '../../hooks/query/Job';
import { useGetOwnInvoices } from '../../hooks/query/Invoice';

const useDashboardMainController = () => {
  const { data: advertiser } = useGetOwnAdvertiser();
  const { data: jobsData } = useGetOwnJobs(null);
  const jobs = jobsData?.jobs ?? [];
  const { data: invoicesData } = useGetOwnInvoices(null);
  const invoices = invoicesData?.invoices ?? [];
  const { data: serverConfig } = useGetServerConfig();

  const requiresBaseDataCheck = useMemo(
    () =>
      !!advertiser?.lastUpdatedBaseAt &&
      dayjs(advertiser?.lastUpdatedBaseAt) < dayjs().subtract(serverConfig?.dataValidity || 180, 'day'),
    [advertiser, serverConfig?.dataValidity]
  );

  const requiresInvoiceDataCheck = useMemo(
    () =>
      !!advertiser?.lastUpdatedInvoiceAt &&
      dayjs(advertiser?.lastUpdatedInvoiceAt) < dayjs().subtract(serverConfig?.dataValidity || 180, 'day'),
    [advertiser, serverConfig?.dataValidity]
  );

  return {
    advertiser,
    jobs,
    invoices,
    requiresBaseDataCheck,
    requiresInvoiceDataCheck,
  };
};

export default useDashboardMainController;
