import clsx from 'clsx';
import { useOutletContext } from 'react-router-dom';
import { MainOutletContext } from '../MainOutlet';

interface InputGroupProps {
  label?: string | React.ReactNode;
  helper?: string | React.ReactNode;
  children: React.ReactNode;
  horizontal?: boolean;
  className?: string;
  labelClassName?: string;
}
const InputGroup: React.FC<InputGroupProps> = ({ label, helper, children, horizontal, className, labelClassName }) => {
  const { service } = useOutletContext<MainOutletContext>();
  return (
    <div className={clsx('flex', horizontal ? 'flex-col sm:flex-row lg:gap-16' : 'flex-col', className)}>
      {label && (
        <label
          className={clsx(
            'font-bold sm:!text-dark sm:font-normal mb-2',
            horizontal ? 'text-md flex-1' : 'text-sm',
            labelClassName
          )}
          style={{ color: service.spotColor }}>
          {label}
        </label>
      )}
      <div className={clsx('flex flex-col', horizontal && (label ? 'min-w-0 flex-[2] lg:flex-[3]' : 'flex-1'))}>
        {children}
        {helper && <div className="text-sm font-light opacity-80 sm:opacity-60 mt-2">{helper}</div>}
      </div>
    </div>
  );
};

export default InputGroup;
