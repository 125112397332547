import { useEffect, useMemo, useRef, useState } from 'react';
import emojiRegex from 'emoji-regex';
import { useImmer } from 'use-immer';
import { useGetLogo, useGetOwnAdvertiser } from '../../../hooks/query/Advertiser';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { RemoteOption } from '@prisma/client';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { MainOutletContext } from '../../../MainOutlet';
import { useSearchPlace } from '../../../hooks/query/Geo';
import { useDebounce } from 'react-use';
import { useUpdateJob, useGetJobById, JobQueryKeys } from '../../../hooks/query/Job';
import { WYSIWYGEditorRef } from '@jobmatic/web/components';
import { JobEditorFields } from '../../../components/JobEditor';
import { useQueryClient } from '@tanstack/react-query';

type Field = keyof JobEditorFields;

const useDashboardJobsEditController = () => {
  const queryClient = useQueryClient();
  const { id: jobIdString } = useParams<{ id: string }>();
  const jobId = useMemo(() => (jobIdString && /^\d+$/.test(jobIdString) ? parseInt(jobIdString) : null), [jobIdString]);
  const navigate = useNavigate();
  const { service } = useOutletContext<MainOutletContext>();
  const { data: advertiser } = useGetOwnAdvertiser();
  const { data: logo } = useGetLogo(advertiser?.id || 0, { enabled: !!advertiser });
  const { mutate: searchPlace } = useSearchPlace({
    onSuccess: (data) => {
      const newSearchResults = data
        .map((place) => {
          const region = place.context?.find((c) => c.type === 'region')?.name;
          return {
            id: place.id,
            city: place.shortName,
            region: region ?? null,
            coordinates: place.coordinates,
            bbox: place.bbox,
          };
        })
        .filter((place) => !jobEditorState.locations.find((l) => l.id === place.id))
        .slice(0, 5);

      updateJobEditorState((draft) => {
        draft.searchResults = newSearchResults;
      });
      if (!jobEditorState.hoveredSearchLocation && newSearchResults.length > 0) {
        updateJobEditorState((draft) => {
          draft.hoveredSearchLocation = newSearchResults[0].id;
        });
      } else if (!newSearchResults.find((c) => c.id === jobEditorState.hoveredSearchLocation)) {
        updateJobEditorState((draft) => {
          draft.hoveredSearchLocation = null;
        });
      }
    },
  });
  const { mutate: updateJob, isLoading: isUpdating } = useUpdateJob({
    onSuccess: (data) => {
      setSuccess(true);
      setError([]);
      queryClient.setQueryData([JobQueryKeys.GET_BY_ID, jobId!], data);
      queryClient.refetchQueries({
        predicate: (query) => query.queryKey[0] === JobQueryKeys.GET_OWN,
      });
      navigate(`/dashboard/anzeigen/${jobId}`);
    },
    onError: (e) => {
      setSuccess(false);
      setError([transformTRPCErrorToMessage(e)]);
    },
  });

  const [initializedData, setInitializedData] = useState(false);

  const { data: job } = useGetJobById(jobId!, {
    enabled: !!jobId,
    onSuccess: async (data) => {
      if (data && !initializedData) {
        const {
          title,
          descriptionHtml,
          descriptionPlain,
          workCountry,
          workLocations,
          remote,
          showEqualityNote,
          showAddress,
          minAge,
          occupationType,
        } = data;
        updateJobEditorState((draft) => {
          draft.title = title.slice(0, Constants.MAX_JOB_TITLE_LENGTH);
          draft.description = descriptionHtml;
          draft.descriptionPlain = descriptionPlain;
          draft.descriptionLength = descriptionPlain.length;
          draft.country = workCountry as keyof typeof Constants.COUNTRY_LIST;
          draft.locations = workLocations.map((l) => ({
            id: l.geoservicePlaceId,
            coordinates: { lat: l.coordinates[1], lng: l.coordinates[0] },
            bbox: Helpers.parseDatabaseBboxToObjectBbox(l.bbox),
            city: l.name,
            region: l.region,
            order: l.order,
          }));
          draft.remote = remote;
          draft.showEqualityNote = showEqualityNote;
          draft.showAddress = showAddress;
          draft.minAge = minAge;
          draft.occupationType = occupationType;
        });
        setInitializedData(true);

        let retries = 0;
        while (editorRef.current?.editor === null) {
          if (retries++ > 10) break;
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        editorRef.current?.editor?.commands.setContent(descriptionHtml);
      }
    },
  });

  const [jobEditorState, updateJobEditorState] = useImmer<JobEditorFields>({
    title: '',
    description: '',
    descriptionPlain: '',
    descriptionLength: 0,
    country: (service.forcedCountry as keyof typeof Constants.COUNTRY_LIST) ?? 'DE',
    remote: service.forcedRemote ?? RemoteOption.NOT_POSSIBLE,
    showEqualityNote: true,
    showAddress: true,
    minAge: service.minAge ?? 18,
    occupationType: [],
    locations: [],
    locationSearch: '',
    searchResults: [],
    hoveredSearchLocation: null,
  });
  const [success, setSuccess] = useState(false);
  const [errorFields, setErrorFields] = useState<Field[]>([]);
  const [error, setError] = useState<string[]>([]);

  const editorRef = useRef<WYSIWYGEditorRef | null>(null);

  useDebounce(
    () => {
      if (jobEditorState.locationSearch.length >= 3) {
        searchPlace({
          query: jobEditorState.locationSearch,
          filter: ['city'],
          filterCountry: jobEditorState.country,
        });
      } else {
        updateJobEditorState((draft) => {
          draft.searchResults = [];
        });
      }
    },
    500,
    [jobEditorState.locationSearch]
  );

  const handleJobEditorStateChange = (newState: Partial<JobEditorFields>) => {
    updateJobEditorState((draft) => {
      Object.assign(draft, newState);

      if (newState.title) {
        // remove emojis
        const newTitle = newState.title.replace(emojiRegex(), '');
        if (newTitle.length > Constants.MAX_JOB_TITLE_LENGTH && !errorFields.includes('title')) {
          setErrorFields([...errorFields, 'title']);
        } else if (newTitle.length <= Constants.MAX_JOB_TITLE_LENGTH && errorFields.includes('title')) {
          setErrorFields(errorFields.filter((f) => f !== 'title'));
        }
        draft.title = newTitle.slice(0, Constants.MAX_JOB_TITLE_LENGTH);
      }
      if (newState.country) {
        draft.locationSearch = '';
        draft.searchResults = [];
        draft.hoveredSearchLocation = null;
        draft.locations = [];
      }
    });
  };

  useEffect(() => {
    if (
      jobEditorState.descriptionLength > 0 &&
      jobEditorState.descriptionLength < Constants.MAX_JOB_DESCRIPTION_LENGTH &&
      errorFields.includes('description')
    ) {
      setErrorFields(errorFields.filter((f) => f !== 'description'));
    }
  }, [jobEditorState.descriptionLength, errorFields]);

  useEffect(() => {
    if (!jobEditorState.locationSearch.length) {
      updateJobEditorState((draft) => {
        draft.searchResults = [];
        draft.hoveredSearchLocation = null;
      });
    }
  }, [jobEditorState.locationSearch, updateJobEditorState]);

  useEffect(() => {
    if (error) {
      Helpers.scrollToTop();
    }
  }, [error]);

  const handleBreakDescriptionCharacterLimit = () => {
    setErrorFields([...errorFields, 'description']);
  };

  const handleSave = () => {
    if (!advertiser || !jobId) return;
    if (!jobEditorState.title.length) {
      setErrorFields(['title']);
      setError([ErrorMessages.missing_job_title]);
      return;
    }
    if (!jobEditorState.descriptionLength) {
      setErrorFields(['description']);
      setError([ErrorMessages.missing_job_description]);
      return;
    }
    if (jobEditorState.remote !== RemoteOption.ONLY && !jobEditorState.locations.length) {
      setErrorFields(['locations']);
      setError([ErrorMessages.missing_job_locations]);
      return;
    }
    if (!jobEditorState.occupationType.length) {
      setErrorFields(['occupationType']);
      setError([ErrorMessages.missing_occupation_types]);
      return;
    }
    updateJob({
      id: jobId,
      title: jobEditorState.title.trim(),
      descriptionHtml: jobEditorState.description,
      descriptionPlain: jobEditorState.descriptionPlain.trim().replace(emojiRegex(), ''),
      country: jobEditorState.country,
      locations:
        jobEditorState.remote === RemoteOption.ONLY
          ? []
          : jobEditorState.locations.map((l) => ({
              geoservice: service.geoservice,
              geoserviceId: l.id,
              name: l.city,
              region: l.region,
              coordinates: l.coordinates,
              bbox: l.bbox,
              order: l.order,
            })),
      remote: jobEditorState.remote,
      minAge: jobEditorState.minAge ?? undefined,
      occupationType: jobEditorState.occupationType,
      showEqualityNote: jobEditorState.showEqualityNote,
      showAddress: jobEditorState.showAddress,
    });
  };

  const handleBack = () => {
    navigate(`/dashboard/anzeigen/${jobId}`);
  };

  return {
    advertiser,
    service,
    job,
    jobEditorState,
    logo,
    isUpdating,
    success,
    errorFields,
    error,
    editorRef,
    handleJobEditorStateChange,
    handleSave,
    handleBack,
    handleBreakDescriptionCharacterLimit,
  };
};

export default useDashboardJobsEditController;
