import { Constants } from '@jobmatic/shared/utils';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import {
  AdvertiserQueryKeys,
  useDeleteLogo,
  useGetLogo,
  useGetOwnAdvertiser,
  useUploadLogo,
} from '../../hooks/query/Advertiser';
import { useQueryClient } from '@tanstack/react-query';
import { AdvertiserType } from '@prisma/client';

const useDashboardUploadLogoController = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [error, setError] = useState<string[] | null>(null);
  const [success, setSuccess] = useState<'upload' | 'delete' | false>(false);
  const [logo, setLogo] = useState<{
    base64: string;
    mimeType: (typeof Constants.ALLOWED_LOGO_MIME_TYPES)[number];
  } | null>(null);

  const { data: advertiser } = useGetOwnAdvertiser();
  const { data: currentLogo } = useGetLogo(advertiser?.id || -1, { enabled: !!advertiser });
  const { mutate: uploadLogo, isLoading: isUploading } = useUploadLogo({
    onSuccess: () => {
      setSuccess('upload');
      setError(null);
      queryClient.setQueryData([AdvertiserQueryKeys.GET_LOGO, advertiser?.id], logo);
      setLogo(null);
    },
    onError: (e) => {
      setError([transformTRPCErrorToMessage(e)]);
      setLogo(null);
    },
  });
  const { mutate: deleteLogo, isLoading: isDeleting } = useDeleteLogo({
    onSuccess: () => {
      setSuccess('delete');
      setError(null);
      queryClient.setQueryData([AdvertiserQueryKeys.GET_LOGO, advertiser?.id], null);
      setLogo(null);
    },
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();
    const file = acceptedFiles[0];

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // convert to base64 for uploading
      const base64Url = reader.result?.toString();
      if (!base64Url) return;

      setSuccess(false);
      setError(null);

      // get mime type
      const mimeType = base64Url?.toString().split(',')[0].split(':')[1].split(';')[0];
      const base64 = base64Url?.toString().split(',')[1];

      if (!(Constants.ALLOWED_LOGO_MIME_TYPES as readonly string[]).includes(mimeType)) {
        setError([ErrorMessages.logo_invalid_mime_type]);
        return;
      }

      setLogo({ base64, mimeType: mimeType as (typeof Constants.ALLOWED_LOGO_MIME_TYPES)[number] });
    };
    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleBack = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);

  useEffect(() => {
    if (advertiser && advertiser.type !== AdvertiserType.COMPANY) navigate('/fehler?code=404');
  }, [advertiser, navigate]);

  const handleSubmit = () => {
    if (!logo || !advertiser) return;

    uploadLogo({
      base64: logo.base64,
      mimeType: logo.mimeType,
      advertiserId: advertiser.id,
    });
  };

  const handleDelete = () => {
    if (!currentLogo || !advertiser) return;

    deleteLogo({
      advertiserId: advertiser.id,
    });
  };

  return {
    error,
    advertiser,
    dropzone: { getRootProps, getInputProps, isDragActive },
    currentLogo,
    logo,
    handleBack,
    handleSubmit,
    handleDelete,
    isUploading,
    isDeleting,
    success,
  };
};

export default useDashboardUploadLogoController;
