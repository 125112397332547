import { useNavigate, useSearchParams } from 'react-router-dom';
import { useConfirmEmailChange } from '../hooks/query/Advertiser';
import { useEffect, useState } from 'react';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';

const useConfirmEmailChangeController = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate: confirmEmailChange, isSuccess: success } = useConfirmEmailChange({
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
  });
  const [error, setError] = useState<string[] | null>(null);

  useEffect(() => {
    if (searchParams.get('token')) {
      setError(null);
      confirmEmailChange({
        token: searchParams.get('token')!,
      });
    } else {
      setError([ErrorMessages.invalid_email_change_token]);
    }
  }, [searchParams, confirmEmailChange]);

  const handleGoToLogin = () => {
    navigate('/');
  };

  return {
    success,
    error,
    handleGoToLogin,
  };
};

export default useConfirmEmailChangeController;
