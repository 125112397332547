import { useMemo, useState } from 'react';
import { useGetLogo } from '../../../hooks/query/Advertiser';
import { JobQueryKeys, useDeleteJob, useGetJobById } from '../../../hooks/query/Job';
import dayjs from 'dayjs';
import { JobState } from '@prisma/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Helpers } from '@jobmatic/shared/utils';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { TRPCClientError } from '@trpc/client';
import { useGetCurrentService } from '../../../hooks/query/Service';

const useDashboardJobsDetailsController = (id: number) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const { data: service } = useGetCurrentService();
  const { data: job } = useGetJobById(id, {
    onError: (e) => {
      if (
        e instanceof TRPCClientError &&
        (e.data.code === 'FORBIDDEN' || e.data.code === 'NOT_FOUND' || e.data.code === 'UNAUTHORIZED')
      ) {
        navigate('/dashboard/anzeigen');
      }
    },
    retry: (failureCount, error) => {
      if (
        error instanceof TRPCClientError &&
        (error.data.code === 'FORBIDDEN' || error.data.code === 'NOT_FOUND' || error.data.code === 'UNAUTHORIZED')
      ) {
        return false;
      }
      return failureCount < 3;
    },
  });
  const { data: logo } = useGetLogo(job?.advertiserId || 0, { enabled: !!job?.advertiserId });
  const { mutate: deleteJob, isLoading: isDeleting } = useDeleteJob({
    onSuccess: async () => {
      queryClient.setQueryData([JobQueryKeys.GET_BY_ID, id], (oldData) => ({
        ...(oldData as any),
        state: JobState.DELETED_BY_USER,
        deletedAt: new Date(),
      }));
      await queryClient.refetchQueries({
        predicate: (query) => query.queryKey[0] === JobQueryKeys.GET_OWN,
      });
      setShowDeleteModal(false);
    },
    onError: (e) => {
      setError(transformTRPCErrorToMessage(e));
      setShowDeleteModal(false);
      Helpers.scrollToTop();
    },
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const canEdit = useMemo(
    () => !job?.deletedAt && (!job?.activatedAt || dayjs(job?.activatedAt).isAfter(dayjs().subtract(24, 'hour'))),
    [job]
  );
  const canDelete = useMemo(
    () =>
      !job?.deletedAt && [JobState.UNCHECKED, JobState.ACTIVE, JobState.ACTIVE_UNCHECKED].includes(job?.state as any),
    [job]
  );

  const handleCancel = () => {
    let page: number | null = null;
    if (searchParams.has('page') && /^\d+$/.test(searchParams.get('page') || '')) {
      page = parseInt(searchParams.get('page') || '', 10);
    }
    navigate('/dashboard/anzeigen' + (page !== null ? `?page=${page}` : ''));
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleEdit = () => {
    if (!job || !canEdit) return;
    navigate(`/dashboard/anzeigen/${job.id}/bearbeiten`);
  };

  const handleDuplicate = () => {
    if (!job) return;
    setShowDuplicateModal(true);
  };

  const handleConfirmDelete = () => {
    if (!job || !canDelete) return;
    deleteJob({ id: job.id });
  };

  const handleConfirmDuplicate = () => {
    if (!job) return;
    navigate(`/dashboard/inserieren?templateId=${job.id}`);
  };

  return {
    service,
    job,
    logo,
    isDeleting,
    canEdit,
    canDelete,
    error,
    showDeleteModal,
    setShowDeleteModal,
    showDuplicateModal,
    setShowDuplicateModal,
    handleCancel,
    handleDelete,
    handleEdit,
    handleDuplicate,
    handleConfirmDelete,
    handleConfirmDuplicate,
  };
};

export default useDashboardJobsDetailsController;
