import clsx from 'clsx';

interface TagContainerProps {
  tags: { id: string; label: string; selected?: boolean }[];
  onToggle: (id: string) => void;
  className?: string;
  onChangeSearch?: (value: string) => void;
  searchValue?: string;
  additionalSearchProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  append?: React.ReactNode;
}
export const TagContainer: React.FC<TagContainerProps> = ({
  tags,
  onToggle,
  onChangeSearch,
  searchValue,
  append,
  additionalSearchProps,
  className,
}) => {
  return (
    <div className="relative">
      <div
        className={clsx(
          'border border-[rgba(0,0,0,0.25)] rounded-md flex flex-row items-center flex-wrap gap-2 w-full px-3 py-2.5',
          className
        )}>
        {tags.map((tag, idx) => (
          <div
            key={idx}
            onClick={tag.selected === undefined ? undefined : () => onToggle(tag.id)}
            className={clsx(
              'px-2.5 py-1 rounded transition flex flex-row items-center gap-2',
              tag.selected !== undefined && 'cursor-pointer',
              tag.selected
                ? 'bg-[rgba(0,0,0,0.7)] text-white opacity-80 hover:opacity-90 active:opacity-100'
                : 'bg-[rgba(0,0,0,0.07)] opacity-100 hover:bg-[rgba(0,0,0,0.17)] active:bg-[rgba(0,0,0,0.27)]'
            )}>
            {tag.label}
            {tag.selected === undefined && (
              <img
                src="/assets/icon-close-black.svg"
                alt="Entfernen"
                className="w-3 h-3 transition opacity-20 hover:opacity-100 cursor-pointer"
                onClick={() => onToggle(tag.id)}
              />
            )}
          </div>
        ))}
        {onChangeSearch && (
          <div className="flex-1 min-w-[33%] py-1">
            <input
              type="text"
              className="w-full border-none bg-transparent focus:outline-none"
              onChange={(e) => onChangeSearch(e.target.value)}
              value={searchValue}
              {...additionalSearchProps}
            />
          </div>
        )}
      </div>
      {append}
    </div>
  );
};
