import { useGetCurrentService } from '../hooks/query/Service';

const PageHeading: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: service } = useGetCurrentService();

  return (
    <h1
      className="text-xl md:text-3xl font-bold md:font-semibold mb-8 md:mb-12"
      style={{ color: service?.headingColor ?? service?.spotColor }}>
      {children}
    </h1>
  );
};

export default PageHeading;
