import { Link } from 'react-router-dom';
import InputGroup from '../../../components/InputGroup';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import useDashboardSettingsChangeEmailController from '../../../controllers/Dashboard/Settings/ChangeEmail';
import Note from '../../../components/Note';
import { AdvertiserType } from '@prisma/client';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

const DashboardSettingsChangeEmailView: React.FC = () => {
  const controller = useDashboardSettingsChangeEmailController();

  return (
    <div className="w-full lg:w-3/4">
      {controller.error && (
        <Note
          type="error"
          message={controller.error.map((e) => (
            <p>{e}</p>
          ))}
          className="mb-8"
        />
      )}
      {controller.success ? (
        <div>
          <p>
            Wir haben eine E-Mail an <strong>{controller.email}</strong> geschickt.
            <br />
            Schauen Sie ggf. in Ihren Spam-Ordner.
          </p>
          <p>Klicken Sie auf den darin enthaltene Link, da ansonsten die alte E-Mail-Adresse aktiv bleibt.</p>
          <p>
            <strong>Sie sind jetzt ausgeloggt.</strong>
          </p>
          <Button onClick={controller.handleGoToHome} title="Home" className="mt-12" />
        </div>
      ) : (
        <>
          <div>
            <p>
              Hier können Sie Ihre aktuelle E-Mail-Adresse <strong>{controller.advertiser?.email || ''}</strong> ändern,
              die zum Einloggen und für Systemnachrichten verwendet wird.
            </p>
            {controller.advertiser?.type === AdvertiserType.COMPANY && (
              <p>
                Für den <strong>Rechnungsempfang</strong> können Sie optional eine abweichende E-Mail-Adresse in den{' '}
                <Link to="/dashboard/rechnungsdaten" className="underline">
                  Rechnungsdaten
                </Link>{' '}
                hinterlegen.
              </p>
            )}
            <p>
              <strong>Bitte beachten Sie</strong>: Wir senden an die <strong>neue</strong> E-Mail-Adresse eine E-Mail
              zur Bestätigung. Klicken Sie bitte auf den darin enthaltene Link, da ansonsten die alte E-Mail-Adresse
              aktiv bleibt.
            </p>
          </div>
          <form className="flex flex-col gap-4 mt-16" onSubmit={controller.handleSubmit}>
            <InputGroup label="neue E-Mail-Adresse*" horizontal>
              <Input
                value={controller.email}
                onChange={controller.setEmail}
                type="email"
                autoComplete="email"
                maxLength={255}
              />
            </InputGroup>
            <InputGroup
              label="Passwort*"
              horizontal
              helper={
                <>
                  <Link to="/dashboard/passwort-vergessen" className="underline">
                    Passwort vergessen
                  </Link>
                  ?
                </>
              }>
              <Input
                value={controller.password}
                onChange={controller.setPassword}
                type={controller.passwordVisible ? 'text' : 'password'}
                append={
                  controller.passwordVisible ? (
                    <EyeSlashIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => controller.setPasswordVisible(false)}
                    />
                  ) : (
                    <EyeIcon className="w-5 h-5 cursor-pointer" onClick={() => controller.setPasswordVisible(true)} />
                  )
                }
                autoComplete="current-password"
              />
            </InputGroup>
            <div className="flex flex-row justify-between mt-12 w-full">
              <Button
                title="abbrechen"
                type="button"
                onClick={controller.handleCancel}
                kind="solid-secondary"
                disabled={controller.isSubmitting}
              />
              <Button
                title="speichern"
                type="submit"
                disabled={controller.isSubmitting}
                className={clsx(controller.isSubmitting && '!cursor-wait')}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default DashboardSettingsChangeEmailView;
