import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from './components/Container';
import Header from './components/Header';
import Footer from './components/Footer';
import { useGetCurrentService } from './hooks/query/Service';
import { useEffect, useMemo } from 'react';
import { useVerifySession } from './hooks/query/Auth';
import { TRPCClientError } from '@trpc/client';
import PageHeading from './components/PageHeading';
import { Helpers } from '@jobmatic/shared/utils';
import { trpc } from './utils/trpc';
import { useTrackVisit } from './hooks/query/System';
import useMount from 'react-use/lib/useMount';

type Navigation = {
  title: string;
  path: string | (() => void | Promise<void>);
  dontShowHeading?: boolean;
  navigation?: 'main' | 'footer' | 'settings';
}[];
export type MainOutletContext = {
  navigation: Navigation;
  service: NonNullable<ReturnType<typeof useGetCurrentService>['data']>;
};
const MainOutlet: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentService = useGetCurrentService();
  const { mutate: trackVisit } = useTrackVisit();

  const navigation = useMemo(
    () =>
      [
        {
          title: 'Login für Arbeitgeber',
          path: '/',
        },
        {
          title: 'Fehler',
          path: '/fehler',
          dontShowHeading: true,
        },
        {
          title: 'Registrierung als Arbeitgeber',
          path: '/registrieren',
          dontShowHeading: true,
        },
        {
          title: 'Passwort vergessen',
          path: '/passwort-vergessen',
        },
        {
          title: 'Registrieren',
          path: '/account-aktivieren',
          dontShowHeading: true,
        },
        {
          title: 'E-Mail-Adresse ändern',
          path: '/neue-email',
        },
        {
          title: 'Mein Account',
          path: '/dashboard',
        },
        {
          title: 'Job inserieren',
          path: '/dashboard/inserieren',
          navigation: 'main',
        },
        {
          title: 'Job inserieren',
          path: '/dashboard/job-inseriert',
        },
        {
          title: 'Anzeigenübersicht',
          path: '/dashboard/anzeigen',
          navigation: 'main',
        },
        {
          title: 'Anzeige bearbeiten',
          path: '/dashboard/anzeigen/:id',
        },
        {
          title: 'Anzeige bearbeiten',
          path: '/dashboard/anzeigen/:id/bearbeiten',
        },
        {
          title: 'Rechnungen',
          path: '/dashboard/rechnungen',
          navigation: 'main',
        },
        {
          title: 'Stammdaten',
          path: '/dashboard/stammdaten',
          navigation: 'main',
        },
        {
          title: 'Rechnungsdaten',
          path: '/dashboard/rechnungsdaten',
          navigation: 'main',
        },
        {
          title: 'Logo',
          path: '/dashboard/logo',
          navigation: 'main',
        },
        {
          title: 'Passwort vergessen',
          path: '/dashboard/passwort-vergessen',
        },
        {
          title: 'Einstellungen',
          path: '/dashboard/einstellungen',
          navigation: 'main',
        },
        {
          title: 'Logout',
          path: async () => {
            await trpc.auth.logout.mutate();
            window.location.href = `${currentService.data?.url}/logout`;
          },
          navigation: 'main',
        },
        {
          title: 'E-Mail-Adresse ändern',
          path: '/dashboard/einstellungen/email',
          navigation: 'settings',
        },
        {
          title: 'Passwort ändern',
          path: '/dashboard/einstellungen/passwort',
          navigation: 'settings',
        },
        {
          title: 'Account löschen',
          path: '/dashboard/einstellungen/account-loeschen',
          navigation: 'settings',
        },
        {
          title: 'Hilfe / Kontakt',
          path: `${currentService.data?.url}/kontakt`,
          navigation: 'footer',
        },
        {
          title: 'Impressum',
          path: `${currentService.data?.url}/rechtliches`,
          navigation: 'footer',
        },
        {
          title: 'Nutzungsbedingungen',
          path: `${currentService.data?.url}/rechtliches#agb`,
          navigation: 'footer',
        },
        {
          title: 'Datenschutz',
          path: `${currentService.data?.url}/rechtliches#datenschutz`,
          navigation: 'footer',
        },
        {
          title: 'Account gelöscht',
          path: `/account-geloescht`,
        },
        {
          title: 'Passwort geändert',
          path: `/passwort-geaendert`,
        },
        {
          title: 'Passwort vergessen',
          path: `/passwort-email-verschickt`,
        },
      ] as Navigation,
    [currentService.data]
  );

  const { mutate: verifySession } = useVerifySession({
    onSuccess: async (data) => {
      if (location.pathname.startsWith('/dashboard')) {
        if (data.userType !== 'advertiser') {
          await trpc.auth.logout.mutate();
          navigate('/');
        }
        return;
      }
      if (location.pathname !== '/fehler') navigate('/dashboard');
    },
    onError: (error) => {
      if (!location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/dashboard/einstellungen/email'))
        return;
      if (error instanceof TRPCClientError && error.data?.code === 'UNAUTHORIZED') {
        navigate('/');
      }
    },
  });

  const currentNavigationItem = useMemo(
    () => navigation.find((item) => item.path === location.pathname.replace(/\/\d+/, '/:id')),
    [location.pathname, navigation]
  );

  useEffect(() => {
    // verify & update session on every navigation
    verifySession();
  }, [location.pathname, verifySession]);

  useMount(() => {
    // verify & update session every minute
    setInterval(() => {
      verifySession();
    }, 60 * 1000);
  });

  // Automatically scroll to top whenever pathname changes
  useEffect(() => {
    Helpers.scrollToTop({ jump: true });
  }, [location.pathname]);

  useEffect(() => {
    trackVisit({
      url: window.location.href,
    });
  }, [location.pathname, location.search, trackVisit]);

  useEffect(() => {
    (async () => {
      if (!currentService.data) return;
      const res = await fetch(
        `${process.env['NX_API_BASE_URL']}/public/service-favicons/${currentService.data.id}/html_code.html`
      );
      const html = (await res.text()).replace(
        /href="(\/[^"]+)"/g,
        `href="${process.env['NX_API_BASE_URL']}/public/service-favicons/${currentService.data.id}$1"`
      );
      document.head.insertAdjacentHTML('beforeend', html);
    })();
  }, [currentService.data]);

  if (!currentService.data) return null;
  return (
    <>
      <Helmet>
        <title>
          {currentNavigationItem?.title || 'Dashboard'} | {currentService.data.name}
        </title>
        {/* Plausible Analytics */}
        <script
          defer
          data-domain={`account.${new URL(currentService.data.url).hostname}`}
          src="https://plausible.io/js/script.outbound-links.js"></script>
        <script>
          {`window.plausible =
            window.plausible ||
            function () {
              (window.plausible.q = window.plausible.q || []).push(arguments);
            };`}
        </script>
        <style>{currentService.data?.customCss ?? ''}</style>
      </Helmet>
      <Header />
      <Container>
        {!currentNavigationItem?.dontShowHeading && (
          <PageHeading>{currentNavigationItem?.title || 'Dashboard'}</PageHeading>
        )}
        <Outlet context={{ navigation, service: currentService.data } as MainOutletContext} />
      </Container>
      <Footer context={{ navigation, service: currentService.data }} />
    </>
  );
};

export default MainOutlet;
