import { Helpers } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import Checkbox from './Checkbox';
import Button from './Button';
import { useOutletContext } from 'react-router-dom';
import { MainOutletContext } from '../MainOutlet';

interface JobOptionProps {
  title: string;
  marketingLabelUrl?: string;
  hotJobName?: string;
  features: (string | React.ReactNode)[];
  price?: number;
  pricePrefix?: string;
  selected: boolean;
  onChange: () => void;
  isCheckbox?: boolean;
  checkboxDisabled?: boolean;
  hideButton?: boolean;
  blockerText?: string;
  className?: string;
}
const JobOption: React.FC<JobOptionProps> = ({
  title,
  marketingLabelUrl,
  hotJobName,
  features,
  price,
  pricePrefix,
  selected,
  onChange,
  isCheckbox,
  checkboxDisabled,
  hideButton,
  blockerText,
  className,
}) => {
  const { service } = useOutletContext<MainOutletContext>();

  return (
    <div
      className={clsx('border rounded-md p-6 hover:bg-light relative', !selected && 'border-border', className)}
      style={selected ? { borderColor: service.spotColor } : undefined}>
      {marketingLabelUrl && (
        <img src={marketingLabelUrl} className="__db-joboption-hotjob-badge absolute" alt={hotJobName ?? 'HotJob'} />
      )}
      <div className="flex flex-col justify-between h-full">
        <div>
          <h2 className={clsx('text-xl', blockerText && 'opacity-50')}>
            <strong>{title}</strong>
            {price !== undefined && (
              <span className="opacity-50 pl-2">
                {' '}
                {pricePrefix ? `${pricePrefix} ` : ''}
                {Helpers.parsePrice(price)}&nbsp;Euro
              </span>
            )}
          </h2>
          <ul className="mt-4">
            {features.map((feature, i) => (
              <li
                key={i}
                className={clsx('bg-no-repeat bg-[0_5px] bg-auto pl-6 my-2', blockerText && 'opacity-50')}
                style={{ backgroundImage: `url(${service.jobOptionCheckSvgUrl})`, backgroundSize: '16px auto' }}
                dangerouslySetInnerHTML={{ __html: typeof feature === 'string' ? feature : '' }}
              />
            ))}
          </ul>
          {blockerText && <div className="mt-4 mb-2">{blockerText}</div>}
        </div>
        <div className="mt-4">
          {!hideButton &&
            (isCheckbox ? (
              <Checkbox
                containerClassName="mt-4"
                checked={selected}
                onChange={onChange}
                label={`${title} hinzubuchen`}
                disabled={checkboxDisabled || blockerText !== undefined}
              />
            ) : (
              <Button
                onClick={onChange}
                title={selected ? 'ausgewählt' : 'wählen'}
                kind={selected ? 'solid' : 'outline'}
                disabled={blockerText !== undefined}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default JobOption;
