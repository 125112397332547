import { MainOutletContext } from '../MainOutlet';
import Button from '../components/Button';
import { Link, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import PageHeading from '../components/PageHeading';
import { useMemo } from 'react';

const ErrorView: React.FC = () => {
  const navigate = useNavigate();
  const { service } = useOutletContext<MainOutletContext>();
  const [searchParams] = useSearchParams();
  const code = useMemo(
    () => (/\d+/.test(searchParams.get('code') || 'a') ? parseInt(searchParams.get('code')!) : null),
    [searchParams]
  );

  return (
    <>
      <PageHeading>{code === 404 ? 'Seite nicht gefunden' : 'Fehler'}</PageHeading>
      <div className="w-full lg:w-3/4">
        <div>
          {code === 404 ? (
            <p>404 – die angeforderte Seite kann nicht aufgerufen werden.</p>
          ) : (
            <>
              <p>Bei der letzten Aktion ist ein Fehler aufgetreten.</p>
              <p>
                Bitte wiederholen Sie den Vorgang. Falls der Fehler weiterhin auftritt,{' '}
                <Link to={service.url + '/kontakt'}>wenden Sie sich bitte an uns</Link>.
              </p>
            </>
          )}
        </div>
        <Button onClick={() => navigate('/')} title="Home" className="mt-12" />
      </div>
    </>
  );
};

export default ErrorView;
