import dayjs from 'dayjs';
import Button from '../../../components/Button';
import useDashboardJobsMainController from '../../../controllers/Dashboard/Jobs/Main';
import { Helpers } from '@jobmatic/shared/utils';

const DashboardJobsMainView: React.FC = () => {
  const controller = useDashboardJobsMainController();

  return (
    <>
      <div className="flex flex-col">
        {controller.jobs && controller.jobs.length > 0 && (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center border-b border-b-border font-bold pb-8">
              <div className="w-full sm:w-2/3 lg:w-1/3">
                Anzeigentitel<span className="inline lg:hidden"> / Laufzeit</span>
              </div>
              <div className="hidden lg:block w-1/3">Laufzeit</div>
              <div className="hidden sm:block w-1/6">Clicks</div>
              <div className="hidden sm:block w-1/6">Status</div>
            </div>
            {controller.jobs.map((job) => (
              <div
                className="flex flex-row justify-between items-center border-b border-b-border py-4 lg:py-8 hover:bg-light cursor-pointer"
                onClick={() => controller.handleJobClicked(job.id)}>
                <div className="w-full sm:w-2/3 lg:w-1/3 sm:pr-8">
                  <div className="flex flex-row items-center gap-2">
                    <div className="whitespace-nowrap truncate font-bold sm:font-normal">{job.title}</div>
                    {job.isHotjob && (
                      <img
                        className="__db-joboverview-hotjob-badge"
                        src={controller.service.hotJobBadgeUrl}
                        alt={controller.service.hotJobName}
                      />
                    )}
                  </div>
                  <div className="block lg:hidden">
                    {job.durationHours / 24 / 7} Wochen{' '}
                    {job.activatedAt && (
                      <span className="opacity-50">
                        ({dayjs(job.activatedAt).format('DD.MM.YYYY')} -{' '}
                        {dayjs(job.activatedAt).add(job.durationHours, 'hour').format('DD.MM.YYYY')})
                      </span>
                    )}
                  </div>
                  <div className="flex sm:hidden flex-row justify-between items-center mt-4">
                    <div>
                      {job.views} Click{job.views !== 1 && 's'}
                    </div>
                    <div className="bg-[rgba(0,0,0,0.07)] rounded-[3px] px-2 py-1 text-[0.8rem] w-fit">
                      {Helpers.parseJobState(job.state)}
                    </div>
                  </div>
                </div>
                <div className="w-1/3 hidden lg:block">
                  {job.durationHours / 24 / 7} Wochen{' '}
                  {job.activatedAt && (
                    <span className="opacity-50">
                      ({dayjs(job.activatedAt).format('DD.MM.YYYY')} -{' '}
                      {dayjs(job.activatedAt).add(job.durationHours, 'hour').format('DD.MM.YYYY')})
                    </span>
                  )}
                </div>
                <div className="w-1/6 hidden sm:block">{job.views}</div>
                <div className="w-1/6 hidden sm:block">
                  <div className="bg-[rgba(0,0,0,0.07)] rounded-[3px] px-2 py-1 text-[0.8rem] w-fit">
                    {Helpers.parseJobState(job.state)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-row self-center justify-between gap-2 mt-8">
          {controller.page !== 1 && (
            <Button onClick={() => controller.handlePageChange(controller.page - 1)} title="«" kind="outline" />
          )}
          {controller.page !== Math.max(controller.pageCount, 1) && (
            <Button onClick={() => controller.handlePageChange(controller.page + 1)} title="»" kind="outline" />
          )}
        </div>
      </div>
      <div className="mt-12 flex flex-row justify-between">
        <Button onClick={controller.handleBack} title="zurück" kind="solid-secondary" />
        {controller.jobs && controller.jobs.length > 0 && (
          <Button onClick={controller.handleCreateJob} title="Job inserieren" kind="solid" />
        )}
      </div>
    </>
  );
};

export default DashboardJobsMainView;
