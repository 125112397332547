import { useMutation, useQuery } from '@tanstack/react-query';
import { UseMutationTypeHelper, UseQueryTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';
import { useGetOwnAdvertiser } from './Advertiser';

export enum JobQueryKeys {
  GET_OWN = 'JOB_GET_OWN',
  GET_BY_ID = 'JOB_GET_BY_ID',
}

export const useCreateJob = (
  options?: UseMutationTypeHelper<typeof trpc.job.create.mutate, Parameters<typeof trpc.job.create.mutate>[0]>
) => useMutation((data) => trpc.job.create.mutate(data), options);

export const useGetOwnJobs = (
  page: number | null,
  options?: UseQueryTypeHelper<typeof trpc.job.getByAdvertiserId.query>
) => {
  const { data: advertiser } = useGetOwnAdvertiser();
  return useQuery(
    [JobQueryKeys.GET_OWN, page],
    () => trpc.job.getByAdvertiserId.query({ advertiserId: advertiser!.id, page: page ?? undefined }),
    { enabled: !!advertiser, ...options }
  );
};

export const useGetJobById = (id: number, options?: UseQueryTypeHelper<typeof trpc.job.getById.query>) =>
  useQuery([JobQueryKeys.GET_BY_ID, id], () => trpc.job.getById.query({ id }), options);

export const useDeleteJob = (
  options?: UseMutationTypeHelper<typeof trpc.job.delete.mutate, Parameters<typeof trpc.job.delete.mutate>[0]>
) => useMutation((data) => trpc.job.delete.mutate(data), options);

export const useUpdateJob = (
  options?: UseMutationTypeHelper<typeof trpc.job.update.mutate, Parameters<typeof trpc.job.update.mutate>[0]>
) => useMutation((data) => trpc.job.update.mutate(data), options);
