import { useEffect, useMemo, useState } from 'react';
import { useSendForgotPasswordEmail } from '../hooks/query/Auth';
import { Helpers, ZodValidators } from '@jobmatic/shared/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useChangePassword } from '../hooks/query/Advertiser';
import { ZodError } from 'zod';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';

const useForgotPasswordController = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string[] | null>(null);
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState<boolean>(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);

  const showChangeFlow = useMemo(() => searchParams.get('token') !== null, [searchParams]);

  const { mutate: sendForgotEmail, isLoading: isSending } = useSendForgotPasswordEmail({
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
    onSuccess: () => {
      setSentEmail(true);
      setError(null);
    },
  });
  const { mutate: changePassword, isLoading: isSaving } = useChangePassword({
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
    onSuccess: () => {
      setSearchParams(new URLSearchParams());
      setError(null);
      navigate('/passwort-geaendert');
    },
  });

  const handleCancel = () => {
    navigate('/');
  };

  const handleSendEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendForgotEmail({ email });
    return false;
  };

  const handleChangePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError([ErrorMessages.passwords_dont_match]);
      return;
    }
    try {
      ZodValidators.PasswordSchema.parse(password);
    } catch (e) {
      if (e instanceof ZodError) {
        const errors = new Set<string>();
        e.errors.forEach((e) => {
          if (e.code === 'too_small') errors.add('Bitte geben Sie ein Passwort mit mindestens 8 Zeichen ein.');
          else
            errors.add(
              'Das angegebene Passwort ist ungültig. Es muss mindestens 8 Zeichen lang sein, sowie mindestens eine Zahl, einen Buchstaben und ein Sonderzeichen (außer Leerzeichen) enthalten.'
            );
        });
        setError(Array.from(errors));
      }
      return;
    }

    const token = searchParams.get('token');
    if (token === null) return;
    changePassword({ token, newPassword: password });
  };

  useEffect(() => {
    if (error) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error]);

  return {
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    handleCancel,
    handleSendEmailSubmit,
    handleChangePasswordSubmit,
    error,
    isSending,
    isSaving,
    sentEmail,
    currentPasswordVisible,
    setCurrentPasswordVisible,
    newPasswordVisible,
    setNewPasswordVisible,
    showChangeFlow,
  };
};

export default useForgotPasswordController;
