import { useEffect, useState } from 'react';
import { useSendForgotPasswordEmail } from '../../hooks/query/Auth';
import { Helpers } from '@jobmatic/shared/utils';
import { useNavigate } from 'react-router-dom';
import { useGetOwnAdvertiser } from '../../hooks/query/Advertiser';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { trpc } from '../../utils/trpc';

const useDashboardForgotPasswordController = () => {
  const navigate = useNavigate();

  const [error, setError] = useState<string[] | null>(null);
  const [sentEmail, setSentEmail] = useState<boolean>(false);

  const { data: advertiser } = useGetOwnAdvertiser();
  const { mutate: sendForgotEmail, isLoading: isSending } = useSendForgotPasswordEmail({
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
    onSuccess: async () => {
      await trpc.auth.logout.mutate();
      navigate('/passwort-email-verschickt?logout=1');
      setSentEmail(true);
      setError(null);
    },
  });

  const handleCancel = () => {
    navigate('/dashboard/einstellungen');
  };

  const handleSubmit = () => {
    if (!advertiser) return;
    sendForgotEmail({ email: advertiser.email });
    return false;
  };

  useEffect(() => {
    if (error) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error]);

  return {
    advertiser,
    handleCancel,
    handleSubmit,
    error,
    isSending,
    sentEmail,
  };
};

export default useDashboardForgotPasswordController;
