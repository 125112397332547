import clsx from 'clsx';

const Container: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => {
  return (
    <div className={clsx('__db-main-container w-full mx-auto max-w-6xl px-4 sm:px-6 md:px-8', className)}>
      {children}
    </div>
  );
};

export default Container;
