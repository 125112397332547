import useDashboardJobsDetailsController from '../../../controllers/Dashboard/Jobs/Details';
import { Constants } from '@jobmatic/shared/utils';
import Button from '../../../components/Button';
import { useParams } from 'react-router-dom';
import JobDetails from '../../../components/JobDetails';
import Modal from '../../../components/Modal';
import Note from '../../../components/Note';
import clsx from 'clsx';
import { JobState } from '@prisma/client';

const DashboardJobsDetailsView = () => {
  const { id } = useParams<{ id: string }>();
  const controller = useDashboardJobsDetailsController(/^\d+$/.test(id || '') ? parseInt(id!) : -1);

  if (!/^\d+$/.test(id || '')) return null;
  return (
    <>
      <div className="w-full lg:w-[90%]">
        <div>
          <p className="font-bold">Wählen Sie eine der Optionen unten.</p>
        </div>
        {controller.error && <Note type="error" message={controller.error} className="mt-8" />}
        {controller.job?.state === JobState.DELETED_BY_ADMIN && (
          <Note
            type="error"
            message={
              controller.job?.deleteReason
                ? Constants.JOB_DELETE_REASON_OPTIONS[controller.job.deleteReason].frontend
                    .split('\n')
                    .map((str) => <p>{str}</p>)
                : 'Die Anzeige wurde gelöscht.'
            }
            className="mt-8"
          />
        )}
        {controller.job && (
          <>
            <div className="mt-16">
              <JobDetails
                title={controller.job.title}
                descriptionHtml={controller.job.descriptionHtml}
                locations={controller.job.workLocations.map((l) => ({ city: l.name, order: l.order }))}
                country={controller.job.workCountry as keyof typeof Constants.COUNTRY_LIST}
                remote={controller.job.remote}
                minAge={controller.job.minAge}
                occupationType={controller.job.occupationType}
                hotJob={controller.job.isHotjob}
                showEqualityNote={controller.job.showEqualityNote}
                showAddress={controller.job.showAddress}
                logo={controller.logo ?? null}
                businessName={controller.job.businessName}
                businessAppendix={controller.job.businessAppendix}
                street={controller.job.street}
                zip={controller.job.zip}
                city={controller.job.city}
                hideHotJobNoteOnLogo
                hideRemote={controller.service?.forcedRemote !== null}
              />
            </div>
            <div className="flex flex-col-reverse sm:flex-row items-start sm:justify-between gap-8 sm:gap-0 mt-12 w-full">
              <Button title="zurück" onClick={controller.handleCancel} kind="solid-secondary" />
              <div className="flex flex-row gap-4">
                <Button
                  title="löschen"
                  onClick={controller.handleDelete}
                  disabled={!controller.canDelete || controller.isDeleting}
                  className={clsx(controller.isDeleting && '!cursor-wait')}
                />
                <Button title="kopieren" onClick={controller.handleDuplicate} disabled={controller.isDeleting} />
                <Button
                  title="bearbeiten"
                  onClick={controller.handleEdit}
                  disabled={!controller.canEdit || controller.isDeleting}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        open={controller.showDeleteModal}
        setOpen={controller.setShowDeleteModal}
        title="Möchten Sie die Anzeige wirklich löschen?"
        buttons={[
          {
            title: 'nein',
            onClick: () => {
              controller.setShowDeleteModal(false);
            },
            kind: 'solid-secondary',
          },
          {
            title: 'ja',
            onClick: controller.handleConfirmDelete,
            kind: 'solid',
          },
        ]}>
        Sie ist dann für Bewerber/innen nicht mehr sichtbar. Hier im Account bleibt sie aber erhalten.
      </Modal>
      <Modal
        open={controller.showDuplicateModal}
        setOpen={controller.setShowDuplicateModal}
        title="Möchten Sie die Anzeige kopieren?"
        buttons={[
          {
            title: 'nein',
            onClick: () => {
              controller.setShowDuplicateModal(false);
            },
            kind: 'solid-secondary',
          },
          {
            title: 'ja',
            onClick: controller.handleConfirmDuplicate,
            kind: 'solid',
          },
        ]}>
        Sie können die Anzeige selbstverständlich optional vorher bearbeiten.
      </Modal>
    </>
  );
};

export default DashboardJobsDetailsView;
