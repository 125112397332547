import clsx from 'clsx';

interface NoteProps {
  message: string | React.ReactNode;
  type: 'error' | 'success' | 'info';
  className?: string;
}
const Note: React.FC<NoteProps> = ({ message, type, className }) => {
  return (
    <div
      className={clsx(
        'mt-8 p-4 rounded-md text-md [&>p:last-of-type]:!mb-0',
        type === 'info'
          ? 'bg-[#e6f2ff]'
          : type === 'success'
          ? 'bg-[rgba(212,245,194,0.58)]'
          : 'bg-[rgba(206,0,0,0.14)]',
        className
      )}
      id="note">
      {message}
    </div>
  );
};

export default Note;
