import Button from '../components/Button';
import useLoginController from '../controllers/Login';
import Input from '../components/Input';
import { Link } from 'react-router-dom';
import InputGroup from '../components/InputGroup';
import Note from '../components/Note';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

const LoginView = () => {
  const controller = useLoginController();

  return (
    <div className="w-full lg:w-3/4">
      {controller.error && (
        <Note
          type="error"
          message={controller.error.map((e) => (
            <p>{e}</p>
          ))}
          className="mb-8"
        />
      )}
      {controller.activated && (
        <Note
          type="success"
          message="Ihr Account wurde erfolgreich aktiviert. Sie können sich nun einloggen."
          className="mb-8"
        />
      )}
      <div>
        <p>Loggen Sie sich hier ein, um Ihre Stellenanzeige/n zu verwalten.</p>
        <p>
          Wenn Sie eine Stellenanzeige schalten möchten und noch keinen Account haben, können Sie sich{' '}
          <Link to="/registrieren" className="underline">
            als Arbeitgeber registrieren
          </Link>
          .
        </p>
      </div>
      {!controller.service.hideLoginApplicantInfo && (
        <Note
          type="info"
          message={
            <p>
              <strong>Hinweis für Bewerber/innen:</strong> Als Bewerber/in kannst du dich hier <strong>nicht</strong>{' '}
              einloggen, da du keinen Account benötigst.
            </p>
          }
        />
      )}
      <form className="flex flex-col gap-4 mt-16" onSubmit={controller.handleSubmit}>
        <InputGroup label="E-Mail-Adresse*" horizontal>
          <Input
            value={controller.email}
            onChange={controller.setEmail}
            type="email"
            autoComplete="email"
            maxLength={255}
          />
        </InputGroup>
        <InputGroup
          label="Passwort*"
          horizontal
          helper={
            <>
              <Link to="/passwort-vergessen" className="underline">
                Passwort vergessen
              </Link>
              ?
            </>
          }>
          <Input
            value={controller.password}
            onChange={controller.setPassword}
            autoComplete="current-password"
            type={controller.passwordVisible ? 'text' : 'password'}
            append={
              controller.passwordVisible ? (
                <EyeSlashIcon className="w-5 h-5 cursor-pointer" onClick={() => controller.setPasswordVisible(false)} />
              ) : (
                <EyeIcon className="w-5 h-5 cursor-pointer" onClick={() => controller.setPasswordVisible(true)} />
              )
            }
          />
        </InputGroup>
        <div className="flex flex-row justify-between mt-12 w-full">
          <Button
            title="abbrechen"
            type="button"
            onClick={controller.handleCancel}
            kind="solid-secondary"
            disabled={controller.isLoggingIn}
          />
          <Button
            title="Login"
            type="submit"
            disabled={controller.isLoggingIn}
            className={clsx(controller.isLoggingIn && '!cursor-wait')}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginView;
