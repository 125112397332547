import { useMutation } from '@tanstack/react-query';
import { UseMutationTypeHelper } from '@jobmatic/shared/types';
import { useGetCurrentService } from './Service';
import { MapboxGeoservice, MaptilerGeoservice, PlaceType } from '@jobmatic/shared/geoservice';
import { useMemo } from 'react';
import { Constants } from '@jobmatic/shared/utils';
import { Geoservice } from '@prisma/client';

// apiDomain is the current top-level domain of the hostname, prefixed with "api."
const url = new URL(window.location.href);
const apiDomain =
  process.env['NODE_ENV'] === 'development'
    ? undefined
    : `${url.protocol}//api.${url.hostname.split('.').slice(-2).join('.')}`;

export const useSearchPlace = (
  options?: UseMutationTypeHelper<
    (typeof MaptilerGeoservice)['prototype']['search'],
    {
      query: string;
      proximity?: { lat: number; lon: number };
      filter?: PlaceType[];
      filterCountry?: keyof typeof Constants.COUNTRY_LIST;
    }
  >
) => {
  const { data: service } = useGetCurrentService();
  const geo = useMemo(() => {
    if (!service?.geoservice) {
      return null;
    }
    if (service.geoservice === Geoservice.MAPTILER) return new MaptilerGeoservice(service?.maptilerApiKey || '');
    else return new MapboxGeoservice(apiDomain);
  }, [service?.maptilerApiKey, service?.geoservice]);

  return useMutation((data) => {
    if (!geo) {
      throw new Error('No geoservice found');
    }
    return geo.search(data.query, {
      proximity: data.proximity,
      filter: data.filter,
      filterCountry: data.filterCountry,
    });
  }, options);
};
