import { AdvertiserType, Salutation } from '@prisma/client';
import Button from '../../components/Button';
import useDashboardBaseDataController from '../../controllers/Dashboard/BaseData';
import Input from '../../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import InputGroup from '../../components/InputGroup';
import SelectBox from '../../components/SelectBox';
import { Constants } from '@jobmatic/shared/utils';
import Note from '../../components/Note';
import clsx from 'clsx';
import Modal from '../../components/Modal';

const DashboardBaseDataView = () => {
  const controller = useDashboardBaseDataController();
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full lg:w-3/4">
        <div>
          <p>Bitte halten Sie diese Daten aktuell.</p>
          {controller.advertiser?.type === AdvertiserType.COMPANY && (
            <p>
              Bei Bedarf können Sie{' '}
              <Link to="/dashboard/rechnungsdaten" className="underline">
                hier abweichende Rechnungsdaten
              </Link>{' '}
              hinterlegen.
            </p>
          )}
        </div>
        {controller.success && <Note type="success" message="Ihre Stammdaten wurden erfolgreich gespeichert." />}
        {controller.requiresUserCheck && (
          <Note
            type="info"
            message={
              <>
                <strong>Bitte prüfen Sie Ihre Stammdaten</strong> und bestätigen Sie durch{' '}
                <strong>‹ speichern ›</strong>, dass diese aktuell sind.
              </>
            }
          />
        )}
        {controller.error && (
          <Note
            type="error"
            message={controller.error.map((e) => (
              <p>{e}</p>
            ))}
          />
        )}
        {controller.advertiser &&
          (controller.advertiser.type === AdvertiserType.COMPANY ? (
            <form className="flex flex-col gap-6 mt-16" onSubmit={controller.handleSubmit}>
              <InputGroup label="Unternehmen*" horizontal>
                <Input
                  value={controller.businessName}
                  onChange={controller.setBusinessName}
                  maxLength={60}
                  autoComplete="organization"
                  className={controller.errorFields.includes('businessName') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="Zusatz (optional)" horizontal>
                <Input
                  value={controller.businessNameAppendix}
                  onChange={controller.setBusinessNameAppendix}
                  maxLength={60}
                  placeholder="Abteilung o.ä."
                  className={controller.errorFields.includes('businessAppendix') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="Straße / Hausnummer*" horizontal>
                <Input
                  value={controller.street}
                  onChange={controller.setStreet}
                  maxLength={60}
                  autoComplete="street-address"
                  className={controller.errorFields.includes('street') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="PLZ* / Ort*" horizontal>
                <div className="flex flex-row gap-4">
                  <Input
                    value={controller.zip}
                    onChange={controller.setZip}
                    containerClassName="w-1/3 lg:w-1/4"
                    className={controller.errorFields.includes('zip') ? '!border-error' : undefined}
                    maxLength={8}
                    autoComplete="postal-code"
                  />
                  <Input
                    value={controller.city}
                    onChange={controller.setCity}
                    containerClassName="w-2/3 lg:w-3/4"
                    className={controller.errorFields.includes('city') ? '!border-error' : undefined}
                    maxLength={60}
                    autoComplete="address-level2"
                  />
                </div>
              </InputGroup>
              <InputGroup label="Land*" horizontal>
                <SelectBox
                  value={Constants.COUNTRY_LIST[controller.country]}
                  onChange={(v) =>
                    controller.setCountry(
                      (Object.keys(Constants.COUNTRY_LIST).find(
                        (k) => Constants.COUNTRY_LIST[k as keyof typeof Constants.COUNTRY_LIST] === v
                      ) as keyof typeof Constants.COUNTRY_LIST | undefined) ?? 'DE'
                    )
                  }
                  options={[
                    Constants.COUNTRY_LIST.DE,
                    Constants.COUNTRY_LIST.AT,
                    Constants.COUNTRY_LIST.CH,
                    ...Object.values(Constants.COUNTRY_LIST).filter(
                      (v) =>
                        ![Constants.COUNTRY_LIST.DE, Constants.COUNTRY_LIST.AT, Constants.COUNTRY_LIST.CH].includes(v)
                    ),
                  ]}
                  className={clsx('w-full', controller.errorFields.includes('country') ? 'border-error' : undefined)}
                  autoComplete="country-name"
                />
              </InputGroup>
              <InputGroup label=" " horizontal>
                <div className="mt-8 text-sm font-light opacity-80 sm:opacity-60 [&>p:last-of-type]:!mb-0">
                  <p>
                    Die nachstehenden Kontaktdaten sind für eventuelle{' '}
                    <strong className="font-bold">Rückfragen unsererseits</strong> und werden{' '}
                    <strong className="font-bold">nicht</strong> veröffentlicht.
                  </p>
                  <p>
                    Im <strong className="font-bold">Anzeigentext</strong> können Sie nach Belieben
                    Ansprechpartner/innen und Kontaktdaten angeben.
                  </p>
                </div>
              </InputGroup>
              <InputGroup label="Anrede*" horizontal>
                <SelectBox
                  value={controller.salutation ? controller.salutationOptions[controller.salutation] : ''}
                  onChange={(v) =>
                    controller.setSalutation(
                      (Object.keys(controller.salutationOptions).find(
                        (k) => controller.salutationOptions[k as Salutation] === v
                      ) as Salutation | undefined) ?? null
                    )
                  }
                  options={Object.values(controller.salutationOptions)}
                  autoComplete="honorific-prefix"
                  className={controller.errorFields.includes('salutation') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="Vorname*" horizontal>
                <Input
                  value={controller.firstName}
                  onChange={controller.setFirstName}
                  autoComplete="given-name"
                  maxLength={60}
                  className={controller.errorFields.includes('firstName') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="Nachname*" horizontal>
                <Input
                  value={controller.lastName}
                  onChange={controller.setLastName}
                  autoComplete="family-name"
                  maxLength={60}
                  className={controller.errorFields.includes('lastName') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="Telefon* (mit Vorwahl)" horizontal>
                <Input
                  value={controller.phone}
                  placeholder="+493012345678"
                  onChange={controller.setPhone}
                  type="tel"
                  autoComplete="tel"
                  maxLength={20}
                  className={controller.errorFields.includes('phone') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup
                label="E-Mail-Adresse*"
                horizontal
                helper={
                  <>
                    Ihre E-Mail-Adresse können Sie in den{' '}
                    <Link to="/dashboard/einstellungen/email" className="underline">
                      Einstellungen
                    </Link>{' '}
                    ändern.
                  </>
                }
                className="mt-8">
                <Input value={controller.email} disabled type="email" autoComplete="email" maxLength={255} />
              </InputGroup>
            </form>
          ) : (
            <form className="flex flex-col gap-6 mt-16" onSubmit={controller.handleSubmit}>
              <InputGroup label="Anrede*" horizontal>
                <SelectBox
                  value={controller.salutation ? controller.salutationOptions[controller.salutation] : ''}
                  onChange={(v) =>
                    controller.setSalutation(
                      (Object.keys(controller.salutationOptions).find(
                        (k) => controller.salutationOptions[k as Salutation] === v
                      ) as Salutation | undefined) ?? null
                    )
                  }
                  options={Object.values(controller.salutationOptions)}
                  autoComplete="honorific-prefix"
                  className={controller.errorFields.includes('salutation') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="Vorname*" horizontal>
                <Input
                  value={controller.firstName}
                  onChange={controller.setFirstName}
                  autoComplete="given-name"
                  maxLength={60}
                  className={controller.errorFields.includes('firstName') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="Nachname*" horizontal>
                <Input
                  value={controller.lastName}
                  onChange={controller.setLastName}
                  autoComplete="family-name"
                  maxLength={60}
                  className={controller.errorFields.includes('lastName') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label=" " horizontal>
                <div className="mt-8 text-sm font-light opacity-80 sm:opacity-60 [&>p:last-of-type]:!mb-0">
                  <p>
                    Die nachstehenden Daten werden für die Rechnungsstellung verwendet. In Ihrer Anzeige können diese
                    ausblendet werden.
                  </p>
                  <p>
                    Die Telefonnummer ist für eventuelle <strong className="font-bold">Rückfragen unsererseits</strong>.
                  </p>
                </div>
              </InputGroup>
              <InputGroup label="Straße / Hausnummer*" horizontal>
                <Input
                  value={controller.street}
                  onChange={controller.setStreet}
                  autoComplete="street-address"
                  maxLength={255}
                  className={controller.errorFields.includes('street') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup label="PLZ* / Ort*" horizontal>
                <div className="flex flex-row gap-4">
                  <Input
                    value={controller.zip}
                    onChange={controller.setZip}
                    containerClassName="w-1/3 lg:w-1/4"
                    className={controller.errorFields.includes('zip') ? '!border-error' : undefined}
                    maxLength={8}
                    autoComplete="postal-code"
                  />
                  <Input
                    value={controller.city}
                    onChange={controller.setCity}
                    containerClassName="w-2/3 lg:w-3/4"
                    className={controller.errorFields.includes('city') ? '!border-error' : undefined}
                    maxLength={60}
                    autoComplete="address-level2"
                  />
                </div>
              </InputGroup>
              <InputGroup label="Land*" horizontal>
                <SelectBox
                  value={Constants.COUNTRY_LIST[controller.country]}
                  onChange={(v) =>
                    controller.setCountry(
                      (Object.keys(Constants.COUNTRY_LIST).find(
                        (k) => Constants.COUNTRY_LIST[k as keyof typeof Constants.COUNTRY_LIST] === v
                      ) as keyof typeof Constants.COUNTRY_LIST | undefined) ?? 'DE'
                    )
                  }
                  options={[
                    Constants.COUNTRY_LIST.DE,
                    Constants.COUNTRY_LIST.AT,
                    Constants.COUNTRY_LIST.CH,
                    ...Object.values(Constants.COUNTRY_LIST).filter(
                      (v) =>
                        ![Constants.COUNTRY_LIST.DE, Constants.COUNTRY_LIST.AT, Constants.COUNTRY_LIST.CH].includes(v)
                    ),
                  ]}
                  className={clsx('w-full', controller.errorFields.includes('country') ? 'border-error' : undefined)}
                  autoComplete="country-name"
                />
              </InputGroup>
              <InputGroup label="Telefon* (mit Vorwahl)" horizontal>
                <Input
                  value={controller.phone}
                  placeholder="+493012345678"
                  onChange={controller.setPhone}
                  type="tel"
                  autoComplete="tel"
                  maxLength={20}
                  className={controller.errorFields.includes('phone') ? 'border-error' : undefined}
                />
              </InputGroup>
              <InputGroup
                label="E-Mail-Adresse*"
                horizontal
                helper={
                  <>
                    Ihre E-Mail-Adresse können Sie in den{' '}
                    <Link to="/dashboard/einstellungen/email" className="underline">
                      Einstellungen
                    </Link>{' '}
                    ändern.
                  </>
                }
                className="mt-8">
                <Input value={controller.email} disabled type="email" autoComplete="email" maxLength={255} />
              </InputGroup>
            </form>
          ))}
        <div className="flex flex-row justify-between mt-12 w-full">
          <Button
            title="zurück"
            onClick={controller.handleBack}
            kind="solid-secondary"
            disabled={controller.isSaving}
          />
          <Button
            title="speichern"
            onClick={() => controller.handleSubmit()}
            disabled={controller.isSaving}
            className={clsx(controller.isSaving && '!cursor-wait')}
          />
        </div>
      </div>
      <Modal
        title="Systemwartung"
        open={!!controller.maintenanceMode}
        buttons={[
          {
            title: `OK`,
            onClick: () => navigate('/dashboard'),
          },
        ]}>
        {controller.maintenanceMode}
      </Modal>
    </>
  );
};

export default DashboardBaseDataView;
