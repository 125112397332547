import { useEffect, useMemo, useState } from 'react';
import { useDownloadInvoice, useGetOwnInvoices } from '../../hooks/query/Invoice';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { MainOutletContext } from '../../MainOutlet';
import dayjs from 'dayjs';
import { InvoiceState } from '@prisma/client';
import { useGetOwnAdvertiser } from '../../hooks/query/Advertiser';
import { Helpers } from '@jobmatic/shared/utils';

const useDashboardInvoicesController = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { service } = useOutletContext<MainOutletContext>();
  const [page, setPage] = useState<number>(1);
  const [invoicePdfWindow, setInvoicePdfWindow] = useState<Window | null>(null);
  const [setPageUsingSearchParams, setSetPageUsingSearchParams] = useState<boolean>(false);
  const { data: advertiser } = useGetOwnAdvertiser();
  const { data: invoicesData } = useGetOwnInvoices(page);
  const { mutate: downloadInvoice, isLoading: isDownloading } = useDownloadInvoice({
    onSuccess: (data) => {
      if (!invoicePdfWindow) return;
      invoicePdfWindow.location = data.link;
    },
    onError: () => {
      // TODO: error handling
    },
  });

  const pageCount = useMemo(() => (invoicesData ? Math.ceil(invoicesData.count / 5) : 0), [invoicesData]);
  const invoices = useMemo(
    () =>
      (invoicesData?.invoices ?? []).map((invoice) => ({
        ...invoice,
        state:
          dayjs(invoice.dueDate).isBefore(dayjs()) && invoice.state === InvoiceState.OPEN
            ? 'OVERDUE'
            : (invoice.state as InvoiceState | 'OVERDUE'),
      })),
    [invoicesData]
  );

  useEffect(() => {
    if (
      !setPageUsingSearchParams &&
      pageCount > 0 &&
      searchParams.has('page') &&
      /^\d+$/.test(searchParams.get('page') || '')
    ) {
      const newPage = parseInt(searchParams.get('page') || '');
      if (newPage > 0 && newPage <= pageCount) {
        setPage(newPage);
      }
      setSetPageUsingSearchParams(true);
    }
  }, [searchParams, pageCount, setPageUsingSearchParams]);

  const handlePageChange = (newPage: number) => {
    if (newPage === page || newPage < 1 || newPage > pageCount) return;
    setPage(newPage);
    Helpers.scrollToTop({ jump: true });
  };

  const handleBack = () => {
    navigate('/dashboard');
  };

  const handleInvoiceClicked = (id: number) => {
    if (isDownloading) return;
    setInvoicePdfWindow(window.open('about:blank', '_blank'));
    downloadInvoice({ id });
  };

  return {
    isDownloading,
    advertiser,
    service,
    invoices,
    pageCount,
    page,
    handlePageChange,
    handleBack,
    handleInvoiceClicked,
  };
};

export default useDashboardInvoicesController;
