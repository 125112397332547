import { useEffect, useState } from 'react';
import { useDeleteAccount, useGetOwnAdvertiser } from '../../../hooks/query/Advertiser';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Helpers } from '@jobmatic/shared/utils';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { trpc } from '../../../utils/trpc';
import { TRPCClientError } from '@trpc/client';
import { MainOutletContext } from '../../../MainOutlet';

const useDashboardSettingsDeleteAccountController = () => {
  const navigate = useNavigate();
  const { service } = useOutletContext<MainOutletContext>();
  const { data: advertiser } = useGetOwnAdvertiser();
  const { mutate: deleteAccount, isLoading: isDeleting } = useDeleteAccount({
    onSuccess: async () => {
      await trpc.auth.logout.mutate();
      navigate('/account-geloescht');
    },
    onError: (e) => {
      if (e instanceof TRPCClientError && e.data.apiError?.code === 'generic_support') {
        setError([
          <>
            Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den{' '}
            <a className="underline cursor-pointer" href={`${service.url}/kontakt`} target="_blank" rel="noreferrer">
              Support
            </a>
            .
          </>,
        ]);
        return;
      }
      setError([transformTRPCErrorToMessage(e)]);
    },
  });

  const [deleteReason, setDeleteReason] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<(string | React.ReactNode)[] | null>(null);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!advertiser) return;
    if (!password.length) {
      setError(['Bitte geben Sie Ihr Passwort ein.']);
      return;
    }

    deleteAccount({ advertiserId: advertiser.id, password, deleteReason });
  };

  const handleCancel = () => {
    navigate('/dashboard/einstellungen');
  };

  useEffect(() => {
    if (error) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error]);

  return {
    advertiser,
    isDeleting,
    deleteReason,
    setDeleteReason,
    password,
    setPassword,
    error,
    passwordVisible,
    setPasswordVisible,
    handleSubmit,
    handleCancel,
  };
};

export default useDashboardSettingsDeleteAccountController;
