import { Constants } from '@jobmatic/shared/utils';
// import { config, geocoding } from '@maptiler/client';
import BaseGeoservice, { Place, PlaceType } from './Base';

/* type MaptilerPlaceType = NonNullable<NonNullable<Parameters<typeof geocoding.forward>[1]>['types']>[number];
export default class MaptilerGeoservice implements BaseGeoservice {
  constructor(apiKey: string) {
    config.apiKey = apiKey;
  }

  private parsePlaceTypeToMaptiler(type: PlaceType): MaptilerPlaceType[] {
    switch (type) {
      case 'city':
        return ['county', 'region', 'municipality'];
      default:
        throw new Error(`Unknown place type ${type}`);
    }
  }

  private parseMaptilerToPlaceType(type: MaptilerPlaceType): PlaceType {
    switch (type) {
      case 'municipality':
        return 'city';
      case 'county':
      case 'region':
        return 'region';
      default:
        return 'unknown';
    }
  }

  public async search(
    query: string,
    {
      proximity,
      filter,
      filterCountry,
    }: {
      proximity?: { lat: number; lon: number };
      filter?: PlaceType[];
      filterCountry?: keyof typeof Constants.COUNTRY_LIST;
    }
  ): Promise<Place[]> {
    let result = await geocoding.forward(query, {
      proximity: proximity ? [proximity.lon, proximity.lat] : undefined,
      language: 'de',
      types: filter ? filter.flatMap((type) => this.parsePlaceTypeToMaptiler(type)) : undefined,
      bbox: filterCountry ? Constants.COUNTRY_BBOXES[filterCountry] ?? undefined : undefined,
    });

    if (filterCountry) {
      result = {
        ...result,
        features: result.features.filter(
          (feature) => feature.properties?.['country_code']?.toUpperCase() === filterCountry
        ),
      };
    }

    return result.features
      .filter((feature) => feature.id !== undefined)
      .map((feature) => ({
        id: feature.id!.toString(),
        longName: feature.place_name,
        shortName: feature.text,
        coordinates: { lat: feature.center[1], lng: feature.center[0] },
        // feature.bbox is [w,s,e,n]
        bbox: {
          ne: { lng: feature.bbox[2], lat: feature.bbox[3] },
          sw: { lng: feature.bbox[0], lat: feature.bbox[1] },
        },
        context:
          feature.context?.map((context) => ({
            id: context.id,
            type: this.parseMaptilerToPlaceType(context.id.split('.')[0] as MaptilerPlaceType),
            name: context.text,
          })) ?? [],
      }));
  }
} */

export default class MaptilerGeoservice implements BaseGeoservice {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(_apiKey: string) {}

  private parsePlaceTypeToMaptiler(_type: PlaceType) {
    throw new Error(`Maptiler currently not supported`);
  }

  private parseMaptilerToPlaceType(_type: string) {
    throw new Error(`Maptiler currently not supported`);
  }

  public async search(
    _query: string,
    _options: {
      proximity?: { lat: number; lon: number };
      filter?: PlaceType[];
      filterCountry?: keyof typeof Constants.COUNTRY_LIST;
    }
  ): Promise<Place[]> {
    throw new Error(`Maptiler currently not supported`);
  }
}
