import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../components/Button';
import { MainOutletContext } from '../../../MainOutlet';

const DashboardSettingsMainView: React.FC = () => {
  const navigate = useNavigate();
  const { navigation } = useOutletContext<MainOutletContext>();

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {navigation
          .filter((item) => item.navigation === 'settings')
          .map((item) =>
            typeof item.path === 'string' ? (
              <Link className="border-border border px-6 h-24 rounded-md hover:bg-light transition" to={item.path}>
                <div className="h-full flex flex-row justify-between items-center">
                  <div className="hyphens-auto">{item.title}</div>
                </div>
              </Link>
            ) : (
              <div
                className="border-border border px-6 h-24 rounded-md hover:bg-light transition cursor-pointer"
                onClick={item.path}>
                <div className="h-full flex flex-row justify-between items-center">
                  <div className="hyphens-auto">{item.title}</div>
                </div>
              </div>
            )
          )}
      </div>
      <Button onClick={() => navigate('/dashboard')} title="zurück" className="mt-12" kind="solid-secondary" />
    </>
  );
};

export default DashboardSettingsMainView;
