import clsx from 'clsx';
import { useOutletContext } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { MainOutletContext } from '../MainOutlet';
import { useMemo, useState } from 'react';

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  kind?: 'solid' | 'solid-secondary' | 'outline';
}
const Button: React.FC<ButtonProps> = ({ title, className, kind, disabled, ...rest }) => {
  const { service } = useOutletContext<MainOutletContext>();
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  const backgroundColor = useMemo(() => {
    if (!hovered && !active) {
      return service.buttonColor;
    }
    if (hovered && !active) {
      return service.buttonHoveredColor ?? tinycolor(service.buttonColor).darken(5).toRgbString();
    }
    if (active) {
      return service.buttonPressedColor ?? tinycolor(service.buttonColor).darken(10).toRgbString();
    }
  }, [active, hovered, service.buttonColor, service.buttonHoveredColor, service.buttonPressedColor]);

  return (
    <button
      {...rest}
      className={clsx(
        'text-sm md:text-base text-white px-5 py-2 transition font-semibold border outline-[#707070] focus-visible:outline outline-1',
        kind === undefined || kind === 'solid'
          ? undefined
          : kind === 'solid-secondary'
          ? 'bg-gray hover:bg-[#b3b3b3] active:bg-[#a1a1a1] border-gray'
          : 'color-[rgba(145, 145, 145, .5)] bg-white hover:bg-[#e6e6e6] active:bg-[#d6d6d6] border-border !text-border',
        disabled && 'opacity-50 !cursor-not-allowed',
        className
      )}
      style={{
        ...(kind === undefined || kind === 'solid'
          ? {
              borderColor: backgroundColor,
              backgroundColor,
            }
          : undefined),
        borderRadius: service.buttonRadius,
        ...rest.style,
      }}
      disabled={disabled}
      onMouseEnter={(...args) => {
        setHovered(true);
        setActive(false);
        rest.onMouseEnter?.(...args);
      }}
      onMouseLeave={(...args) => {
        setHovered(false);
        setActive(false);
        rest.onMouseLeave?.(...args);
      }}
      onMouseDown={(...args) => {
        setActive(true);
        setHovered(false);
        rest.onMouseDown?.(...args);
      }}
      onMouseUp={(...args) => {
        setActive(false);
        setHovered(true);
        rest.onMouseUp?.(...args);
      }}>
      {title}
    </button>
  );
};

export default Button;
