import { Link } from 'react-router-dom';
import { MainOutletContext } from '../MainOutlet';

const Footer: React.FC<{ context: MainOutletContext }> = ({ context }) => {
  const { navigation } = context;
  return (
    <footer className="mt-auto w-full mx-auto max-w-6xl sm:px-6 lg:px-8 pt-24 pb-2 flex flex-col items-center gap-2 sm:gap-0 sm:flex-row sm:justify-evenly text-xs md:text-sm">
      {navigation
        .filter((item) => item.navigation === 'footer')
        .map((item) =>
          typeof item.path === 'string' ? (
            <Link to={item.path} className="opacity-50 hover:opacity-100" target="_blank">
              {item.title}
            </Link>
          ) : (
            <div onClick={item.path} className="cursor-pointer opacity-50 hover:opacity-100">
              {item.title}
            </div>
          )
        )}
    </footer>
  );
};

export default Footer;
