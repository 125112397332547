import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useActivateAccount } from '../hooks/query/Auth';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { TRPCClientError } from '@trpc/client';

const useActivateAccountController = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate: activateAccount, isSuccess: success } = useActivateAccount({
    onError: (e) => {
      if (e instanceof TRPCClientError && e.data.apiError?.code === 'invalid_activation_token') {
        setError([
          <>
            <p>Wir können Ihre Registrierung nicht finden.</p>
            <p>
              <strong>Bitte beachten Sie:</strong> Wenn die Aktivierung Ihres Accounts nicht innerhalb von 24 Stunden
              stattgefunden hat, wurde die Registrierung automatisch gelöscht.
            </p>
            <p>
              <Link to="/registrieren" className="underline">
                Registrieren Sie sich einfach noch einmal
              </Link>
              .
            </p>
          </>,
        ]);
      } else setError([transformTRPCErrorToMessage(e)]);
    },
  });
  const [error, setError] = useState<string[] | React.ReactNode[] | null>(null);

  useEffect(() => {
    if (searchParams.get('token')) {
      setError(null);
      activateAccount({
        token: searchParams.get('token')!,
      });
    } else {
      setError([ErrorMessages.invalid_activation_token]);
    }
  }, [searchParams, activateAccount]);

  const handleGoToLogin = () => {
    navigate('/');
  };

  return {
    success,
    error,
    handleGoToLogin,
  };
};

export default useActivateAccountController;
