import { AdvertiserType, Salutation } from '@prisma/client';
import Button from '../components/Button';
import useSignupController from '../controllers/Signup';
import Input from '../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import InputGroup from '../components/InputGroup';
import SelectBox from '../components/SelectBox';
import { Constants } from '@jobmatic/shared/utils';
import Note from '../components/Note';
import Checkbox from '../components/Checkbox';
import PageHeading from '../components/PageHeading';
import clsx from 'clsx';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

const SignupView = () => {
  const navigate = useNavigate();
  const controller = useSignupController();

  return controller.success ? (
    <>
      <PageHeading>Account aktivieren</PageHeading>
      <div className="w-full lg:w-3/4">
        <div>
          <p>
            Zur Aktivierung Ihres Accounts haben wir Ihnen eine E-Mail an <strong>{controller.email.trim()}</strong>{' '}
            geschickt.
            <br />
            Schauen Sie ggf. in Ihren Spam-Ordner.
          </p>
          <p>
            Sollten Sie sich bei der E-Mail-Adresse vertippt haben,{' '}
            <span className="underline cursor-pointer" onClick={controller.handleBack}>
              registrieren Sie sich einfach noch einmal
            </span>
            .
          </p>
          <p>
            <strong>Bitte beachten Sie:</strong> Wenn die Aktivierung Ihres Accounts nicht innerhalb von 24 Stunden
            stattfindet, wird die Registrierung automatisch gelöscht.
          </p>
        </div>
        <Button title="Home" className="mt-12" onClick={() => navigate('/')} />
      </div>
    </>
  ) : (
    <>
      <PageHeading>{controller.advertiserType ? 'Registrierung' : 'Registrierung als Arbeitgeber'}</PageHeading>
      {!controller.advertiserType ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div
            className="border border-border rounded-md p-6 cursor-pointer hover:bg-light transition"
            onClick={() => controller.setAdvertiserType(AdvertiserType.COMPANY)}>
            <h2 className="text-2xl font-semibold mb-4">Gewerblicher Arbeitgeber</h2>
            <p className="text-md !mb-0">
              Registrieren Sie sich hier als Unternehmen bzw. gewerblicher Arbeitgeber, öffentliche und institutionelle
              Einrichtung, Verein etc.
            </p>
          </div>
          <div
            className="border border-border rounded-md p-6 cursor-pointer hover:bg-light transition"
            onClick={() => controller.setAdvertiserType(AdvertiserType.PRIVATE)}>
            <h2 className="text-2xl font-semibold mb-4">Privater Arbeitgeber</h2>
            <p className="text-md !mb-0">
              Registrieren Sie sich hier, wenn Sie als Privathaushalt/-person eine Stelle anzubieten haben.
            </p>
          </div>
        </div>
      ) : controller.advertiserType === AdvertiserType.COMPANY ? (
        <div className="w-full lg:w-3/4">
          <div>
            <p>
              Registrieren Sie sich hier als <strong>Unternehmen bzw. gewerblicher Arbeitgeber</strong> sowie
              öffentliche und institutionelle Einrichtung, Verein etc.
            </p>
            {controller.service.advertiserTypes.includes(AdvertiserType.PRIVATE) && (
              <p>
                Wenn Sie als Privatperson/-haushalt ein Stellenangebot aufgeben möchten, nutzen Sie bitte{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={() => controller.setAdvertiserType(AdvertiserType.PRIVATE)}>
                  dieses Formular
                </span>
                .
              </p>
            )}
            <p>
              Sie haben bereits einen Account? Dann können Sie sich{' '}
              <span className="underline cursor-pointer" onClick={() => navigate('/')}>
                hier einloggen
              </span>
              .
            </p>
          </div>
          {controller.error && (
            <Note
              type="error"
              message={controller.error.map((e) => (
                <p>{e}</p>
              ))}
            />
          )}
          <form className="flex flex-col gap-6 mt-16" onSubmit={controller.handleSubmit}>
            <InputGroup label="Unternehmen*" horizontal>
              <Input
                value={controller.businessName}
                onChange={controller.setBusinessName}
                maxLength={60}
                autoComplete="organization"
                className={controller.errorFields.includes('businessName') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Zusatz (optional)" horizontal>
              <Input
                value={controller.businessNameAppendix}
                onChange={controller.setBusinessNameAppendix}
                maxLength={60}
                placeholder="Abteilung o.ä."
                className={controller.errorFields.includes('businessAppendix') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Straße / Hausnummer*" horizontal>
              <Input
                value={controller.street}
                onChange={controller.setStreet}
                maxLength={60}
                autoComplete="street-address"
                className={controller.errorFields.includes('street') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="PLZ* / Ort*" horizontal>
              <div className="flex flex-row gap-4">
                <Input
                  value={controller.zip}
                  onChange={controller.setZip}
                  containerClassName="w-1/3 lg:w-1/4"
                  className={controller.errorFields.includes('zip') ? '!border-error' : undefined}
                  maxLength={8}
                  autoComplete="postal-code"
                />
                <Input
                  value={controller.city}
                  onChange={controller.setCity}
                  containerClassName="w-2/3 lg:w-3/4"
                  className={controller.errorFields.includes('city') ? '!border-error' : undefined}
                  maxLength={60}
                  autoComplete="address-level2"
                />
              </div>
            </InputGroup>
            <InputGroup label="Land*" horizontal>
              <SelectBox
                value={Constants.COUNTRY_LIST[controller.country]}
                onChange={(v) =>
                  controller.setCountry(
                    (Object.keys(Constants.COUNTRY_LIST).find(
                      (k) => Constants.COUNTRY_LIST[k as keyof typeof Constants.COUNTRY_LIST] === v
                    ) as keyof typeof Constants.COUNTRY_LIST | undefined) ?? 'DE'
                  )
                }
                options={[
                  Constants.COUNTRY_LIST.DE,
                  Constants.COUNTRY_LIST.AT,
                  Constants.COUNTRY_LIST.CH,
                  ...Object.values(Constants.COUNTRY_LIST).filter(
                    (v) =>
                      ![Constants.COUNTRY_LIST.DE, Constants.COUNTRY_LIST.AT, Constants.COUNTRY_LIST.CH].includes(v)
                  ),
                ]}
                className={clsx('w-full', controller.errorFields.includes('country') ? '!border-error' : undefined)}
                autoComplete="country-name"
              />
            </InputGroup>
            <InputGroup label=" " horizontal className="mt-8">
              <div className="text-sm font-light opacity-80 sm:opacity-60 [&>p:last-of-type]:!mb-0">
                <p>
                  Die nachstehenden Kontaktdaten sind für eventuelle{' '}
                  <strong className="font-bold">Rückfragen unsererseits</strong> und werden{' '}
                  <strong className="font-bold">nicht</strong> veröffentlicht.
                </p>
                <p>
                  Im <strong className="font-bold">Anzeigentext</strong> können Sie nach Belieben Ansprechpartner/innen
                  und Kontaktdaten angeben.
                </p>
              </div>
            </InputGroup>
            <InputGroup label="Anrede*" horizontal>
              <SelectBox
                value={controller.salutation ? controller.salutationOptions[controller.salutation] : ''}
                placeholder="bitte wählen"
                onChange={(v) =>
                  controller.setSalutation(
                    (Object.keys(controller.salutationOptions).find(
                      (k) => controller.salutationOptions[k as Salutation] === v
                    ) as Salutation | undefined) ?? null
                  )
                }
                options={Object.values(controller.salutationOptions)}
                autoComplete="honorific-prefix"
                className={controller.errorFields.includes('salutation') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Vorname*" horizontal>
              <Input
                value={controller.firstName}
                onChange={controller.setFirstName}
                autoComplete="given-name"
                maxLength={60}
                className={controller.errorFields.includes('firstName') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Nachname*" horizontal>
              <Input
                value={controller.lastName}
                onChange={controller.setLastName}
                autoComplete="family-name"
                maxLength={60}
                className={controller.errorFields.includes('lastName') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Telefon* (mit Vorwahl)" horizontal>
              <Input
                value={controller.phone}
                placeholder="+493012345678"
                onChange={controller.setPhone}
                type="tel"
                autoComplete="tel"
                maxLength={20}
                className={controller.errorFields.includes('phone') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label=" " horizontal className="mt-8">
              <div className="text-sm font-light opacity-80 sm:opacity-60">
                Diese E-Mail-Adresse ist zum Einloggen. Für Bewerbungen können Sie bei Bedarf eine andere angeben.
              </div>
            </InputGroup>
            <InputGroup label="E-Mail-Adresse*" horizontal>
              <Input
                value={controller.email}
                onChange={controller.setEmail}
                type="email"
                autoComplete="email"
                maxLength={255}
                className={controller.errorFields.includes('email') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Passwort*" horizontal helper="mindestens 8 Zeichen, Buchstaben + Zahlen + Sonderzeichen">
              <Input
                value={controller.password}
                onChange={controller.setPassword}
                type={controller.passwordVisible ? 'text' : 'password'}
                append={
                  controller.passwordVisible ? (
                    <EyeSlashIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => controller.setPasswordVisible(false)}
                    />
                  ) : (
                    <EyeIcon className="w-5 h-5 cursor-pointer" onClick={() => controller.setPasswordVisible(true)} />
                  )
                }
                autoComplete="new-password"
                className={controller.errorFields.includes('password') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label=" " horizontal>
              <Checkbox
                label={
                  <>
                    Ich akzeptiere die{' '}
                    <Link to={`${controller.service.url}/rechtliches#agb`} target="_blank" className="underline">
                      AGB
                    </Link>{' '}
                    und die{' '}
                    <Link
                      to={`${controller.service.url}/rechtliches#datenschutz`}
                      target="_blank"
                      className="underline">
                      Datenschutzerklärung
                    </Link>
                    .
                  </>
                }
                checked={controller.terms}
                onChange={controller.setTerms}
                className={controller.errorFields.includes('terms') ? '!border-error' : undefined}
              />
            </InputGroup>
          </form>
        </div>
      ) : (
        <div className="w-full lg:w-3/4">
          <div>
            <p>
              Hier können Sie sich <strong>als Privatperson/-haushalt</strong> registrieren.
              {controller.service.advertiserTypes.includes(AdvertiserType.COMPANY) && (
                <>
                  {' '}
                  Als gewerblicher Arbeitgeber nutzen Sie bitte{' '}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => controller.setAdvertiserType(AdvertiserType.COMPANY)}>
                    dieses Formular
                  </span>
                  .
                </>
              )}
            </p>
            <p>
              <strong>Bitte beachten Sie</strong>: Private Inserenten, deren Stellenangebote keinen eindeutigen privaten
              Charakter haben, werden kommentarlos gelöscht.
            </p>
            <p>
              Sie haben bereits einen Account? Dann können Sie sich{' '}
              <span className="underline cursor-pointer" onClick={() => navigate('/')}>
                hier einloggen
              </span>
              .
            </p>
          </div>
          {controller.error && (
            <Note
              type="error"
              message={controller.error.map((e) => (
                <p>{e}</p>
              ))}
            />
          )}
          <form className="flex flex-col gap-6 mt-16" onSubmit={controller.handleSubmit}>
            <InputGroup label="Anrede*" horizontal>
              <SelectBox
                value={controller.salutation ? controller.salutationOptions[controller.salutation] : ''}
                placeholder="bitte wählen"
                onChange={(v) =>
                  controller.setSalutation(
                    (Object.keys(controller.salutationOptions).find(
                      (k) => controller.salutationOptions[k as Salutation] === v
                    ) as Salutation | undefined) ?? null
                  )
                }
                options={Object.values(controller.salutationOptions)}
                autoComplete="honorific-prefix"
                className={controller.errorFields.includes('salutation') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Vorname*" horizontal>
              <Input
                value={controller.firstName}
                onChange={controller.setFirstName}
                autoComplete="given-name"
                maxLength={60}
                className={controller.errorFields.includes('firstName') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Nachname*" horizontal>
              <Input
                value={controller.lastName}
                onChange={controller.setLastName}
                autoComplete="family-name"
                maxLength={60}
                className={controller.errorFields.includes('lastName') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Straße / Hausnummer*" horizontal className="mt-8">
              <Input
                value={controller.street}
                onChange={controller.setStreet}
                autoComplete="street-address"
                maxLength={255}
                className={controller.errorFields.includes('street') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="PLZ* / Ort*" horizontal>
              <div className="flex flex-row gap-4">
                <Input
                  value={controller.zip}
                  onChange={controller.setZip}
                  containerClassName="w-1/3 lg:w-1/4"
                  className={controller.errorFields.includes('zip') ? '!border-error' : undefined}
                  maxLength={8}
                  autoComplete="postal-code"
                />
                <Input
                  value={controller.city}
                  onChange={controller.setCity}
                  containerClassName="w-2/3 lg:w-3/4"
                  className={controller.errorFields.includes('city') ? '!border-error' : undefined}
                  maxLength={60}
                  autoComplete="address-level2"
                />
              </div>
            </InputGroup>
            <InputGroup label="Land*" horizontal>
              <SelectBox
                value={Constants.COUNTRY_LIST[controller.country]}
                onChange={(v) =>
                  controller.setCountry(
                    (Object.keys(Constants.COUNTRY_LIST).find(
                      (k) => Constants.COUNTRY_LIST[k as keyof typeof Constants.COUNTRY_LIST] === v
                    ) as keyof typeof Constants.COUNTRY_LIST | undefined) ?? 'DE'
                  )
                }
                options={[
                  Constants.COUNTRY_LIST.DE,
                  Constants.COUNTRY_LIST.AT,
                  Constants.COUNTRY_LIST.CH,
                  ...Object.values(Constants.COUNTRY_LIST).filter(
                    (v) =>
                      ![Constants.COUNTRY_LIST.DE, Constants.COUNTRY_LIST.AT, Constants.COUNTRY_LIST.CH].includes(v)
                  ),
                ]}
                className={clsx('w-full', controller.errorFields.includes('country') ? '!border-error' : undefined)}
                autoComplete="country-name"
              />
            </InputGroup>
            <InputGroup label=" " horizontal className="mt-8">
              <div className="text-sm font-light opacity-80 sm:opacity-60">
                Bitte geben Sie eine Telefonnummer für eventuelle{' '}
                <span className="font-bold">Rückfragen unsererseits</span> an. Diese wird nicht mit Ihrer Anzeige
                veröffentlicht.
              </div>
            </InputGroup>
            <InputGroup label="Telefon* (mit Vorwahl)" horizontal>
              <Input
                value={controller.phone}
                placeholder="+493012345678"
                onChange={controller.setPhone}
                type="tel"
                autoComplete="tel"
                maxLength={20}
                className={controller.errorFields.includes('phone') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label=" " horizontal className="mt-8">
              <div className="text-sm font-light opacity-80 sm:opacity-60">
                Diese E-Mail-Adresse ist zum Einloggen. Für Bewerbungen können Sie bei Bedarf eine andere angeben.
              </div>
            </InputGroup>
            <InputGroup label="E-Mail-Adresse*" horizontal>
              <Input
                value={controller.email}
                onChange={controller.setEmail}
                type="email"
                autoComplete="email"
                maxLength={255}
                className={controller.errorFields.includes('email') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Passwort*" horizontal helper="mindestens 8 Zeichen, Buchstaben + Zahlen + Sonderzeichen">
              <Input
                value={controller.password}
                onChange={controller.setPassword}
                type={controller.passwordVisible ? 'text' : 'password'}
                append={
                  controller.passwordVisible ? (
                    <EyeSlashIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => controller.setPasswordVisible(false)}
                    />
                  ) : (
                    <EyeIcon className="w-5 h-5 cursor-pointer" onClick={() => controller.setPasswordVisible(true)} />
                  )
                }
                autoComplete="new-password"
                className={controller.errorFields.includes('password') ? '!border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label=" " horizontal>
              <Checkbox
                label={
                  <>
                    Ich akzeptiere die{' '}
                    <Link to={`${controller.service.url}/rechtliches#agb`} target="_blank" className="underline">
                      AGB
                    </Link>{' '}
                    und die{' '}
                    <Link
                      to={`${controller.service.url}/rechtliches#datenschutz`}
                      target="_blank"
                      className="underline">
                      Datenschutzerklärung
                    </Link>
                    .
                  </>
                }
                checked={controller.terms}
                onChange={controller.setTerms}
                className={controller.errorFields.includes('terms') ? '!border-error' : undefined}
              />
            </InputGroup>
          </form>
        </div>
      )}
      <div className="flex flex-row justify-between mt-12 w-full lg:w-3/4">
        <Button
          title="zurück"
          onClick={controller.handleBack}
          kind="solid-secondary"
          disabled={controller.isSigningUp}
        />
        {controller.advertiserType && (
          <Button
            title="weiter"
            onClick={() => controller.handleSubmit()}
            disabled={controller.isSigningUp}
            className={clsx(controller.isSigningUp && '!cursor-wait')}
          />
        )}
      </div>
    </>
  );
};

export default SignupView;
