import { useEffect, useMemo, useState } from 'react';
import { useGetOwnJobs } from '../../../hooks/query/Job';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { MainOutletContext } from '../../../MainOutlet';
import { Helpers } from '@jobmatic/shared/utils';

const useDashboardJobsMainController = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { service } = useOutletContext<MainOutletContext>();
  const [page, setPage] = useState<number>(1);
  const [setPageUsingSearchParams, setSetPageUsingSearchParams] = useState<boolean>(false);
  const { data: jobsData } = useGetOwnJobs(page);

  const pageCount = useMemo(() => (jobsData ? Math.ceil(jobsData.count / 5) : 0), [jobsData]);

  useEffect(() => {
    if (
      !setPageUsingSearchParams &&
      pageCount > 0 &&
      searchParams.has('page') &&
      /^\d+$/.test(searchParams.get('page') || '')
    ) {
      const newPage = parseInt(searchParams.get('page') || '');
      if (newPage > 0 && newPage <= pageCount) {
        setPage(newPage);
      }
      setSetPageUsingSearchParams(true);
    }
  }, [searchParams, pageCount, setPageUsingSearchParams]);

  const handlePageChange = (newPage: number) => {
    if (newPage === page || newPage < 1 || newPage > pageCount) return;
    setPage(newPage);
    Helpers.scrollToTop({ jump: true });
  };

  const handleBack = () => {
    navigate('/dashboard');
  };

  const handleCreateJob = () => {
    navigate('/dashboard/inserieren');
  };

  const handleJobClicked = (id: number) => {
    navigate(`/dashboard/anzeigen/${id}?page=${page}`);
  };

  return {
    service,
    jobs: jobsData?.jobs,
    pageCount,
    page,
    handlePageChange,
    handleBack,
    handleCreateJob,
    handleJobClicked,
  };
};

export default useDashboardJobsMainController;
