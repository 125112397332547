import { useEffect, useState } from 'react';
import { useChangePassword, useGetOwnAdvertiser } from '../../../hooks/query/Advertiser';
import { useNavigate } from 'react-router-dom';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { Helpers } from '@jobmatic/shared/utils';
import { trpc } from '../../../utils/trpc';

const useDashboardSettingsChangePasswordController = () => {
  const navigate = useNavigate();
  const { data: advertiser } = useGetOwnAdvertiser();
  const { mutate: changePassword, isLoading: isSubmitting } = useChangePassword({
    onSuccess: async () => {
      await trpc.auth.logout.mutate();
      navigate('/passwort-geaendert?logout=1');
    },
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
  });
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
  const [error, setError] = useState<string[] | null>(null);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState<boolean>(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [newPasswordConfirmationVisible, setNewPasswordConfirmationVisible] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!advertiser) return;
    if (newPassword !== newPasswordConfirmation) {
      setError(['Die Passwörter stimmen nicht überein.']);
      return;
    }

    changePassword({ advertiserId: advertiser.id, newPassword, currentPassword });
  };

  const handleCancel = () => {
    navigate('/dashboard/einstellungen');
  };

  const handleGoToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    if (error) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error]);

  return {
    advertiser,
    isSubmitting,
    error,
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    newPasswordConfirmation,
    setNewPasswordConfirmation,
    currentPasswordVisible,
    setCurrentPasswordVisible,
    newPasswordVisible,
    setNewPasswordVisible,
    newPasswordConfirmationVisible,
    setNewPasswordConfirmationVisible,
    handleSubmit,
    handleCancel,
    handleGoToHome,
  };
};

export default useDashboardSettingsChangePasswordController;
