import dayjs from 'dayjs';
import Button from '../../components/Button';
import useDashboardInvoicesController from '../../controllers/Dashboard/Invoices';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import { AdvertiserType } from '@prisma/client';

const DashboardInvoicesView: React.FC = () => {
  const controller = useDashboardInvoicesController();

  return (
    <>
      <div className="flex flex-col">
        {controller.invoices && controller.invoices.length > 0 && (
          <div
            className={clsx(
              'flex flex-col',
              controller.isDownloading && '!cursor-wait [&>*]:!cursor-wait !opacity-50'
            )}>
            <div className="flex flex-row justify-between items-center border-b border-b-border font-bold pb-8">
              <div className="w-1/3 sm:w-[28.33%]">Rechnung</div>
              <div className="w-1/3 sm:w-[28.33%]">Fälligkeit</div>
              <div className="w-1/3 sm:w-[28.33%]">Betrag</div>
              <div className="hidden sm:block w-[15%]">Status</div>
            </div>
            {controller.invoices.map((invoice) => (
              <div
                className="flex flex-row justify-between items-start border-b border-b-border py-4 lg:py-8 hover:bg-light cursor-pointer"
                onClick={() => controller.handleInvoiceClicked(invoice.id)}>
                <div className="w-1/3 sm:w-[28.33%] flex flex-col items-start gap-2">
                  <div>{invoice.invoiceNumber}</div>
                  <div
                    className={clsx(
                      'block sm:hidden mt-2 rounded-[3px] px-2 py-1 text-[0.8rem] w-fit',
                      invoice.state === 'OVERDUE'
                        ? 'bg-[hsla(358.8819875776398,0.00%,32.65%,1.00)] text-white'
                        : 'bg-[rgba(0,0,0,0.07)]'
                    )}>
                    {Helpers.parseInvoiceState(invoice.state)}
                  </div>
                </div>
                <div className="w-1/3 sm:w-[28.33%]">{dayjs(invoice.dueDate).format('DD.MM.YYYY')}</div>
                <div className="w-1/3 sm:w-[28.33%]">
                  {Helpers.parsePrice(
                    invoice.invoiceAmountNet *
                      (controller.advertiser &&
                      Constants.EU_COUNTRY_CODES.includes(
                        controller.advertiser.invoiceCountry ?? controller.advertiser.baseCountry
                      ) &&
                      controller.advertiser.type === AdvertiserType.COMPANY
                        ? 1
                        : 1 + invoice.vatRate)
                  )}{' '}
                  €
                </div>
                <div className="hidden sm:block w-[15%]">
                  <div
                    className={clsx(
                      'rounded-[3px] px-2 py-1 text-[0.8rem] w-fit',
                      invoice.state === 'OVERDUE'
                        ? 'bg-[hsla(358.8819875776398,0.00%,32.65%,1.00)] text-white'
                        : 'bg-[rgba(0,0,0,0.07)]'
                    )}>
                    {Helpers.parseInvoiceState(invoice.state)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-row self-center justify-between gap-2 mt-8">
          {controller.page !== 1 && (
            <Button onClick={() => controller.handlePageChange(controller.page - 1)} title="«" kind="outline" />
          )}
          {controller.page !== Math.max(controller.pageCount, 1) && (
            <Button onClick={() => controller.handlePageChange(controller.page + 1)} title="»" kind="outline" />
          )}
        </div>
      </div>
      <Button onClick={controller.handleBack} title="zurück" kind="solid-secondary" className="mt-12" />
    </>
  );
};

export default DashboardInvoicesView;
