import { OccupationType, RemoteOption } from '@prisma/client';
import InputGroup from './InputGroup';
import { Constants } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import { useOutletContext } from 'react-router-dom';
import { MainOutletContext } from '../MainOutlet';

interface JobDetailsProps {
  title: string;
  descriptionHtml: string;
  locations: { city: string; order: number }[];
  country: keyof typeof Constants.COUNTRY_LIST;
  remote: RemoteOption;
  minAge: number | null;
  occupationType: OccupationType[];
  hotJob: boolean;
  showEqualityNote: boolean;
  showAddress: boolean;
  logo: { base64: string; mimeType: string } | null;
  businessName: string;
  businessAppendix: string | null;
  street: string;
  zip: string;
  city: string;
  hideHotJobNoteOnLogo?: boolean;
  hideRemote?: boolean;
}

const JobDetails: React.FC<JobDetailsProps> = ({
  title,
  descriptionHtml,
  locations,
  country,
  remote,
  minAge,
  occupationType,
  hotJob,
  showEqualityNote,
  showAddress,
  logo,
  businessName,
  businessAppendix,
  street,
  zip,
  city,
  hideHotJobNoteOnLogo,
  hideRemote,
}) => {
  const { service } = useOutletContext<MainOutletContext>();

  return (
    <>
      <InputGroup horizontal label="Anzeigentitel">
        <span className="text-2xl font-bold">{title}</span>
      </InputGroup>
      <InputGroup horizontal label="Jobbeschreibung" className="mt-6">
        <div
          className="[&>h2:first-child]:mt-0 [&>h2]:text-xl [&>h2]:font-bold [&>h2]:my-2 [&_strong]:opacity-90 [&_ul]:list-disc [&_ul]:ml-6 [&_li>p]:inline [&_a]:underline [&_a]:cursor-pointer"
          dangerouslySetInnerHTML={{ __html: descriptionHtml }}
        />
      </InputGroup>
      {remote !== RemoteOption.ONLY && (
        <InputGroup
          horizontal
          label={service.allowMultipleCities ? 'Arbeitsort/e' : 'Arbeitsort / Stadt'}
          className="mt-6">
          {[...locations]
            .sort((a, b) => a.order - b.order)
            .map((v) => v.city)
            .join(', ')}
        </InputGroup>
      )}
      {!hideRemote && Constants.REMOTE_OPTIONS(Constants.COUNTRY_LIST[country], country)[remote].preview !== null && (
        <InputGroup horizontal label="Remote / Home-Office" className="mt-6">
          {Constants.REMOTE_OPTIONS(Constants.COUNTRY_LIST[country], country)[remote].preview}
        </InputGroup>
      )}
      {minAge !== null && (
        <InputGroup horizontal label="Mindestalter" className="mt-6">
          {minAge} Jahre
        </InputGroup>
      )}
      <InputGroup horizontal label="Beschäftigungsverhältnis" className="mt-6">
        {occupationType
          .filter((v) => !service.filteredOccupationTypes.includes(v))
          .map((v) => Constants.OCCUPATION_TYPE_OPTIONS[v])
          .join(', ')}
      </InputGroup>
      {showEqualityNote && (
        <InputGroup horizontal label=" " className="mt-6 opacity-50">
          Die Anzeige ist aus Gründen der leichteren Lesbarkeit ggf. nicht überall geschlechts­neutral formuliert. Sie
          richtet sich aber grundsätzlich an alle Menschen.
        </InputGroup>
      )}
      <InputGroup horizontal className="mt-16" label="Inserent / Arbeitgeber">
        <div className="flex flex-col sm:flex-row items-start gap-4">
          {logo && (
            <div className="w-52 h-52 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-52 lg:h-52 object-contain p-4 border border-border relative flex items-center justify-center">
              {!hotJob && !hideHotJobNoteOnLogo && (
                <div className="absolute text-center left-0 right-0">
                  Wählen Sie die {service.hotJobName}-Option, damit Ihr Logo angezeigt wird!
                </div>
              )}
              <img
                src={`data:${logo.mimeType};base64,${logo.base64}`}
                alt="Logo"
                className={clsx('w-full h-full object-contain', hotJob ? 'opacity-100' : 'opacity-5')}
              />
            </div>
          )}
          <div className="flex flex-col gap-4">
            <div>
              {businessName}
              <br />
              {businessAppendix !== null && (
                <>
                  {businessAppendix}
                  <br />
                </>
              )}
              <span className={clsx('transition', !showAddress ? 'opacity-30' : 'opacity-100')}>
                {street} <br />
                {zip} {city}
              </span>
            </div>
          </div>
        </div>
      </InputGroup>
    </>
  );
};

export default JobDetails;
