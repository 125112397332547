import Input from '../../components/Input';
import InputGroup from '../../components/InputGroup';
import useDashboardCreateJobListingController from '../../controllers/Dashboard/CreateJobListing';
import JobOption from '../../components/JobOption';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import { AdvertiserType, PaymentMethod } from '@prisma/client';
import clsx from 'clsx';
import Button from '../../components/Button';
import RadioButtons from '../../components/RadioButtons';
import Note from '../../components/Note';
import JobDetails from '../../components/JobDetails';
import JobEditor, { JobEditorFields } from '../../components/JobEditor';
import Modal from '../../components/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '../../components/Checkbox';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

const DashboardCreateJobListingView = () => {
  const controller = useDashboardCreateJobListingController();
  const navigate = useNavigate();

  if (!controller.service) return null; // TODO: Loading state
  return (
    <>
      <div className="w-full lg:w-[90%]">
        {controller.showPreview ? (
          <>
            <div>
              <p className="font-bold">Hier die Vorschau Ihres Inserats.</p>
            </div>
            {controller.error.length > 0 && (
              <Note
                type="error"
                message={controller.error.map((e) => (
                  <p>{e}</p>
                ))}
                className="mt-8"
              />
            )}
            <div className="mt-16">
              {controller.advertiser && (
                <JobDetails
                  title={controller.jobEditorState.title}
                  descriptionHtml={controller.jobEditorState.description}
                  locations={controller.jobEditorState.locations}
                  country={controller.jobEditorState.country}
                  remote={controller.jobEditorState.remote}
                  minAge={controller.service.minAge === null ? null : controller.jobEditorState.minAge}
                  occupationType={controller.jobEditorState.occupationType}
                  hotJob={controller.hotJob}
                  showEqualityNote={controller.jobEditorState.showEqualityNote}
                  showAddress={controller.jobEditorState.showAddress}
                  logo={controller.logo ?? null}
                  businessName={
                    controller.advertiser.type === AdvertiserType.COMPANY
                      ? controller.advertiser.baseBusinessName || ''
                      : `${controller.advertiser.baseFirstName} ${controller.advertiser.baseLastName}`
                  }
                  businessAppendix={controller.advertiser.baseBusinessAppendix}
                  street={controller.advertiser.baseStreet}
                  zip={controller.advertiser.baseZip}
                  city={controller.advertiser.baseCity}
                  hideRemote={controller.service.forcedRemote !== null}
                />
              )}
            </div>
          </>
        ) : controller.showSummary ? (
          <>
            <div>
              <p className="font-bold">Bitte prüfen Sie Ihre Buchung.</p>
            </div>
            {controller.error.length > 0 && (
              <Note
                type="error"
                message={controller.error.map((e) => (
                  <p>{e}</p>
                ))}
                className="mt-8"
              />
            )}
            <div className="mt-16">
              <InputGroup
                horizontal
                label={
                  <>
                    <div>Ihre Buchung</div>
                    {!controller.couponDiscount && controller.jobOption !== 'FREE' && (
                      <div className="opacity-80 sm:opacity-60 !text-dark text-sm !font-normal sm:max-w-full mb-4 sm:mb-auto">
                        Falls Sie einen Gutschein-Code haben, können Sie diesen{' '}
                        <span className="underline cursor-pointer" onClick={controller.openCouponModal}>
                          hier eingeben
                        </span>
                        .
                      </div>
                    )}
                  </>
                }>
                <div className="flex flex-row justify-between mb-1 gap-6">
                  <div>
                    Stellenanzeige –{' '}
                    {controller.jobOption === 'FREE'
                      ? `${controller.service.freeOptionDuration} Wochen Laufzeit (kostenlos)`
                      : `${controller.jobOption} Wochen Laufzeit`}
                  </div>
                  <div>
                    <span>
                      {Helpers.parsePrice(
                        (controller.jobOption === 'FREE'
                          ? 0
                          : controller.jobOption === 2
                          ? controller.service.priceTwoWeeks
                          : controller.service.priceFourWeeks) as number
                      )}
                      &nbsp;Euro
                    </span>
                  </div>
                </div>
                {controller.hotJob && controller.service.priceHotJob !== null && (
                  <div className="flex flex-row justify-between my-1 gap-6">
                    <div>{controller.service.hotJobName}-Option</div>
                    <div>{Helpers.parsePrice(controller.service.priceHotJob)}&nbsp;Euro</div>
                  </div>
                )}
                {!!controller.price?.coupon && (
                  <div className="flex flex-row justify-between my-1 gap-6">
                    <div>Gutschein-Code ({controller.couponDiscount} %)</div>
                    <div>- {Helpers.parsePrice(controller.price.coupon)}&nbsp;Euro</div>
                  </div>
                )}
                {!!controller.price?.discount && (
                  <div className="flex flex-row justify-between my-1 gap-6">
                    <div>Kundenrabatt ({controller.advertiser?.discountPercentage} %)</div>
                    <div>- {Helpers.parsePrice(controller.price.discount)}&nbsp;Euro</div>
                  </div>
                )}
                <div className="flex flex-row justify-between my-1 gap-6">
                  <div>{controller.price?.vatRate} % MwSt.</div>
                  <div>{Helpers.parsePrice(controller.price?.vat || 0)}&nbsp;Euro</div>
                </div>
                <div className="flex flex-row justify-between border-t border-t-border pt-1 gap-6">
                  <div>Gesamt</div>
                  <div>{Helpers.parsePrice(controller.price?.gross || 0)}&nbsp;Euro</div>
                </div>
              </InputGroup>
              {controller.price?.gross !== 0 && (
                <>
                  {!controller.advertiser?.invoiceVatId?.length &&
                    Constants.EU_COUNTRY_CODES.includes(
                      controller.advertiser?.invoiceCountry || controller.advertiser?.baseCountry || ''
                    ) &&
                    controller.advertiser?.type === AdvertiserType.COMPANY && (
                      <InputGroup horizontal label="USt-ID*" className="mt-16">
                        <Input
                          type="text"
                          value={controller.vatId}
                          onChange={controller.setVatId}
                          className={clsx(controller.errorFields.includes('vatId') && '!border-error')}
                        />
                      </InputGroup>
                    )}
                  {controller.advertiser?.type === AdvertiserType.COMPANY && (
                    <InputGroup horizontal label="Rechnungsvermerk" className="mt-16">
                      <Input
                        type="text"
                        value={controller.invoiceNote}
                        onChange={controller.setInvoiceNote}
                        maxLength={60}
                      />
                      <div className="text-sm font-light opacity-80 sm:opacity-60 !text-dark mt-2">
                        Anzeigentitel und Schaltungsdatum stehen automatisch auf jeder Rechnung. Hier können Sie
                        optional eine Jobnummer, Kostenstelle o.ä. angeben, die zusätzlich aufgeführt wird.
                      </div>
                    </InputGroup>
                  )}
                  <InputGroup horizontal label="Zahlungsweise*" className="mt-16">
                    <RadioButtons
                      buttons={Object.keys(Constants.PAYMENT_METHOD_OPTIONS)
                        .filter((method) =>
                          controller.advertiser?.allowedPaymentMethods.includes(method as PaymentMethod)
                        )
                        .map((method) => ({
                          label: Constants.PAYMENT_METHOD_OPTIONS[method as PaymentMethod],
                          value: method,
                          checked: controller.paymentMethod === method,
                        }))}
                      onChange={(v) => controller.setPaymentMethod(v as PaymentMethod)}
                      name="paymentMethod"
                    />
                  </InputGroup>
                </>
              )}
              <InputGroup horizontal label=" " className="mt-16">
                <Checkbox
                  label={
                    <>
                      Hiermit beauftrage ich die Veröffentlichung der verfassten Anzeige zum angegebenen Preis auf
                      Grundlage der{' '}
                      <Link to={`${controller.service.url}/rechtliches#agb`} target="_blank" className="underline">
                        AGB
                      </Link>{' '}
                      von {controller.service.name}.
                    </>
                  }
                  checked={controller.terms}
                  onChange={controller.setTerms}
                  className={clsx(controller.errorFields.includes('terms') && '!border-error')}
                />
              </InputGroup>
            </div>
          </>
        ) : (
          <>
            <div>
              <p>
                <strong>Wählen Sie die gewünschte Laufzeit und verfassen Sie Ihr Stellenangebot.</strong>
                <br />
                {controller.service.showNetPrices
                  ? 'Die Preisangaben sind netto.'
                  : `Die Preisangaben sind brutto inkl. ${controller.vatRate} % MwSt.`}
              </p>

              {controller.advertiser &&
                controller.advertiser.discountPercentage > 0 &&
                controller.advertiser.baseServiceId === controller.service.id && (
                  <p>
                    Ihr Kundenrabatt ({controller.advertiser.discountPercentage} %) wird am Ende der Schaltung
                    verrechnet.
                  </p>
                )}
              {controller.jobOption !== 'FREE' && (
                <p className="opacity-50">
                  {!controller.couponDiscount ? (
                    <>
                      Falls Sie einen Gutschein-Code haben, können Sie diesen{' '}
                      <span className="underline cursor-pointer" onClick={controller.openCouponModal}>
                        hier eingeben
                      </span>
                      .
                    </>
                  ) : (
                    <>
                      Ihr Gutschein-Code über {controller.couponDiscount} % ({controller.couponCode}) wurde angewendet (
                      <span className="underline cursor-pointer" onClick={controller.handleRemoveCoupon}>
                        Gutschein entfernen
                      </span>
                      ).
                    </>
                  )}
                </p>
              )}
            </div>
            {controller.error.length > 0 && (
              <Note
                type="error"
                message={controller.error.map((e) => (
                  <p>{e}</p>
                ))}
                className="mt-8"
              />
            )}
            <div
              className={clsx(
                'grid grid-cols-1 gap-4 my-16',
                controller.pricingCardCount >= 2 && 'sm:grid-cols-2',
                controller.pricingCardCount >= 4
                  ? 'md:grid-cols-4'
                  : controller.pricingCardCount >= 3 && 'md:grid-cols-3'
              )}>
              {controller.service.freeOptionDuration !== null &&
                (controller.advertiser?.freeOptionJobsPerMonth ?? 0) > 0 && (
                  <JobOption
                    title="Kostenlose Stellenanzeige"
                    selected={controller.jobOption === 'FREE'}
                    hideButton={controller.service.priceFourWeeks === null && controller.service.priceTwoWeeks == null}
                    onChange={() => controller.setJobOption('FREE')}
                    features={controller.service.freeJobOptionFeatures}
                    blockerText={
                      !controller.canUseFreeOption
                        ? `Auf ${controller.advertiser?.freeOptionJobsPerMonth ?? 0} Anzeige${
                            (controller.advertiser?.freeOptionJobsPerMonth ?? 0) !== 1 ? 'n' : ''
                          } pro Monat limitiert. Bitte wählen Sie bei zusätzlichem Bedarf das kostenpflichtige Format.`
                        : undefined
                    }
                  />
                )}
              {controller.service.priceTwoWeeks !== null && (
                <JobOption
                  title={
                    controller.service.priceFourWeeks === null
                      ? controller.service.defaultJobOptionTitle ?? 'Stellenanzeige'
                      : '2 Wochen'
                  }
                  price={
                    controller.service.priceTwoWeeks *
                    ((100 - (controller.couponDiscount ?? 0)) / 100) *
                    (controller.service.showNetPrices ? 1 : 1 + controller.vatRate / 100)
                  }
                  selected={controller.jobOption === 2}
                  hideButton={
                    controller.service.priceFourWeeks === null && controller.service.freeOptionDuration == null
                  }
                  onChange={() => controller.setJobOption(2)}
                  features={controller.service.twoWeeksJobOptionFeatures}
                />
              )}
              {controller.service.priceFourWeeks !== null && (
                <JobOption
                  title={
                    controller.service.priceTwoWeeks === null
                      ? controller.service.defaultJobOptionTitle ?? 'Stellenanzeige'
                      : '4 Wochen'
                  }
                  price={
                    controller.service.priceFourWeeks *
                    ((100 - (controller.couponDiscount ?? 0)) / 100) *
                    (controller.service.showNetPrices ? 1 : 1 + controller.vatRate / 100)
                  }
                  selected={controller.jobOption === 4}
                  hideButton={
                    controller.service.priceFourWeeks === null && controller.service.freeOptionDuration == null
                  }
                  onChange={() => controller.setJobOption(4)}
                  features={controller.service.fourWeeksJobOptionFeatures}
                />
              )}
              {controller.service.priceHotJob !== null && (
                <JobOption
                  title={controller.service.hotJobName}
                  marketingLabelUrl={controller.service.hotJobBadgeUrl}
                  hotJobName={controller.service.hotJobName}
                  className={clsx(
                    'col-span-1 mt-6 md:mt-0 md:col-span-1',
                    controller.pricingCardCount === 3 ? 'sm:col-span-2' : 'sm:mt-0'
                  )}
                  pricePrefix="zzgl."
                  price={
                    controller.service.priceHotJob *
                    ((100 - (controller.couponDiscount ?? 0)) / 100) *
                    (controller.service.showNetPrices ? 1 : 1 + controller.vatRate / 100)
                  }
                  selected={controller.hotJob}
                  onChange={() => controller.setHotJob(!controller.hotJob)}
                  isCheckbox
                  checkboxDisabled={controller.jobOption === 'FREE'}
                  features={controller.service.hotJobJobOptionFeatures.filter(
                    (feature) =>
                      !feature.includes('Firmenlogo') || controller.advertiser?.type === AdvertiserType.COMPANY
                  )}
                />
              )}
            </div>
            <div className="mt-32">
              {controller.advertiser && (
                <JobEditor
                  onBreakEditorCharacterLimit={controller.handleBreakDescriptionCharacterLimit}
                  errorFields={controller.errorFields as (keyof JobEditorFields)[]}
                  state={controller.jobEditorState}
                  onChange={controller.handleJobEditorStateChange}
                  editorRef={controller.editorRef}
                  advertiser={controller.advertiser}
                  logo={controller.logo ?? undefined}
                  hotJob={controller.hotJob}
                  hideCountry={controller.service.forcedCountry !== null}
                  hideRemote={controller.service.forcedRemote !== null}
                />
              )}
            </div>
          </>
        )}
        <div
          className="flex flex-row justify-between mt-12 w-full"
          style={controller.paymentMethod === PaymentMethod.PAYPAL ? { marginBottom: -6 } : undefined}>
          <Button
            title={controller.showPreview || controller.showSummary ? 'zurück' : 'abbrechen'}
            onClick={controller.handleCancel}
            disabled={controller.isCreatingJob || controller.isCapturingPaypalPayment}
            kind="solid-secondary"
            style={controller.paymentMethod === PaymentMethod.PAYPAL ? { marginBottom: 6 } : undefined}
          />
          {controller.showSummary &&
          controller.paymentMethod === PaymentMethod.PAYPAL &&
          !controller.isCapturingPaypalPayment ? (
            controller.paypalClientId ? (
              <PayPalScriptProvider options={{ 'client-id': controller.paypalClientId, 'currency': 'EUR' }}>
                <PayPalButtons
                  onClick={(_data, actions) => controller.handlePayPalClick(actions)}
                  createOrder={(data, actions) => controller.createPayPalOrder(data, actions)}
                  onApprove={(data, _actions) => controller.approvePayPalOrder(data)}
                  onError={(err) => controller.handlePayPalError(err)}
                  style={{ layout: 'horizontal', height: 42 }}
                  disabled={
                    !controller.terms ||
                    !controller.vatIdIsValid ||
                    controller.isCreatingJob ||
                    controller.isCapturingPaypalPayment
                  }
                />
              </PayPalScriptProvider>
            ) : null
          ) : (
            <Button
              title={controller.showPreview ? 'weiter' : controller.showSummary ? 'Anzeige buchen' : 'Vorschau'}
              onClick={controller.handleSubmit}
              disabled={
                (controller.showSummary && (!controller.terms || !controller.vatIdIsValid)) ||
                controller.isCreatingJob ||
                controller.isCapturingPaypalPayment
              }
              className={clsx((controller.isCreatingJob || controller.isCapturingPaypalPayment) && '!cursor-wait')}
            />
          )}
        </div>
      </div>
      <Modal
        title="Systemwartung"
        open={!!controller.maintenanceMode}
        buttons={[
          {
            title: `OK`,
            onClick: () => navigate('/dashboard'),
          },
        ]}>
        {controller.maintenanceMode}
      </Modal>
      <Modal
        title="Bitte Daten prüfen!"
        open={(controller.requiresBaseCheck || controller.requiresInvoiceCheck) && !controller.maintenanceMode}
        buttons={[
          {
            title: `OK`,
            onClick: () => navigate('/dashboard'),
          },
        ]}>
        Bevor Sie eine Anzeige schalten, bestätigen Sie bitte die Aktualität Ihrer{' '}
        {controller.advertiser?.type === AdvertiserType.COMPANY ? 'Stamm- und/oder Rechnungsdaten' : 'Stammdaten'}.
      </Modal>
      <Modal
        open={controller.showCouponModal}
        setOpen={controller.isVerifyingCoupon ? undefined : controller.setShowCouponModal}
        buttons={[
          {
            title: 'abbrechen',
            onClick: controller.handleCancelCoupon,
            kind: 'solid-secondary',
            disabled: controller.isVerifyingCoupon,
          },
          {
            title: 'Ok',
            onClick: controller.handleSubmitCoupon,
            kind: 'solid',
            disabled: controller.couponCode.trim().length === 0 || controller.isVerifyingCoupon,
          },
        ]}>
        Bitte geben Sie Ihren Gutschein-Code ein.
        <Input value={controller.couponCode} onChange={controller.setCouponCode} className="mt-4" />
        {controller.couponError && <p className="text-error mt-4">{controller.couponError}</p>}
      </Modal>
      <Modal
        open={controller.showFreeOptionModal}
        setOpen={controller.setShowFreeOptionModal}
        title="Hinweis"
        buttons={[
          {
            title: 'Alles klar!',
            onClick: () => controller.setShowFreeOptionModal(false),
            kind: 'solid',
          },
        ]}>
        <p>Wir können das kostenlose Inserieren nur mit diesen „Spielregeln“ ermöglichen:</p>
        <ul className="list-disc ml-4 [&>li]:mt-2">
          <li className="!mt-0">
            Bitte schreiben Sie eine Stelle konkret und standortbezogen aus – keinen allgemeinen Aufruf.
          </li>
          <li>
            Natürlich geben Sie wie gewohnt/gewünscht für die Kontaktaufnahme Ihre Anschrift, Rufnummer, E-Mail-Adresse
            etc. im Anzeigentext an.
            <br />
            <div className="mt-2">
              Möchten Sie darüber hinaus einen Link kommunizieren bzw. auf Ihren Bewerberbereich verweisen, nutzen Sie
              bitte die kostenpflichtige Anzeige.
            </div>
          </li>
        </ul>
        <p>Inserate, bei denen diese Punkte nicht berücksichtigt werden, löschen wir kommentarlos.</p>
        <p>Vielen Dank für Ihr Verständnis.</p>
      </Modal>
    </>
  );
};

export default DashboardCreateJobListingView;
