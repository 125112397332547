import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button, { ButtonProps } from './Button';

interface ModalProps {
  open: boolean;
  setOpen?: (open: boolean) => void;
  title?: string;
  buttons?: ButtonProps[];
  children: React.ReactNode;
}
const Modal: React.FC<ModalProps> = ({ open, setOpen, title, children, buttons }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={
          setOpen ??
          (() => {
            /* do nothing / keep open */
          })
        }>
        <Transition.Child
          as={Fragment}
          enter="ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-[rgba(0,0,0,0.34)] transition" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-white p-8 text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div>
                  <div className="text-left">
                    {!!title?.length && (
                      <Dialog.Title as="h3" className="text-base font-bold">
                        {title}
                      </Dialog.Title>
                    )}
                    <div className="mt-2">{children}</div>
                  </div>
                </div>
                {!!buttons?.length && (
                  <div className="mt-8 flex flex-row justify-between">
                    {buttons.length === 1 && <div />}
                    {buttons.map((button) => (
                      <Button key={button.title} {...button} />
                    ))}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
