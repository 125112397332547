import { z } from 'zod';
import { AdvertiserType, Salutation } from '@prisma/client';
import { COUNTRY_LIST } from './constants';

// min length 8, must contain at least one letter, one number, one special character and can only contain ASCII characters (excluding space)
export const PasswordSchema = z
  .string()
  .min(8)
  .regex(/[a-zA-Z]/)
  .regex(/[0-9]/)
  .regex(/[^a-zA-Z0-9]/)
  .regex(/^[!-~]*$/);

// BASE SCHEMAS
export const EmailSchema = z.string().email();

export const SalutationSchema = z.enum(Object.values(Salutation) as [string, ...string[]]);

export const CountryCodeSchema = z.enum(Object.keys(COUNTRY_LIST) as [string, ...string[]]);

export const PhoneNumberSchema = z.string().regex(/^\+[0-9]{2,14}$/);

export const AdvertiserTypeSchema = z.enum(Object.values(AdvertiserType) as [string, ...string[]]);
