import { useMemo } from 'react';
import Button from '../components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';

const JobPublishedView: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const autoOn = useMemo(() => searchParams.get('on') === '1', [searchParams]);

  return (
    <div className="w-full lg:w-3/4">
      <div className="[&>p:last-of-type]:!mb-0">
        <p className="font-bold">
          {autoOn
            ? 'Ihre Anzeige ist online – vielen Dank für Ihre Buchung!'
            : 'Wir haben Ihre Buchung erhalten – vielen Dank!'}
        </p>
        {!autoOn && (
          <p>
            Nach erfolgreicher Prüfung wird Ihre Anzeige freigeschaltet. Parallel erhalten Sie eine Bestätigung per
            E-Mail.
          </p>
        )}
        <p>
          Zur Info: Änderungen/Korrekturen (z.B. bei Schreibfehlern) können Sie eigenständig innerhalb der nächsten
          24&nbsp;Stunden vornehmen.
        </p>
      </div>
      <Button onClick={() => navigate('/dashboard')} title="Mein Account" className="mt-12" />
    </div>
  );
};

export default JobPublishedView;
