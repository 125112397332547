import { Constants } from '@jobmatic/shared/utils';
import Checkbox from './Checkbox';
import Input from './Input';
import InputGroup from './InputGroup';
import { Advertiser, AdvertiserType, OccupationType, RemoteOption } from '@prisma/client';
import clsx from 'clsx';
import { WYSIWYGEditor, WYSIWYGEditorRef } from '@jobmatic/web/components';
import SelectBox from './SelectBox';
import { TagContainer } from '@jobmatic/web/components';
import { useOutletContext } from 'react-router-dom';
import { MainOutletContext } from '../MainOutlet';
import { Fragment, useMemo } from 'react';

interface JobEditorLocation {
  id: string;
  city: string;
  region: string | null;
  coordinates: { lat: number; lng: number };
  bbox: { ne: { lat: number; lng: number }; sw: { lat: number; lng: number } };
}
export interface JobEditorFields {
  title: string;
  description: string;
  descriptionPlain: string;
  descriptionLength: number;
  locations: (JobEditorLocation & { selected?: boolean | undefined; order: number })[];
  locationSearch: string;
  searchResults: JobEditorLocation[];
  hoveredSearchLocation: string | null;
  country: keyof typeof Constants.COUNTRY_LIST;
  remote: RemoteOption;
  minAge: number | null;
  occupationType: OccupationType[];
  showEqualityNote: boolean;
  showAddress: boolean;
}
interface JobEditorProps {
  state: JobEditorFields;
  onChange: (state: Partial<JobEditorFields>) => void;
  onBreakEditorCharacterLimit: (e: 'input' | 'paste') => void;
  errorFields: (keyof JobEditorFields)[];
  editorRef?: React.RefObject<WYSIWYGEditorRef>;
  advertiser: Advertiser;
  overrideDisplayedAddress?: { name: string; address: string };
  logo?: { base64: string; mimeType: string };
  hotJob: boolean;
  hideHotJobNoteOnLogo?: boolean;
  hideCountry?: boolean;
  hideRemote?: boolean;
}
const JobEditor: React.FC<JobEditorProps> = ({
  state,
  onChange,
  onBreakEditorCharacterLimit,
  errorFields,
  editorRef,
  advertiser,
  logo,
  hotJob,
  hideHotJobNoteOnLogo,
  overrideDisplayedAddress,
  hideCountry,
  hideRemote,
}) => {
  const { service } = useOutletContext<MainOutletContext>();

  /**
   * turn Constants.REMOTE_OPTIONS from
   * Record<RemoteOption, { creation: string; joblisting: string | null; jobdetail: string | null }>
   * into
   * Record<RemoteOption, string> (where string is the creation value)
   */
  const remoteOptions = useMemo(() => {
    return Object.entries(Constants.REMOTE_OPTIONS(Constants.COUNTRY_LIST[state.country], state.country)).reduce(
      (acc, [key, value]) => {
        acc[key as RemoteOption] = value.creation;
        return acc;
      },
      {} as Record<RemoteOption, string>
    );
  }, [state.country]);
  const cityLimit = useMemo(() => (service.allowMultipleCities ? 50 : 1), [service.allowMultipleCities]);

  const handleCityInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 40) {
      e.preventDefault();
      const currentIdx = state.searchResults.findIndex((v) => v.id === state.hoveredSearchLocation);
      if (currentIdx < state.searchResults.length - 1) {
        onChange({ hoveredSearchLocation: state.searchResults[currentIdx + 1].id });
      } else {
        onChange({ hoveredSearchLocation: state.searchResults[0].id });
      }
    } else if (e.keyCode === 38) {
      e.preventDefault();
      const currentIdx = state.searchResults.findIndex((v) => v.id === state.hoveredSearchLocation);
      if (currentIdx > 0) {
        onChange({ hoveredSearchLocation: state.searchResults[currentIdx - 1].id });
      } else {
        onChange({ hoveredSearchLocation: state.searchResults[state.searchResults.length - 1].id });
      }
    } else if (e.keyCode === 13) {
      e.preventDefault();
      if (state.hoveredSearchLocation !== null) {
        onChange({
          locationSearch: '',
          hoveredSearchLocation: null,
          locations: [
            ...state.locations,
            {
              ...state.searchResults.find((v) => v.id === state.hoveredSearchLocation)!,
              order: state.locations.length + 1,
            },
          ],
        });
      }
    } else if (e.keyCode === 8) {
      if (state.locationSearch.length === 0 && state.locations.length > 0) {
        onChange({
          locations: state.locations.slice(0, state.locations.length - 1),
        });
      }
    } else if (e.keyCode === 27) {
      e.preventDefault();
      onChange({ locationSearch: '' });
    }
  };

  return (
    <>
      <InputGroup
        horizontal
        label={
          <span>
            Anzeigentitel*
            <br />
            <span className="!font-normal">{`max. ${(
              Constants.MAX_JOB_TITLE_LENGTH - state.title.length
            ).toString()} Zeichen`}</span>
          </span>
        }>
        <Input
          value={state.title}
          onChange={(v) => onChange({ title: v })}
          className={clsx(errorFields.includes('title') && '!border-error')}
        />
      </InputGroup>
      <InputGroup
        horizontal
        className="mt-16"
        label={
          <>
            <p>
              Jobbeschreibung*
              <br />
              <span className="!font-normal">{`max. ${(Constants.MAX_JOB_DESCRIPTION_LENGTH - state.descriptionLength)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} Zeichen`}</span>
            </p>
            <span className="opacity-80 sm:opacity-60 !text-dark text-sm !font-normal">
              <p>
                Folgende Angaben sind für Bewerber/innen <strong>wichtig</strong> und daher{' '}
                <strong>erforderlich</strong>:
              </p>
              <ul className="list-disc list-inside">
                <li>Tätigkeitsbeschreibung</li>
                <li>Arbeitszeiten</li>
                <li>Anforderungen</li>
                <li>Verdienst</li>
                <li>Kontaktmöglichkeiten</li>
              </ul>
            </span>
          </>
        }>
        <WYSIWYGEditor
          ref={editorRef}
          value={state.description}
          onChange={(text, { characters, plainText }) => {
            onChange({ description: text });
            onChange({ descriptionPlain: plainText });
            onChange({ descriptionLength: characters });
          }}
          maxLength={Constants.MAX_JOB_DESCRIPTION_LENGTH}
          onBreakCharacterLimit={onBreakEditorCharacterLimit}
          className={clsx(errorFields.includes('description') && '!border-error')}
        />
        <p className="text-sm opacity-80 sm:opacity-60 mt-2">
          Bitte beachten Sie, dass die Bewerbungen <strong>nicht</strong> über unsere Plattform stattfinden. Stellen Sie
          daher sicher, dass die gewünschte/n <strong>Kontaktmöglichkeit/en</strong> (z.B. E-Mail-Adresse){' '}
          <strong>im Anzeigentext</strong> stehen.
        </p>
      </InputGroup>
      <InputGroup
        horizontal
        className="mt-16"
        label={cityLimit > 1 ? 'Arbeitsort/e*' : 'Arbeitsort / Stadt*'}
        labelClassName="__db-job-editor-location-label">
        {!hideCountry && (
          <SelectBox
            value={Constants.COUNTRY_LIST[state.country]}
            onChange={(v) =>
              onChange({
                country:
                  (Object.keys(Constants.COUNTRY_LIST).find(
                    (k) => Constants.COUNTRY_LIST[k as keyof typeof Constants.COUNTRY_LIST] === v
                  ) as keyof typeof Constants.COUNTRY_LIST | undefined) ?? 'DE',
              })
            }
            options={[
              Constants.COUNTRY_LIST.DE,
              Constants.COUNTRY_LIST.AT,
              Constants.COUNTRY_LIST.CH,
              ...Object.values(Constants.COUNTRY_LIST).filter(
                (v) => ![Constants.COUNTRY_LIST.DE, Constants.COUNTRY_LIST.AT, Constants.COUNTRY_LIST.CH].includes(v)
              ),
            ]}
            className="w-full"
          />
        )}
        {!hideRemote && (
          <SelectBox
            value={remoteOptions[state.remote]}
            onChange={(v) =>
              onChange({
                remote: (Object.keys(remoteOptions).find((k) => remoteOptions[k as keyof typeof remoteOptions] === v) ??
                  RemoteOption.NOT_POSSIBLE) as RemoteOption,
              })
            }
            options={Object.values(remoteOptions)}
            className={clsx('w-full', !hideCountry && 'mt-4')}
          />
        )}
        {state.remote !== RemoteOption.ONLY && (
          <>
            {service.allowMultipleCities && (
              <div className={clsx('opacity-50', (!hideCountry || !hideRemote) && 'mt-4')}>
                Stadt / Städte* (max. {cityLimit})
              </div>
            )}
            <TagContainer
              className={clsx(errorFields.includes('locations') && '!border-error')}
              tags={state.locations
                .map((location) => ({ ...location, label: location.city }))
                .sort((a, b) => a.order - b.order)}
              onToggle={(id) => {
                const location = state.locations.find((v) => v.id === id);
                if (location) {
                  onChange({
                    locations: [...state.locations.filter((v) => v.order !== location.order)]
                      .sort((a, b) => a.order - b.order)
                      .map((v, idx) => ({ ...v, order: idx + 1 })),
                  });
                }
              }}
              onChangeSearch={(v) => {
                // if user pastes a long string, ignore
                const characterDiff = v.length - state.locationSearch.length;
                console.log({ characterDiff });
                if (state.locations.length < cityLimit && characterDiff <= 30) {
                  onChange({ locationSearch: v });
                }
              }}
              searchValue={state.locations.length < cityLimit ? state.locationSearch : undefined}
              additionalSearchProps={{
                onKeyDown: handleCityInputKeyDown,
              }}
              append={
                state.searchResults.length > 0 ? (
                  <div className="absolute z-10 bg-white left-0 right-0 shadow-md rounded-md overflow-hidden">
                    {state.searchResults.map((v) => (
                      <div
                        key={v.id}
                        className={clsx('px-4 py-2 cursor-pointer', state.hoveredSearchLocation === v.id && 'bg-light')}
                        onMouseEnter={() => onChange({ hoveredSearchLocation: v.id })}
                        onMouseLeave={() => onChange({ hoveredSearchLocation: null })}
                        onClick={() => handleCityInputKeyDown({ keyCode: 13, preventDefault: () => undefined } as any)}>
                        {v.city}
                        {v.region?.length && <span className="opacity-30"> ({v.region})</span>}
                      </div>
                    ))}
                  </div>
                ) : undefined
              }
            />
          </>
        )}
      </InputGroup>
      {service.minAge !== null && (
        <InputGroup horizontal className="mt-16" label="Mindestalter*">
          <SelectBox
            value={`${state.minAge} Jahre`}
            onChange={(v) => onChange({ minAge: parseInt(v.replace(' Jahre', '')) })}
            /* build an array from service.minAge to 18 */
            options={Array.from({ length: 18 - service.minAge + 1 }, (_, i) => i + service.minAge!).map(
              (v) => `${v} Jahre`
            )}
            className="w-full"
          />
        </InputGroup>
      )}
      <InputGroup
        horizontal
        className="mt-16"
        label={
          <>
            <p>Beschäftigungsverhältnis*</p>
            <p className="opacity-80 sm:opacity-60 !text-dark text-sm !font-normal">
              Bitte wählen Sie <strong>alle zutreffenden</strong> Optionen.
            </p>
          </>
        }>
        <TagContainer
          className={clsx(errorFields.includes('occupationType') && '!border-error')}
          tags={Object.entries(Constants.OCCUPATION_TYPE_OPTIONS)
            .filter(([key]) => !service.filteredOccupationTypes.includes(key as OccupationType))
            .map(([key, value]) => ({
              id: key,
              label: value,
              selected: state.occupationType.includes(key as OccupationType),
            }))}
          onToggle={(id) =>
            onChange({
              occupationType: state.occupationType.includes(id as OccupationType)
                ? state.occupationType.filter((v) => v !== id)
                : [...state.occupationType, id as OccupationType],
            })
          }
        />
      </InputGroup>
      <InputGroup horizontal className="mt-16" label="Gleichstellungszusatz">
        <div className="bg-[#f1f1f1] rounded-md p-4">
          Die Anzeige ist aus Gründen der leichteren Lesbarkeit ggf. nicht überall geschlechts­neutral formuliert. Sie
          richtet sich aber grundsätzlich an alle Menschen.
        </div>
        <Checkbox
          label="diesen Zusatz unter meinem Inserat anzeigen"
          containerClassName="mt-2"
          checked={state.showEqualityNote}
          onChange={(v) => onChange({ showEqualityNote: v })}
        />
      </InputGroup>
      <InputGroup horizontal className="mt-16" label="Inserent / Arbeitgeber">
        <div className="flex flex-col sm:flex-row items-start gap-4">
          {logo && (
            <div className="w-52 h-52 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-52 lg:h-52 object-contain p-4 border border-border relative flex items-center justify-center">
              {!hotJob && !hideHotJobNoteOnLogo && (
                <div className="absolute text-center left-0 right-0">
                  Wählen Sie die {service.hotJobName}-Option, damit Ihr Logo angezeigt wird!
                </div>
              )}
              <img
                src={`data:${logo.mimeType};base64,${logo.base64}`}
                alt="Logo"
                className={clsx('w-full h-full object-contain', hotJob ? 'opacity-100' : 'opacity-5')}
              />
            </div>
          )}
          <div className="flex flex-col gap-4">
            <div>
              {overrideDisplayedAddress?.name ? (
                overrideDisplayedAddress?.name.split('\n').map((v, i) => (
                  <Fragment key={i}>
                    {v}
                    <br />
                  </Fragment>
                ))
              ) : advertiser.type === AdvertiserType.COMPANY ? (
                <>
                  {advertiser?.baseBusinessName}
                  <br />
                  {advertiser.baseBusinessAppendix !== null && (
                    <>
                      {advertiser?.baseBusinessAppendix}
                      <br />
                    </>
                  )}
                </>
              ) : (
                <>
                  {advertiser.baseFirstName} {advertiser.baseLastName}
                  <br />
                </>
              )}
              <span className={clsx('transition', state.showAddress ? 'opacity-100' : 'opacity-30')}>
                {overrideDisplayedAddress?.address ? (
                  overrideDisplayedAddress.address.split('\n').map((v, i) => (
                    <Fragment key={i}>
                      {v}
                      <br />
                    </Fragment>
                  ))
                ) : (
                  <>
                    {advertiser.baseStreet} <br />
                    {advertiser.baseZip} {advertiser.baseCity}
                  </>
                )}
              </span>
            </div>
            <Checkbox
              label="Anschrift nicht anzeigen"
              checked={!state.showAddress}
              onChange={(v) => onChange({ showAddress: !v })}
            />
          </div>
        </div>
      </InputGroup>
    </>
  );
};

export default JobEditor;
