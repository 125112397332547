import { MainOutletContext } from '../MainOutlet';
import Button from '../components/Button';
import { useOutletContext } from 'react-router-dom';

const AccountDeletedView: React.FC = () => {
  const { service } = useOutletContext<MainOutletContext>();

  return (
    <div className="w-full lg:w-3/4">
      <div>
        <p>Ihr Account wurde erfolgreich gelöscht.</p>
        <p>Wir wünschen Ihnen alles Gute!</p>
      </div>
      <Button onClick={() => (window.location.href = service.url)} title="Home" className="mt-12" />
    </div>
  );
};

export default AccountDeletedView;
