import Button from '../../components/Button';
import useDashboardInvoiceDataController from '../../controllers/Dashboard/InvoiceData';
import Input from '../../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import InputGroup from '../../components/InputGroup';
import SelectBox from '../../components/SelectBox';
import { Constants } from '@jobmatic/shared/utils';
import Note from '../../components/Note';
import clsx from 'clsx';
import { AdvertiserType } from '@prisma/client';
import Modal from '../../components/Modal';

const DashboardInvoiceDataView = () => {
  const controller = useDashboardInvoiceDataController();
  const navigate = useNavigate();

  if (controller.advertiser?.type !== AdvertiserType.COMPANY) return null;
  return (
    <>
      <div className="w-full lg:w-3/4">
        <div>
          <p>Diese Daten werden für die Rechnungsstellung verwendet.</p>
          <p>
            Rechnungen werden automatisch generiert und als PDF-Dateien zur Verfügung gestellt. Die Rechnungen werden
            Ihnen per E-Mail zugesandt und liegen zusätzlich im Account zum{' '}
            <Link to="/dashboard/rechnungen" className="underline">
              Download
            </Link>{' '}
            bereit.
          </p>

          <p>
            <strong>Bitte beachten Sie:</strong>
          </p>
          <ul className="list-disc list-inside ml-8">
            <li>Änderungen sind nicht rückwirkend.</li>
            <li>Fehlerhafte Angaben können nicht nachträglich korrigiert werden.</li>
            <li>Bitte prüfen Sie daher alle Angaben genau!</li>
          </ul>
          <p>
            Für Fragen stehen wir natürlich{' '}
            <Link to={`${controller.service.url}/kontakt`} target="_blank" className="underline">
              gerne zur Verfügung
            </Link>
            .
          </p>
        </div>
        {controller.success && <Note type="success" message="Ihre Rechnungsdaten wurden erfolgreich gespeichert." />}
        {controller.requiresUserCheck && (
          <Note
            type="info"
            message={
              <>
                <strong>Bitte prüfen Sie Ihre Rechnungsdaten</strong> und bestätigen Sie durch{' '}
                <strong>‹ speichern ›</strong>, dass diese aktuell sind.
              </>
            }
          />
        )}
        {controller.error && (
          <Note
            type="error"
            message={controller.error.map((e) => (
              <p>{e}</p>
            ))}
          />
        )}
        {controller.advertiser && (
          <form className="flex flex-col gap-6 mt-16" onSubmit={controller.handleSubmit}>
            <InputGroup label="Unternehmen*" horizontal>
              <Input
                value={controller.businessName}
                onChange={controller.setBusinessName}
                maxLength={60}
                autoComplete="organization"
                className={controller.errorFields.includes('businessName') ? 'border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Zusatz (optional)" horizontal>
              <Input
                value={controller.businessNameAppendix}
                onChange={controller.setBusinessNameAppendix}
                maxLength={60}
                placeholder="Kostenstelle o.ä."
                className={controller.errorFields.includes('businessAppendix') ? 'border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="Straße / Hausnummer*" horizontal className="mt-8">
              <Input
                value={controller.street}
                onChange={controller.setStreet}
                maxLength={60}
                autoComplete="street-address"
                className={controller.errorFields.includes('street') ? 'border-error' : undefined}
              />
            </InputGroup>
            <InputGroup label="PLZ* / Ort*" horizontal>
              <div className="flex flex-row gap-4">
                <Input
                  value={controller.zip}
                  onChange={controller.setZip}
                  containerClassName="w-1/3 lg:w-1/4"
                  className={controller.errorFields.includes('zip') ? '!border-error' : undefined}
                  maxLength={8}
                  autoComplete="postal-code"
                />
                <Input
                  value={controller.city}
                  onChange={controller.setCity}
                  containerClassName="w-2/3 lg:w-3/4"
                  className={controller.errorFields.includes('city') ? '!border-error' : undefined}
                  maxLength={60}
                  autoComplete="address-level2"
                />
              </div>
            </InputGroup>
            <InputGroup label="Land*" horizontal>
              <SelectBox
                value={Constants.COUNTRY_LIST[controller.country]}
                onChange={(v) =>
                  controller.setCountry(
                    (Object.keys(Constants.COUNTRY_LIST).find(
                      (k) => Constants.COUNTRY_LIST[k as keyof typeof Constants.COUNTRY_LIST] === v
                    ) as keyof typeof Constants.COUNTRY_LIST | undefined) ?? 'DE'
                  )
                }
                options={[
                  Constants.COUNTRY_LIST.DE,
                  Constants.COUNTRY_LIST.AT,
                  Constants.COUNTRY_LIST.CH,
                  ...Object.values(Constants.COUNTRY_LIST).filter(
                    (v) =>
                      ![Constants.COUNTRY_LIST.DE, Constants.COUNTRY_LIST.AT, Constants.COUNTRY_LIST.CH].includes(v)
                  ),
                ]}
                className={clsx('w-full', controller.errorFields.includes('country') ? 'border-error' : undefined)}
                autoComplete="country-name"
                disabled
              />
            </InputGroup>
            {Constants.EU_COUNTRY_CODES.includes(controller.country) && (
              <InputGroup label="USt-ID*" horizontal className="mt-8">
                <Input
                  value={controller.vatId}
                  onChange={controller.setVatId}
                  maxLength={30}
                  className={controller.errorFields.includes('vatId') ? 'border-error' : undefined}
                  disabled={!!controller.advertiser.invoiceVatId && controller.advertiser.invoiceVatId.length > 0}
                />
              </InputGroup>
            )}
            <InputGroup
              label="E-Mail-Adresse*"
              horizontal
              helper="Für Rechnungen können Sie hier bei Bedarf eine separate E-Mail-Adresse angeben."
              className="mt-8">
              <Input
                value={controller.email}
                onChange={controller.setEmail}
                maxLength={30}
                className={controller.errorFields.includes('email') ? 'border-error' : undefined}
              />
            </InputGroup>
          </form>
        )}
        <div className="flex flex-row justify-between mt-12 w-full">
          <Button
            title="zurück"
            onClick={controller.handleBack}
            kind="solid-secondary"
            disabled={controller.isSaving}
          />
          <Button
            title="speichern"
            onClick={() => controller.handleSubmit()}
            disabled={controller.isSaving}
            className={clsx(controller.isSaving && '!cursor-wait')}
          />
        </div>
      </div>
      <Modal
        title="Systemwartung"
        open={!!controller.maintenanceMode}
        buttons={[
          {
            title: `OK`,
            onClick: () => navigate('/dashboard'),
          },
        ]}>
        {controller.maintenanceMode}
      </Modal>
    </>
  );
};

export default DashboardInvoiceDataView;
