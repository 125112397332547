import Button from '../components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';

const PasswordChangedView: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <div className="w-full lg:w-3/4">
      <div>
        <p>Ihr neues Passwort wurde gespeichert und ist ab sofort zum Einloggen erforderlich.</p>
        {searchParams.get('logout') && searchParams.get('logout') === '1' && (
          <p className="font-bold">Sie sind jetzt ausgeloggt.</p>
        )}
      </div>
      <Button onClick={() => navigate('/')} title="Login" className="mt-12" />
    </div>
  );
};

export default PasswordChangedView;
