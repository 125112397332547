import { Link, useOutletContext } from 'react-router-dom';
import useDashboardMainController from '../../controllers/Dashboard/Main';
import { AdvertiserType, InvoiceState, JobState } from '@prisma/client';
import { MainOutletContext } from '../../MainOutlet';

const DashboardMainView: React.FC = () => {
  const controller = useDashboardMainController();
  const { navigation } = useOutletContext<MainOutletContext>();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {navigation
        .filter((item) => item.navigation === 'main')
        .filter(
          (item) =>
            !(
              // hide invoice data and logo upload for non-company advertisers
              (
                (item.path === '/dashboard/rechnungsdaten' || item.path === '/dashboard/logo') &&
                (!controller.advertiser || controller.advertiser.type !== AdvertiserType.COMPANY)
              )
            )
        )
        .map((item) => {
          let badgeText: string | null = null;
          let title: string | React.ReactNode = item.title;
          if (item.path === '/dashboard/anzeigen') {
            const onlineJobs = controller.jobs.filter((j) =>
              [JobState.ACTIVE, JobState.ACTIVE_UNCHECKED].includes(j.state as any)
            ).length;
            badgeText = onlineJobs > 0 ? `${onlineJobs} online` : null;
            title = <>Anzeigen&shy;übersicht</>;
          }
          if (item.path === '/dashboard/rechnungen') {
            const openInvoices = controller.invoices.filter((i) => i.state === InvoiceState.OPEN).length;
            badgeText = openInvoices > 0 ? `${openInvoices} offen` : null;
          }
          if (item.path === '/dashboard/stammdaten' && controller.requiresBaseDataCheck) badgeText = 'prüfen';
          if (item.path === '/dashboard/rechnungsdaten' && controller.requiresInvoiceDataCheck) badgeText = 'prüfen';
          const badge = badgeText ? (
            <div className="bg-[rgba(0,0,0,0.07)] rounded-[3px] px-2 py-1 text-[0.8rem] ml-2 whitespace-nowrap">
              {badgeText}
            </div>
          ) : null;

          return typeof item.path === 'string' ? (
            <Link className="border-border border px-6 h-24 rounded-md hover:bg-light transition" to={item.path}>
              <div className="h-full flex flex-row justify-between items-center">
                <div className="hyphens-auto">{title}</div>
                {badge}
              </div>
            </Link>
          ) : (
            <div
              className="border-border border px-6 h-24 rounded-md hover:bg-light transition cursor-pointer"
              onClick={item.path}>
              <div className="h-full flex flex-row justify-between items-center">
                <div className="hyphens-auto">{item.title}</div>
                {badge}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DashboardMainView;
