import { useMutation, useQuery } from '@tanstack/react-query';
import { UseMutationTypeHelper, UseQueryTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';
import { useGetOwnAdvertiser } from './Advertiser';

export enum InvoiceQueryKeys {
  GET_OWN = 'INVOICE_GET_OWN',
}

export const useGetOwnInvoices = (
  page: number | null,
  options?: UseQueryTypeHelper<typeof trpc.invoice.getByAdvertiserId.query>
) => {
  const { data: advertiser } = useGetOwnAdvertiser();
  return useQuery(
    [InvoiceQueryKeys.GET_OWN, page],
    () => trpc.invoice.getByAdvertiserId.query({ advertiserId: advertiser!.id, page: page ?? undefined }),
    { enabled: !!advertiser, ...options }
  );
};

export const useDownloadInvoice = (
  options?: UseMutationTypeHelper<
    typeof trpc.invoice.downloadPdf.mutate,
    Parameters<typeof trpc.invoice.downloadPdf.mutate>[0]
  >
) => useMutation((data) => trpc.invoice.downloadPdf.mutate(data), options);

export const useCapturePaypalPayment = (
  options?: UseMutationTypeHelper<
    typeof trpc.invoice.capturePaypalPayment.mutate,
    Parameters<typeof trpc.invoice.capturePaypalPayment.mutate>[0]
  >
) => useMutation((data) => trpc.invoice.capturePaypalPayment.mutate(data), options);
