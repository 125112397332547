import { Constants } from '..';
import { AdvertiserType, FeedParser, InvoiceState, JobState } from '@prisma/client';
import { parse as parseHTML, valid as validateHTML } from 'node-html-parser';

/**
 * Parses a given phone number to a valid E.164 format. If no country code is provided, it will default to DE.
 * @param phoneNumber The phone number to parse
 * @returns The parsed phone number
 */
export const parsePhoneNumber = (phoneNumber: string) => {
  return phoneNumber
    .replace(/[^0-9+]/g, '')
    .replace(/^\+0+/, '+')
    .replace(/^00/, '+')
    .replace(/^0/, '+49');
};

/**
 * Parses a given price number to a German format.
 * @param price The price to parse
 * @returns The parsed price
 */
export const parsePrice = (price: number) => (Math.round(price * 100) / 100).toFixed(2).replace('.', ',');

type CalculatedPrice = {
  net: number;
  vat: number;
  vatRate: number;
  discount: number | null;
  coupon: number | null;
  gross: number;
};
export const calculateJobPrice = ({
  jobOption,
  hotJob,
  countryCode,
  customerDiscount,
  couponDiscount,
  service,
  advertiserType,
  isBaseService,
  vatRate,
}: {
  jobOption: 2 | 4 | 'FREE';
  hotJob: boolean;
  countryCode: keyof typeof Constants.COUNTRY_LIST;
  customerDiscount: number;
  couponDiscount: number;
  service: { priceTwoWeeks: number | null; priceFourWeeks: number | null; priceHotJob: number | null };
  advertiserType: AdvertiserType;
  isBaseService: boolean;
  vatRate: number;
}): CalculatedPrice => {
  const actualVatRate =
    Constants.EU_COUNTRY_CODES.includes(countryCode) && advertiserType === AdvertiserType.COMPANY ? 0 : vatRate;

  if (jobOption === 'FREE')
    return {
      net: 0,
      vat: 0,
      vatRate: actualVatRate * 100,
      gross: 0,
      discount: null,
      coupon: null,
    };
  const basePrice = jobOption === 2 ? service.priceTwoWeeks : service.priceFourWeeks;
  if (basePrice === null) throw new Error('Invalid service price');
  const hotJobPrice = hotJob ? service.priceHotJob : 0;
  if (hotJobPrice === null) throw new Error('Hotjob selected but no hotjob price available');

  let net = basePrice + hotJobPrice;
  const coupon = couponDiscount > 0 ? Math.round(net * couponDiscount * 100) / 100 : null;
  if (coupon) net -= coupon;
  const discount = customerDiscount > 0 && isBaseService ? Math.round(net * customerDiscount * 100) / 100 : null;
  if (discount) net -= discount;

  const vat = Math.round(net * actualVatRate * 100) / 100;
  const gross = Math.round((net + vat) * 100) / 100;
  net = Math.round(net * 100) / 100;

  return {
    net,
    vat,
    vatRate: actualVatRate * 100,
    gross,
    discount,
    coupon,
  };
};

export const recoverJobPriceDetails = ({
  couponDiscount,
  finalListingNetPrice,
  finalHotJobNetPrice,
  finalVatRate,
  finalCustomerDiscount,
}: {
  couponDiscount: number;
  finalListingNetPrice: number;
  finalHotJobNetPrice: number | null;
  finalVatRate: number;
  finalCustomerDiscount: number;
}): CalculatedPrice => {
  const net = Math.round((finalListingNetPrice + (finalHotJobNetPrice ?? 0)) * 100) / 100;
  const vat = Math.round(net * finalVatRate * 100) / 100;
  const couponValue = couponDiscount > 0 ? Math.round(net * couponDiscount * 100) / 100 : null;
  const discountValue =
    finalCustomerDiscount > 0 ? Math.round((net - (couponValue ?? 0)) * finalCustomerDiscount * 100) / 100 : null;
  return {
    net,
    vat,
    vatRate: finalVatRate * 100,
    gross: Math.round((net + vat) * 100) / 100,
    discount: discountValue,
    coupon: couponValue,
  };
};

export const parseJobState = (jobState: JobState) => {
  switch (jobState) {
    case JobState.ACTIVE:
    case JobState.ACTIVE_UNCHECKED:
      return 'aktiv';
    case JobState.UNCHECKED:
      return 'in Prüfung';
    case JobState.DELETED_BY_ADMIN:
    case JobState.DELETED_BY_USER:
      return 'gelöscht';
    case JobState.EXPIRED:
      return 'abgelaufen';
    default:
      return 'unbekannt';
  }
};

export const parseInvoiceState = (invoiceState: InvoiceState | 'OVERDUE') => {
  switch (invoiceState) {
    case InvoiceState.OPEN:
      return 'offen';
    case InvoiceState.PAID:
      return 'bezahlt';
    case InvoiceState.CANCELED:
      return 'storniert';
    case 'OVERDUE':
      return 'überfällig';
    case InvoiceState.DRAFT:
      return 'Entwurf';
  }
};

export const parseFeedParserName = (feedParser: FeedParser) => {
  switch (feedParser) {
    case FeedParser.VONQ:
      return 'VONQ';
    case FeedParser.STELLENANZEIGEN:
      return 'stellenanzeigen.de';
  }
};

export const generateSlug = (text: string) =>
  text
    .trim()
    .toLowerCase()
    // Replace known separators with hyphens
    .replace(/[ _/|.:,;–—#&]/g, '-')
    // Replace german umlauts
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    // Replace euro
    .replace(/€/g, '-euro-')
    // Only allow valid url characters
    .replace(/[^a-zA-Z0-9-]/g, '')
    // Remove leading hyphens
    .replace(/^-+/g, '')
    // Remove trailing hyphens
    .replace(/-+$/g, '')
    // Remove duplicate hyphens
    .replace(/-+/g, '-');

export const scrollToTop = (
  { y, jump }: { y?: number; jump?: boolean } = { y: 0, jump: false },
  skipTimeout = false
) => {
  const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  const isMobileSafari = navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
  if (isFirefox && !skipTimeout) {
    // Firefox somehow won't scroll to top sometimes without a timeout if smooth scrolling is enabled in CSS
    setTimeout(() => scrollToTop({ y, jump }, true), 10);
  } else {
    window.scrollTo({
      top: isMobileSafari && !y ? -50 : y ?? 0,
      behavior: (jump ? 'instant' : 'smooth') as ScrollBehavior,
    });

    // if browser is mobile Safari, scroll to top again after 25ms to fix a display problem where the URL bar is overlaying the page
    if (isMobileSafari && !skipTimeout) {
      setTimeout(() => scrollToTop({ y: !y ? -50 : y, jump }, true), 25);
    }
  }
};

/**
 * Converts degrees to radians.
 * @param deg The degrees to convert.
 * @returns The radians.
 * @see {@link https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates}
 */
export const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

/**
 * Calculates the distance between two coordinates using the Haversine formula.
 * @param lat1 Latitude of the first coordinate.
 * @param lon1 Longitude of the first coordinate.
 * @param lat2 Latitude of the second coordinate.
 * @param lon2 Longitude of the second coordinate.
 * @returns The distance between the two coordinates in kilometers.
 * @see {@link https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates}
 */
export const getCoordinatesDistanceUsingHaversine = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

/**
 * Calculates the distance between a point and a bounding box by calculating the distance to every corner of the
 * bounding box using the Haversine formula and returning the shortest distance.
 * @param point The point to calculate the distance to the bounding box to, in the format [lat, lng].
 * @param bbox The bounding box to calculate the distance to the point to, in the format [sw.lng, sw.lat, ne.lng, ne.lat].
 * @returns The shortest distance between the point and the bounding box in kilometers. If the point is inside the
 * bounding box, 0 is returned.
 */
export const getDistanceFromPointToBbox = (point: [number, number], bbox: [number, number, number, number]) => {
  const [minLng, minLat, maxLng, maxLat] = bbox;
  const bboxPoints: [number, number][] = [
    [minLat, minLng],
    [minLat, maxLng],
    [maxLat, minLng],
    [maxLat, maxLng],
  ];

  // first, check if point is inside bbox
  if (point[0] >= minLat && point[0] <= maxLat && point[1] >= minLng && point[1] <= maxLng) {
    return 0;
  }

  const distances = bboxPoints.map((bboxPoint) => getCoordinatesDistanceUsingHaversine(...point, ...bboxPoint));
  return Math.min(...distances);
};

export const sanitizeHTML = (rawHtml: string): string => {
  const html = parseHTML(rawHtml);

  // convert all headings to h2
  html.querySelectorAll('h1, h3, h4, h5, h6').forEach((el) => {
    el.tagName = 'h2';
  });

  // convert ordered lists to unordered lists
  html.querySelectorAll('ol').forEach((el) => {
    el.tagName = 'ul';
  });

  // remove spans but keep their content
  html.querySelectorAll('span').forEach((el) => {
    el.replaceWith(...el.childNodes);
  });

  // if there's text that's both a h2 heading and bold, remove the bold
  const h2Nodes = html.querySelectorAll('h2');
  h2Nodes.forEach((h2) => {
    const parent = h2.parentNode;
    const isWrappedInBold = parent?.tagName?.toUpperCase() === 'STRONG' || parent?.tagName?.toUpperCase() === 'B';
    const containsBold = h2.querySelector('strong') || h2.querySelector('b');

    if (isWrappedInBold || containsBold) {
      // Create a new strong element with the h2's text
      const strongNode = parseHTML(`<strong>${h2.text}</strong>`);

      if (isWrappedInBold) {
        // Replace the parent (strong/b) node with the new strong node
        parent.replaceWith(strongNode);
      } else if (containsBold) {
        // Replace the h2 node with the new strong node
        h2.replaceWith(strongNode);
      }
    }
  });

  // check for <a> tags with hrefs starting with file:// and replace the href with the text content
  html.querySelectorAll('a').forEach((a) => {
    const href = a.getAttribute('href');
    if (href?.startsWith('file://')) {
      a.setAttribute('href', a.innerText.includes('@') ? `mailto:${a.innerText}` : a.innerText);
    }
  });

  // check every node if it's allowed and if not, replace it with a p if it's a div, or otherwise, remove the tag and replace it with the content
  html.querySelectorAll('*').forEach((el) => {
    if (!Constants.ALLOWED_DESCRIPTION_HTML_TAGS.includes(el.tagName.toLowerCase())) {
      if (el.tagName.toLowerCase() === 'div') {
        el.tagName = 'p';
      } else {
        el.replaceWith(...el.childNodes);
      }
    }
  });

  return html.toString();
};

export const cleanJobDescriptionHtml = (html: string) => {
  // check if description is valid HTML & only contains allowed tags
  if (!validateHTML(html)) {
    return { invalidTags: [], parsed: null, plain: null };
  }

  const descriptionHtml = parseHTML(html).removeWhitespace();
  const sanitizedHtml = parseHTML(sanitizeHTML(descriptionHtml.outerHTML));

  const allowedTags = ['br', 'p', 'b', 'strong', 'ul', 'ol', 'li', 'h2', 'a'];
  const invalidTags = sanitizedHtml
    .querySelectorAll('*')
    .filter((el) => !allowedTags.includes(el.tagName.toLowerCase()));

  const parsedHtml = sanitizedHtml.outerHTML;
  const parsedPlain = sanitizedHtml.text.replace(/\n/g, ' ');

  return {
    invalidTags,
    parsed: invalidTags.length > 0 ? null : parsedHtml,
    plain: invalidTags.length > 0 ? null : parsedPlain,
  };
};

export const parseDatabaseBboxToObjectBbox = (bbox: number[]) => {
  if (bbox.length !== 4) {
    throw new Error('Invalid bbox');
  }

  return {
    ne: {
      lng: bbox[2],
      lat: bbox[3],
    },
    sw: {
      lng: bbox[0],
      lat: bbox[1],
    },
  };
};

export const parseObjectBboxToDatabaseBbox = (bbox: {
  ne: { lng: number; lat: number };
  sw: { lng: number; lat: number };
}): [number, number, number, number] => {
  return [bbox.sw.lng, bbox.sw.lat, bbox.ne.lng, bbox.ne.lat];
};

export const filterAsync = async <T>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => Promise<boolean>
): Promise<T[]> => {
  const filterMap = await Promise.all(array.map(callbackfn));
  return array.filter((value, index) => filterMap[index]);
};
