import Button from '../../components/Button';
import useDashboardForgotPasswordController from '../../controllers/Dashboard/ForgotPassword';
import Note from '../../components/Note';

const DashboardForgotPasswordView = () => {
  const controller = useDashboardForgotPasswordController();

  return (
    <div className="w-full lg:w-3/4">
      {controller.error && (
        <Note
          type="error"
          message={controller.error.map((e) => (
            <p>{e}</p>
          ))}
          className="mb-8"
        />
      )}
      <div>
        Passwort vergessen? Kein Problem!
        <br />
        Wir senden Ihnen einen Link an <strong>{controller.advertiser?.email}</strong>, mit dem Sie ein neues Passwort
        erzeugen können.
      </div>
      <div className="flex flex-row justify-between mt-12 w-full">
        <Button
          title="abbrechen"
          type="button"
          onClick={controller.handleCancel}
          kind="solid-secondary"
          disabled={controller.isSending}
        />
        <Button
          title="weiter"
          type="submit"
          disabled={controller.isSending}
          onClick={() => controller.handleSubmit()}
        />
      </div>
    </div>
  );
};

export default DashboardForgotPasswordView;
