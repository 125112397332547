import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import emojiRegex from 'emoji-regex';
import { useImmer } from 'use-immer';
import { useGetLogo, useGetOwnAdvertiser } from '../../hooks/query/Advertiser';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { AdvertiserType, JobState, PaymentMethod, RemoteOption } from '@prisma/client';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { MainOutletContext } from '../../MainOutlet';
import { useSearchPlace } from '../../hooks/query/Geo';
import { useDebounce } from 'react-use';
import { useCreateJob, useGetJobById, useGetOwnJobs } from '../../hooks/query/Job';
import { WYSIWYGEditorRef } from '@jobmatic/web/components';
import { JobEditorFields } from '../../components/JobEditor';
import dayjs from 'dayjs';
import { useGetServerConfig } from '../../hooks/query/System';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useCapturePaypalPayment } from '../../hooks/query/Invoice';
import { useVerifyCoupon } from '../../hooks/query/Coupon';
import { TRPCClientError } from '@trpc/client';

type CreateOrderData = Parameters<NonNullable<React.ComponentProps<typeof PayPalButtons>['createOrder']>>[0];
type CreateOrderActions = Parameters<NonNullable<React.ComponentProps<typeof PayPalButtons>['createOrder']>>[1];
type OnApproveData = Parameters<NonNullable<React.ComponentProps<typeof PayPalButtons>['onApprove']>>[0];
// type OnApproveActions = Parameters<NonNullable<React.ComponentProps<typeof PayPalButtons>['onApprove']>>[1];
type Field = keyof JobEditorFields | 'vatId' | 'terms';

const useDashboardCreateJobListingController = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { service } = useOutletContext<MainOutletContext>();

  const handleCreateJobError = useCallback((e: any) => {
    if (e instanceof TRPCClientError && e.data?.apiError) {
      const errorCode = e.data.apiError.code as keyof typeof ErrorMessages;
      if (errorCode === 'invalid_vat_id') {
        setErrorFields(['vatId']);
        setError([
          <>
            Die Umsatzsteuer-ID ist ungültig oder nicht auf die angegebene Adresse registriert.{' '}
            <a href="/dashboard/rechnungsdaten" target="_blank" className="underline cursor-pointer">
              Bitte prüfen Sie die Daten
            </a>
            .
          </>,
        ]);
        return;
      }
    }
    setError([transformTRPCErrorToMessage(e)]);
  }, []);

  const { data: serverConfig } = useGetServerConfig();
  const { data: advertiser } = useGetOwnAdvertiser();
  const { data: ownJobs } = useGetOwnJobs(null);
  const { data: logo } = useGetLogo(advertiser?.id || 0, { enabled: !!advertiser });
  const { mutate: searchPlace } = useSearchPlace({
    onSuccess: (data) => {
      const newSearchResults = data
        .map((place) => {
          const region = place.context?.find((c) => c.type === 'region')?.name;
          return {
            id: place.id,
            city: place.shortName,
            region: region ?? null,
            coordinates: place.coordinates,
            bbox: place.bbox,
          };
        })
        .filter((place) => !jobEditorState.locations.find((l) => l.id === place.id))
        .slice(0, 5);

      updateJobEditorState((draft) => {
        draft.searchResults = newSearchResults;
      });
      if (!jobEditorState.hoveredSearchLocation && newSearchResults.length > 0) {
        updateJobEditorState((draft) => {
          draft.hoveredSearchLocation = newSearchResults[0].id;
        });
      } else if (!newSearchResults.find((c) => c.id === jobEditorState.hoveredSearchLocation)) {
        updateJobEditorState((draft) => {
          draft.hoveredSearchLocation = null;
        });
      }
    },
  });
  const {
    isLoading: isCreatingJob,
    mutate: createJob,
    mutateAsync: createJobAsync,
  } = useCreateJob({
    onSuccess: (data) => {
      if (data) navigate(`/dashboard/job-inseriert` + (data.state === JobState.ACTIVE_UNCHECKED ? '?on=1' : ''));
    },
    onError: handleCreateJobError,
  });
  const { isLoading: isCapturingPaypalPayment, mutate: capturePaypalPayment } = useCapturePaypalPayment({
    onSuccess: () => {
      handleCreateJob();
    },
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
  });
  const { isLoading: isVerifyingCoupon, mutate: verifyCoupon } = useVerifyCoupon({
    onSuccess: (data) => {
      setCouponDiscount(data.discountPercentage);
      setShowCouponModal(false);
      setCouponCode(data.code);
      setCouponError(null);
    },
    onError: (e) => setCouponError(transformTRPCErrorToMessage(e)),
  });

  const [templateId, setTemplateId] = useState<number | null>(null);
  const [initializedData, setInitializedData] = useState(false);

  const requiresBaseCheck = useMemo(
    () =>
      !!advertiser?.lastUpdatedBaseAt &&
      dayjs(advertiser?.lastUpdatedBaseAt) < dayjs().subtract(serverConfig?.dataValidity || 180, 'day'),
    [advertiser, serverConfig?.dataValidity]
  );

  const requiresInvoiceCheck = useMemo(
    () =>
      !!advertiser?.lastUpdatedInvoiceAt &&
      dayjs(advertiser?.lastUpdatedInvoiceAt) < dayjs().subtract(serverConfig?.dataValidity || 180, 'day'),
    [advertiser, serverConfig?.dataValidity]
  );

  useGetJobById(templateId!, {
    enabled: templateId !== null,
    onSuccess: async (data) => {
      if (data && !initializedData) {
        const {
          title,
          descriptionHtml,
          descriptionPlain,
          workCountry,
          workLocations,
          remote,
          showEqualityNote,
          showAddress,
          minAge,
          occupationType,
          durationHours,
          isHotjob,
        } = data;
        updateJobEditorState((draft) => {
          draft.title = title.slice(0, Constants.MAX_JOB_TITLE_LENGTH);
          draft.description = descriptionHtml;
          draft.descriptionPlain = descriptionPlain;
          draft.descriptionLength = descriptionPlain.length;
          draft.country = (service.forcedCountry ?? workCountry) as keyof typeof Constants.COUNTRY_LIST;
          draft.locations = workLocations.map((l) => ({
            id: l.geoservicePlaceId,
            coordinates: { lat: l.coordinates[1], lng: l.coordinates[0] },
            bbox: Helpers.parseDatabaseBboxToObjectBbox(l.bbox),
            city: l.name,
            region: l.region,
            order: l.order,
          }));
          draft.remote = remote;
          draft.showEqualityNote = showEqualityNote;
          draft.showAddress = showAddress;
          draft.minAge = minAge;
          draft.occupationType = occupationType;
        });
        setJobOption((durationHours / 24 / 7) as 2 | 4);
        setHotJob(isHotjob);
        setInitializedData(true);

        let retries = 0;
        while (editorRef.current?.editor === null) {
          if (retries++ > 10) break;
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        editorRef.current?.editor?.commands.setContent(descriptionHtml);
      }
    },
  });

  const [jobOption, setJobOption] = useState<2 | 4 | 'FREE'>(2);
  const [hotJob, setHotJob] = useState(false);
  const [addedInitialState, setAddedInitialState] = useState(false);
  const [jobEditorState, updateJobEditorState] = useImmer<JobEditorFields>({
    title: '',
    description: '',
    descriptionPlain: '',
    descriptionLength: 0,
    country: ((service.forcedCountry ?? advertiser?.baseCountry) as keyof typeof Constants.COUNTRY_LIST | null) ?? 'DE',
    remote: service.forcedRemote ?? RemoteOption.NOT_POSSIBLE,
    showEqualityNote: true,
    showAddress: true,
    minAge: service.minAge ?? 18,
    occupationType: [],
    locations: service.defaultJobLocations.map((l) => ({
      id: `default-${l.name.replace(/[^a-z0-9]/gi, '-')}-${l.region?.replace(/[^a-z0-9]/gi, '-')}`,
      city: l.name,
      region: l.region,
      coordinates: { lat: l.coordinates[1], lng: l.coordinates[0] },
      bbox: Helpers.parseDatabaseBboxToObjectBbox(l.bbox),
      order: 1,
    })),
    locationSearch: '',
    searchResults: [],
    hoveredSearchLocation: null,
  });
  const [vatId, setVatId] = useState('');
  const [invoiceNote, setInvoiceNote] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.INVOICE);
  const [terms, setTerms] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [errorFields, setErrorFields] = useState<Field[]>([]);
  const [error, setError] = useState<(string | React.ReactNode)[]>([]);
  const [paypalOrderId, setPaypalOrderId] = useState<string | null>(null);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponDiscount, setCouponDiscount] = useState<number | null>(null);
  const [couponError, setCouponError] = useState<string | null>(null);
  const [showFreeOptionModal, setShowFreeOptionModal] = useState(false);

  const editorRef = useRef<WYSIWYGEditorRef | null>(null);

  const price = useMemo(() => {
    if (!advertiser || !serverConfig) return null;
    const countryCode = (advertiser.invoiceCountry || advertiser.baseCountry) as keyof typeof Constants.COUNTRY_LIST;
    try {
      return Helpers.calculateJobPrice({
        jobOption,
        hotJob,
        countryCode,
        customerDiscount: (advertiser.discountPercentage || 0) / 100,
        couponDiscount: (couponDiscount || 0) / 100,
        service,
        advertiserType: advertiser.type,
        isBaseService: advertiser.baseServiceId === service.id,
        vatRate: serverConfig.vatRate / 100,
      });
    } catch (e) {
      return null;
    }
  }, [jobOption, hotJob, advertiser, service, couponDiscount, serverConfig]);

  useEffect(() => {
    if (searchParams.has('templateId')) {
      const template = searchParams.get('templateId');
      if (!/^\d+$/.test(template || '') || !template?.length) return;
      setTemplateId(parseInt(template));
      setSearchParams(new URLSearchParams());
    }

    if (showPreview) {
      setSearchParams(new URLSearchParams({ seite: '2' }));
    } else if (showSummary) {
      setSearchParams(new URLSearchParams({ seite: '3' }));
    } else {
      setSearchParams(new URLSearchParams({ seite: '1' }), { replace: true });
    }
  }, [searchParams, setSearchParams, showPreview, showSummary]);

  useEffect(() => {
    if (!advertiser) return;
    if (!addedInitialState) {
      setVatId(advertiser.invoiceVatId || '');
      if (templateId === null) {
        updateJobEditorState((draft) => {
          draft.country = (service.forcedCountry ?? advertiser.baseCountry) as keyof typeof Constants.COUNTRY_LIST;
        });
      }
      setAddedInitialState(true);
    }
    if (!advertiser.allowedPaymentMethods.includes(paymentMethod) && price?.gross !== 0) {
      setPaymentMethod(advertiser.allowedPaymentMethods[0]);
    }
    if (price?.gross === 0) setPaymentMethod(PaymentMethod.INVOICE);
  }, [advertiser, addedInitialState, paymentMethod, templateId, updateJobEditorState, price, service.forcedCountry]);

  useEffect(() => {
    if (service.priceTwoWeeks === null && jobOption === 2) setJobOption(4);
    if (service.priceFourWeeks === null && jobOption === 4) setJobOption(2);
    if (service.priceHotJob === null) setHotJob(false);

    if (jobOption === 'FREE') {
      setShowFreeOptionModal(true);
      setCouponCode('');
      setCouponDiscount(null);
      setHotJob(false);
    }
  }, [jobOption, hotJob, service]);

  useEffect(() => {
    // remove occupation types that are not available for the selected service
    if (jobEditorState.occupationType.some((o) => service.filteredOccupationTypes.includes(o))) {
      updateJobEditorState((draft) => {
        draft.occupationType = draft.occupationType.filter((o) => service.filteredOccupationTypes.includes(o));
      });
    }
  }, [jobEditorState.occupationType, service.filteredOccupationTypes, updateJobEditorState]);

  useDebounce(
    () => {
      if (jobEditorState.locationSearch.length >= 3) {
        searchPlace({
          query: jobEditorState.locationSearch,
          filter: ['city'],
          filterCountry: jobEditorState.country,
        });
      } else {
        updateJobEditorState((draft) => {
          draft.searchResults = [];
        });
      }
    },
    500,
    [jobEditorState.locationSearch]
  );

  const canUseFreeOption = useMemo(() => {
    if (!service?.freeOptionDuration || !ownJobs || !advertiser) return false;

    const freeJobs = ownJobs.jobs.filter(
      (j) => j.createdWithFreeOption && dayjs(j.createdAt).isAfter(dayjs().startOf('month'))
    ).length;

    if (freeJobs >= advertiser.freeOptionJobsPerMonth) return false;
    return true;
  }, [service, ownJobs, advertiser]);

  const vatIdIsValid = useMemo(
    () =>
      !!advertiser?.invoiceVatId?.length ||
      advertiser?.type === AdvertiserType.PRIVATE ||
      price?.gross === 0 ||
      !(
        Constants.EU_COUNTRY_CODES.includes(advertiser?.invoiceCountry || advertiser?.baseCountry || '') &&
        !vatId.length
      ),
    [advertiser, vatId, price]
  );

  const pricingCardCount = useMemo(() => {
    const checkFields = [service.priceTwoWeeks, service.priceFourWeeks, service.priceHotJob];
    if (advertiser?.freeOptionJobsPerMonth !== 0) checkFields.push(service.freeOptionDuration);

    return checkFields.filter((p) => p !== null).length;
  }, [service.priceTwoWeeks, service.priceFourWeeks, service.priceHotJob, service.freeOptionDuration, advertiser]);

  useEffect(() => {
    if (!canUseFreeOption && jobOption === 'FREE') {
      setJobOption(2);
    }
  }, [canUseFreeOption, jobOption]);

  const handleJobEditorStateChange = (newState: Partial<JobEditorFields>) => {
    updateJobEditorState((draft) => {
      Object.assign(draft, newState);

      if (newState.title) {
        // remove emojis
        const newTitle = newState.title.replace(emojiRegex(), '');
        if (newTitle.length > Constants.MAX_JOB_TITLE_LENGTH && !errorFields.includes('title')) {
          setErrorFields([...errorFields, 'title']);
        } else if (newTitle.length <= Constants.MAX_JOB_TITLE_LENGTH && errorFields.includes('title')) {
          setErrorFields(errorFields.filter((f) => f !== 'title'));
        }
        draft.title = newTitle.slice(0, Constants.MAX_JOB_TITLE_LENGTH);
      }
      if (newState.country) {
        draft.locationSearch = '';
        draft.searchResults = [];
        draft.hoveredSearchLocation = null;
        draft.locations = [];
      }
    });
  };

  useEffect(() => {
    if (
      jobEditorState.descriptionLength > 0 &&
      jobEditorState.descriptionLength < Constants.MAX_JOB_DESCRIPTION_LENGTH &&
      errorFields.includes('description')
    ) {
      setErrorFields(errorFields.filter((f) => f !== 'description'));
    }
  }, [jobEditorState.descriptionLength, errorFields]);

  useEffect(() => {
    if (!jobEditorState.locationSearch.length) {
      updateJobEditorState((draft) => {
        draft.searchResults = [];
        draft.hoveredSearchLocation = null;
      });
    }
  }, [jobEditorState.locationSearch, updateJobEditorState]);

  useEffect(() => {
    if (error) Helpers.scrollToTop();
  }, [error]);

  const openCouponModal = () => {
    setShowCouponModal(true);
  };

  const handleBreakDescriptionCharacterLimit = () => {
    setErrorFields([...errorFields, 'description']);
  };

  const handleCreateJob = () => {
    if (!advertiser || !price) return;
    createJob({
      advertiserId: advertiser.id,
      title: jobEditorState.title.trim(),
      descriptionHtml: jobEditorState.description,
      descriptionPlain: jobEditorState.descriptionPlain.trim().replace(emojiRegex(), ''),
      country: jobEditorState.country,
      locations:
        jobEditorState.remote === RemoteOption.ONLY
          ? []
          : jobEditorState.locations.map((l) => ({
              geoservice: service.geoservice,
              geoserviceId: l.id,
              name: l.city,
              region: l.region,
              coordinates: l.coordinates,
              bbox: l.bbox,
              order: l.order,
            })),
      remote: jobEditorState.remote,
      minAge: jobEditorState.minAge ?? undefined,
      occupationType: jobEditorState.occupationType,
      showEqualityNote: jobEditorState.showEqualityNote,
      showAddress: jobEditorState.showAddress,
      invoiceNote:
        invoiceNote.length > 0 && advertiser.type === AdvertiserType.COMPANY && price.gross !== 0
          ? invoiceNote
          : undefined,
      jobOption: typeof jobOption === 'number' ? (jobOption.toString() as '2' | '4') : 'FREE',
      isHotjob: hotJob,
      coupon: !couponCode.trim().length || jobOption === 'FREE' ? undefined : couponCode.trim(),
      paymentMethod: price.gross === 0 ? PaymentMethod.INVOICE : paymentMethod,
      templateJobId: templateId ?? undefined,
      paypalOrderId,
      vatId: vatId?.length ? vatId : null,
    });
  };

  const handleSubmit = () => {
    if (showSummary) {
      if (!vatIdIsValid) {
        setErrorFields(['vatId']);
        setError([
          <>
            Die Umsatzsteuer-ID ist ungültig oder nicht auf die angegebene Adresse registriert.{' '}
            <a href="/dashboard/rechnungsdaten" target="_blank" className="underline cursor-pointer">
              Bitte prüfen Sie die Daten
            </a>
            .
          </>,
        ]);
        return;
      }
      if (!terms) {
        setErrorFields(['terms']);
        setError([ErrorMessages.terms_not_accepted]);
        return;
      }
      if (!advertiser) return;
      if (paymentMethod === PaymentMethod.INVOICE) handleCreateJob();
    } else if (showPreview) {
      Helpers.scrollToTop({ jump: true });
      setShowPreview(false);
      setShowSummary(true);
      setErrorFields([]);
      setError([]);
    } else {
      if (!jobEditorState.title.length) {
        setErrorFields(['title']);
        setError([ErrorMessages.missing_job_title]);
        return;
      }
      if (!jobEditorState.descriptionLength) {
        setErrorFields(['description']);
        setError([ErrorMessages.missing_job_description]);
        return;
      }
      if (jobEditorState.remote !== RemoteOption.ONLY && !jobEditorState.locations.length) {
        setErrorFields(['locations']);
        setError([ErrorMessages.missing_job_locations]);
        return;
      }
      if (!jobEditorState.occupationType.length) {
        setErrorFields(['occupationType']);
        setError([ErrorMessages.missing_occupation_types]);
        return;
      }
      Helpers.scrollToTop({ jump: true });
      setShowPreview(true);
      setShowSummary(false);
      setErrorFields([]);
      setError([]);
    }
  };

  const handleCancel = () => {
    setErrorFields([]);
    setError([]);
    if (showSummary) {
      setShowSummary(false);
      setShowPreview(true);
      Helpers.scrollToTop({ jump: true });
      return;
    }
    if (showPreview) {
      setShowPreview(false);
      setShowSummary(false);
      Helpers.scrollToTop({ jump: true });
      return;
    }
    navigate('/dashboard');
  };

  const handlePayPalClick = async (actions: { reject: () => Promise<void>; resolve: () => Promise<void> }) => {
    if (!advertiser || !price) {
      return actions.reject();
    }

    try {
      // validate job data before creating PayPal order using "validateOnly" flag
      await createJobAsync({
        advertiserId: advertiser.id,
        title: jobEditorState.title.trim(),
        descriptionHtml: jobEditorState.description,
        descriptionPlain: jobEditorState.descriptionPlain.trim().replace(emojiRegex(), ''),
        country: jobEditorState.country,
        locations:
          jobEditorState.remote === RemoteOption.ONLY
            ? []
            : jobEditorState.locations.map((l) => ({
                geoservice: service.geoservice,
                geoserviceId: l.id,
                name: l.city,
                region: l.region,
                coordinates: l.coordinates,
                bbox: l.bbox,
                order: l.order,
              })),
        remote: jobEditorState.remote,
        minAge: jobEditorState.minAge ?? undefined,
        occupationType: jobEditorState.occupationType,
        showEqualityNote: jobEditorState.showEqualityNote,
        showAddress: jobEditorState.showAddress,
        invoiceNote:
          invoiceNote.length > 0 && advertiser.type === AdvertiserType.COMPANY && price.gross !== 0
            ? invoiceNote
            : undefined,
        jobOption: typeof jobOption === 'number' ? (jobOption.toString() as '2' | '4') : 'FREE',
        isHotjob: hotJob,
        coupon: !couponCode.trim().length || jobOption === 'FREE' ? undefined : couponCode.trim(),
        paymentMethod: price.gross === 0 ? PaymentMethod.INVOICE : paymentMethod,
        templateJobId: templateId ?? undefined,
        vatId: vatId?.length ? vatId : null,
        validateOnly: true,
      });
    } catch (e) {
      handleCreateJobError(e);
      return actions.reject();
    }

    return actions.resolve();
  };

  const createPayPalOrder = async (data: CreateOrderData, actions: CreateOrderActions): Promise<string> => {
    if (!price || !advertiser) return '';

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: 'EUR',
            value: price.gross.toFixed(2),
            breakdown: {
              item_total: {
                currency_code: 'EUR',
                value: price.net.toFixed(2),
              },
              tax_total: {
                currency_code: 'EUR',
                value: price.vat.toFixed(2),
              },
            },
          },
          items: [
            {
              name: `Inserat ${service.name}: ${jobEditorState.title}`,
              quantity: '1',
              unit_amount: {
                currency_code: 'EUR',
                value: price.net.toFixed(2),
              },
              tax: {
                currency_code: 'EUR',
                value: price.vat.toFixed(2),
              },
            },
          ],
        },
      ],
    });
  };

  const approvePayPalOrder = async (data: OnApproveData) => {
    setPaypalOrderId(data.orderID);
    capturePaypalPayment({ paypalOrderId: data.orderID });
  };

  const handlePayPalError = (err: any) => {
    console.error('handlePayPalError', err);
    setError([ErrorMessages.paypal_error]);
  };

  const handleCancelCoupon = () => {
    setCouponCode('');
    setShowCouponModal(false);
    setCouponError(null);
  };

  const handleSubmitCoupon = () => {
    if (!couponCode.trim().length) return;
    verifyCoupon({ code: couponCode.trim() });
  };

  const handleRemoveCoupon = () => {
    setCouponCode('');
    setCouponDiscount(null);
  };

  return {
    advertiser,
    service,
    jobEditorState,
    requiresBaseCheck,
    requiresInvoiceCheck,
    isCreatingJob,
    vatIdIsValid,
    pricingCardCount,
    price,
    canUseFreeOption,
    logo,
    jobOption,
    setJobOption,
    hotJob,
    setHotJob,
    errorFields,
    error,
    showPreview,
    showSummary,
    vatId,
    setVatId,
    invoiceNote,
    setInvoiceNote,
    paymentMethod,
    setPaymentMethod,
    terms,
    setTerms,
    editorRef,
    openCouponModal,
    handleJobEditorStateChange,
    handleSubmit,
    handleCancel,
    handleBreakDescriptionCharacterLimit,
    paypalClientId: serverConfig?.paypalClientId ?? null,
    vatRate: serverConfig?.vatRate ?? 0,
    maintenanceMode: serverConfig?.maintenanceEnabled ? serverConfig.maintenanceReason : false,
    handlePayPalClick,
    createPayPalOrder,
    approvePayPalOrder,
    handlePayPalError,
    isCapturingPaypalPayment,
    showCouponModal,
    setShowCouponModal,
    handleCancelCoupon,
    handleSubmitCoupon,
    handleRemoveCoupon,
    couponCode,
    setCouponCode,
    couponDiscount,
    couponError,
    isVerifyingCoupon,
    showFreeOptionModal,
    setShowFreeOptionModal,
  };
};

export default useDashboardCreateJobListingController;
