import { useEffect, useState } from 'react';
import { useGetOwnAdvertiser, useUpdateBaseData } from '../../../hooks/query/Advertiser';
import { useNavigate } from 'react-router-dom';
import { Helpers } from '@jobmatic/shared/utils';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { trpc } from '../../../utils/trpc';

const useDashboardSettingsChangeEmailController = () => {
  const navigate = useNavigate();
  const { data: advertiser } = useGetOwnAdvertiser();
  const {
    mutate: updateBaseData,
    isLoading: isSubmitting,
    isSuccess: success,
  } = useUpdateBaseData({
    onSuccess: () => {
      trpc.auth.logout.mutate();
    },
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
  });
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string[] | null>(null);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!advertiser) return;

    e.preventDefault();
    updateBaseData({ advertiserId: advertiser.id, email, password });
  };

  const handleCancel = () => {
    navigate('/dashboard/einstellungen');
  };

  const handleGoToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    if (error) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error]);

  useEffect(() => {
    if (success) setError(null);
  }, [success]);

  return {
    advertiser,
    isSubmitting,
    success,
    email,
    setEmail,
    password,
    setPassword,
    error,
    passwordVisible,
    setPasswordVisible,
    handleSubmit,
    handleCancel,
    handleGoToHome,
  };
};

export default useDashboardSettingsChangeEmailController;
