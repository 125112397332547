import { Link } from 'react-router-dom';
import InputGroup from '../../../components/InputGroup';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import useDashboardSettingsDeleteAccountController from '../../../controllers/Dashboard/Settings/DeleteAccount';
import Note from '../../../components/Note';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

const DashboardSettingsDeleteAccountView: React.FC = () => {
  const controller = useDashboardSettingsDeleteAccountController();

  return (
    <div className="w-full lg:w-3/4">
      {controller.error && (
        <Note
          type="error"
          message={controller.error.map((e) => (
            <p>{e}</p>
          ))}
          className="mb-8"
        />
      )}
      <div>
        <p>Wenn Sie Ihren Account löschen, werden alle Anzeigen und Ihre Daten unwiderruflich gelöscht.</p>
        <p>
          Wir würden gerne noch erfahren, warum Sie uns verlassen möchten. Vielleicht können Sie uns dies in wenigen
          Stichworten mitteilen. Vielen Dank vorab!
        </p>
      </div>
      <form className="flex flex-col gap-4 mt-16" onSubmit={controller.handleSubmit}>
        <InputGroup label="Löschgrund" horizontal>
          <Input value={controller.deleteReason} onChange={controller.setDeleteReason} type="text" autoComplete="off" />
        </InputGroup>
        <InputGroup
          label="Passwort*"
          horizontal
          helper={
            <>
              <Link to="/dashboard/passwort-vergessen" className="underline">
                Passwort vergessen
              </Link>
              ?
            </>
          }>
          <Input
            value={controller.password}
            onChange={controller.setPassword}
            type={controller.passwordVisible ? 'text' : 'password'}
            append={
              controller.passwordVisible ? (
                <EyeSlashIcon className="w-5 h-5 cursor-pointer" onClick={() => controller.setPasswordVisible(false)} />
              ) : (
                <EyeIcon className="w-5 h-5 cursor-pointer" onClick={() => controller.setPasswordVisible(true)} />
              )
            }
            autoComplete="current-password"
          />
        </InputGroup>
        <div className="flex flex-row justify-between mt-12 w-full">
          <Button
            title="abbrechen"
            type="button"
            onClick={controller.handleCancel}
            kind="solid-secondary"
            disabled={controller.isDeleting}
          />
          <Button
            title="Account löschen"
            type="submit"
            disabled={controller.isDeleting}
            className={clsx(controller.isDeleting && '!cursor-wait')}
          />
        </div>
      </form>
    </div>
  );
};

export default DashboardSettingsDeleteAccountView;
