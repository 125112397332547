import Button from '../components/Button';
import useForgotPasswordController from '../controllers/ForgotPassword';
import Input from '../components/Input';
import InputGroup from '../components/InputGroup';
import Note from '../components/Note';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

const ForgotPasswordView = () => {
  const controller = useForgotPasswordController();

  return (
    <div className="w-full lg:w-3/4">
      {controller.error && (
        <Note
          type="error"
          message={controller.error.map((e) => (
            <p>{e}</p>
          ))}
          className="mb-8"
        />
      )}
      {!controller.showChangeFlow ? (
        controller.sentEmail ? (
          <>
            <div>
              <p>
                Falls Ihre E-Mail-Adresse <strong>{controller.email}</strong> dem System bekannt ist, erhalten Sie in
                den nächsten Minuten eine E-Mail, mit der Sie ein neues Passwort erstellen können.
              </p>
              <p>Schauen Sie ggf. in Ihren Spam-Ordner.</p>
            </div>
            <Button title="Home" type="button" onClick={controller.handleCancel} className="mt-12" />
          </>
        ) : (
          <>
            <div>
              Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link, mit dem Sie ein neues Passwort
              erzeugen können.
            </div>
            <form className="flex flex-col gap-4 mt-16" onSubmit={controller.handleSendEmailSubmit}>
              <InputGroup label="E-Mail-Adresse*" horizontal>
                <Input
                  value={controller.email}
                  onChange={controller.setEmail}
                  type="email"
                  autoComplete="email"
                  maxLength={255}
                />
              </InputGroup>
              <div className="flex flex-row justify-between mt-12 w-full">
                <Button
                  title="abbrechen"
                  type="button"
                  onClick={controller.handleCancel}
                  kind="solid-secondary"
                  disabled={controller.isSending}
                />
                <Button
                  title="weiter"
                  type="submit"
                  disabled={controller.isSending}
                  className={clsx(controller.isSending && '!cursor-wait')}
                />
              </div>
            </form>
          </>
        )
      ) : (
        <>
          <div>Bitte geben Sie das gewünschte Passwort ein und wiederholen Sie die Eingabe.</div>
          <form className="flex flex-col gap-4 mt-16" onSubmit={controller.handleChangePasswordSubmit}>
            <InputGroup
              label="neues Passwort*"
              horizontal
              helper="mindestens 8 Zeichen, Buchstaben + Zahlen + Sonderzeichen">
              <Input
                value={controller.password}
                onChange={controller.setPassword}
                type={controller.currentPasswordVisible ? 'text' : 'password'}
                append={
                  controller.currentPasswordVisible ? (
                    <EyeSlashIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => controller.setCurrentPasswordVisible(false)}
                    />
                  ) : (
                    <EyeIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => controller.setCurrentPasswordVisible(true)}
                    />
                  )
                }
                autoComplete="new-password"
              />
            </InputGroup>
            <InputGroup label="neues Passwort wiederholen*" horizontal>
              <Input
                value={controller.confirmPassword}
                onChange={controller.setConfirmPassword}
                type={controller.newPasswordVisible ? 'text' : 'password'}
                append={
                  controller.newPasswordVisible ? (
                    <EyeSlashIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => controller.setNewPasswordVisible(false)}
                    />
                  ) : (
                    <EyeIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => controller.setNewPasswordVisible(true)}
                    />
                  )
                }
                autoComplete="new-password"
              />
            </InputGroup>
            <div className="flex flex-row justify-between mt-12 w-full">
              <Button
                title="abbrechen"
                type="button"
                onClick={controller.handleCancel}
                kind="solid-secondary"
                disabled={controller.isSaving}
              />
              <Button
                title="speichern"
                type="submit"
                disabled={controller.isSaving}
                className={clsx(controller.isSaving && '!cursor-wait')}
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ForgotPasswordView;
