import { Link, useLocation } from 'react-router-dom';
import { useGetCurrentService } from '../hooks/query/Service';
import { useMemo } from 'react';
import tinycolor from 'tinycolor2';

const Header: React.FC = () => {
  const { data: service } = useGetCurrentService();
  const location = useLocation();
  const textColor = useMemo(
    () => (tinycolor(service?.headerBgColor || '#fff').isDark() ? '#f8f8f8' : '#333'),
    [service?.headerBgColor]
  );

  return (
    <header
      className="__db-main-header mb-12 md:mb-20 w-full flex items-center justify-center"
      style={{ backgroundColor: service?.headerBgColor, color: textColor }}>
      <div className="__db-main-header-inner flex flex-row justify-between items-center w-full relative h-full">
        {service?.logoUrl ? (
          <Link to={location.pathname.startsWith('/dashboard') ? '/dashboard' : '/'}>
            <img src={service.logoUrl || ''} alt={`${service?.name} Logo`} className="__db-main-header-logo" />
          </Link>
        ) : (
          <div />
        )}
        <Link to={service?.url || ''} className="font-medium">
          zum Portal
        </Link>
      </div>
    </header>
  );
};

export default Header;
