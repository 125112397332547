import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import MainOutlet from './app/MainOutlet';
import DashboardMainView from './app/views/Dashboard/Main';
import SignupView from './app/views/Signup';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LoginView from './app/views/Login';
import ForgotPasswordView from './app/views/ForgotPassword';
import DashboardSettingsMainView from './app/views/Dashboard/Settings/Main';
import DashboardSettingsChangeEmailView from './app/views/Dashboard/Settings/ChangeEmail';
import ConfirmEmailChangeView from './app/views/ConfirmEmailChange';
import ActivateAccountView from './app/views/ActivateAccount';
import DashboardUploadLogoView from './app/views/Dashboard/UploadLogo';
import DashboardBaseDataView from './app/views/Dashboard/BaseData';
import DashboardInvoiceDataView from './app/views/Dashboard/InvoiceData';
import DashboardCreateJobListingView from './app/views/Dashboard/CreateJobListing';
import AccountDeletedView from './app/views/AccountDeleted';
import DashboardSettingsDeleteAccountView from './app/views/Dashboard/Settings/DeleteAccount';
import DashboardSettingsChangePasswordView from './app/views/Dashboard/Settings/ChangePassword';
import PasswordChangedView from './app/views/PasswordChanged';
import SentPasswordChangeEmailView from './app/views/SentPasswordChangeEmail';
import DashboardForgotPasswordView from './app/views/Dashboard/ForgotPassword';
import ErrorView from './app/views/Error';
import JobPublishedView from './app/views/JobPublished';
import DashboardJobsMainView from './app/views/Dashboard/Jobs/Main';
import DashboardJobsDetailsView from './app/views/Dashboard/Jobs/Details';
import DashboardJobsEditView from './app/views/Dashboard/Jobs/Edit';
import DashboardInvoicesView from './app/views/Dashboard/Invoices';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'development'
      ? undefined
      : 'https://d5d4b87e38cc4d88a627cbfcd5aa1f87@sentry.kuatsu.de/4505358345371648',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', new RegExp(`^${process.env.NX_API_BASE_URL}`)],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0, // 20% of samples in production, 100% in development
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // 10% of sessions in production, 100% in development
  replaysOnErrorSampleRate: 1.0, // Sample everything when an error happens
  environment: process.env.NODE_ENV,
});

const queryClient = new QueryClient();
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <MainOutlet />,
    children: [
      {
        path: '/',
        element: <LoginView />,
      },
      {
        path: '/fehler',
        element: <ErrorView />,
      },
      {
        path: '/registrieren',
        element: <SignupView />,
      },
      {
        path: '/passwort-vergessen',
        element: <ForgotPasswordView />,
      },
      {
        path: '/account-aktivieren',
        element: <ActivateAccountView />,
      },
      {
        path: '/neue-email',
        element: <ConfirmEmailChangeView />,
      },
      {
        path: '/account-geloescht',
        element: <AccountDeletedView />,
      },
      {
        path: '/passwort-geaendert',
        element: <PasswordChangedView />,
      },
      {
        path: '/passwort-email-verschickt',
        element: <SentPasswordChangeEmailView />,
      },
      {
        path: '/dashboard',
        element: <DashboardMainView />,
      },
      {
        path: '/dashboard/stammdaten',
        element: <DashboardBaseDataView />,
      },
      {
        path: '/dashboard/rechnungsdaten',
        element: <DashboardInvoiceDataView />,
      },
      {
        path: '/dashboard/logo',
        element: <DashboardUploadLogoView />,
      },
      {
        path: '/dashboard/anzeigen',
        element: <DashboardJobsMainView />,
      },
      {
        path: '/dashboard/anzeigen/:id',
        element: <DashboardJobsDetailsView />,
      },
      {
        path: '/dashboard/anzeigen/:id/bearbeiten',
        element: <DashboardJobsEditView />,
      },
      {
        path: '/dashboard/inserieren',
        element: <DashboardCreateJobListingView />,
      },
      {
        path: '/dashboard/rechnungen',
        element: <DashboardInvoicesView />,
      },
      {
        path: '/dashboard/job-inseriert',
        element: <JobPublishedView />,
      },
      {
        path: '/dashboard/passwort-vergessen',
        element: <DashboardForgotPasswordView />,
      },
      {
        path: '/dashboard/einstellungen',
        element: <DashboardSettingsMainView />,
      },
      {
        path: '/dashboard/einstellungen/email',
        element: <DashboardSettingsChangeEmailView />,
      },
      {
        path: '/dashboard/einstellungen/account-loeschen',
        element: <DashboardSettingsDeleteAccountView />,
      },
      {
        path: '/dashboard/einstellungen/passwort',
        element: <DashboardSettingsChangePasswordView />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={(e) => (
        <>
          <p>Fehler</p>
          <p>{JSON.stringify(e)}</p>
        </>
      )}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
);
