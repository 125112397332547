import { Link } from 'react-router-dom';
import InputGroup from '../../../components/InputGroup';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import useDashboardSettingsChangePasswordController from '../../../controllers/Dashboard/Settings/ChangePassword';
import Note from '../../../components/Note';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

const DashboardSettingsChangePasswordView: React.FC = () => {
  const controller = useDashboardSettingsChangePasswordController();

  return (
    <div className="w-full lg:w-3/4">
      {controller.error && (
        <Note
          type="error"
          message={controller.error.map((e) => (
            <p>{e}</p>
          ))}
          className="mb-8"
        />
      )}
      <div>
        <p>Hier können Sie Ihr Passwort ändern.</p>
      </div>
      <form className="flex flex-col gap-4 mt-16" onSubmit={controller.handleSubmit}>
        <InputGroup
          label="aktuelles Passwort*"
          horizontal
          helper={
            <>
              <Link to="/dashboard/passwort-vergessen" className="underline">
                Passwort vergessen
              </Link>
              ?
            </>
          }>
          <Input
            value={controller.currentPassword}
            onChange={controller.setCurrentPassword}
            type={controller.currentPasswordVisible ? 'text' : 'password'}
            append={
              controller.currentPasswordVisible ? (
                <EyeSlashIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => controller.setCurrentPasswordVisible(false)}
                />
              ) : (
                <EyeIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => controller.setCurrentPasswordVisible(true)}
                />
              )
            }
            autoComplete="current-password"
          />
        </InputGroup>
        <InputGroup label="neues Passwort*" horizontal className="mt-8">
          <Input
            value={controller.newPassword}
            onChange={controller.setNewPassword}
            type={controller.newPasswordVisible ? 'text' : 'password'}
            append={
              controller.newPasswordVisible ? (
                <EyeSlashIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => controller.setNewPasswordVisible(false)}
                />
              ) : (
                <EyeIcon className="w-5 h-5 cursor-pointer" onClick={() => controller.setNewPasswordVisible(true)} />
              )
            }
            autoComplete="new-password"
          />
        </InputGroup>
        <InputGroup label="neues Passwort wiederholen*" horizontal>
          <Input
            value={controller.newPasswordConfirmation}
            onChange={controller.setNewPasswordConfirmation}
            type={controller.newPasswordConfirmationVisible ? 'text' : 'password'}
            append={
              controller.newPasswordConfirmationVisible ? (
                <EyeSlashIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => controller.setNewPasswordConfirmationVisible(false)}
                />
              ) : (
                <EyeIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => controller.setNewPasswordConfirmationVisible(true)}
                />
              )
            }
            autoComplete="new-password"
          />
        </InputGroup>
        <div className="flex flex-row justify-between mt-12 w-full">
          <Button
            title="abbrechen"
            type="button"
            onClick={controller.handleCancel}
            kind="solid-secondary"
            disabled={controller.isSubmitting}
          />
          <Button
            title="speichern"
            type="submit"
            disabled={controller.isSubmitting}
            className={clsx(controller.isSubmitting && '!cursor-wait')}
          />
        </div>
      </form>
    </div>
  );
};

export default DashboardSettingsChangePasswordView;
