import clsx from 'clsx';
import { useState } from 'react';

interface CheckboxProps {
  label: string | React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange, containerClassName, className, disabled }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={clsx(
        'flex flex-row items-start gap-2 relative',
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        containerClassName
      )}
      onClick={disabled ? undefined : () => onChange(!checked)}>
      <div className="relative">
        <input
          type="checkbox"
          checked={checked}
          onChange={disabled ? undefined : (e) => onChange(e.target.checked)}
          onFocus={disabled ? undefined : () => setFocused(true)}
          onBlur={disabled ? undefined : () => setFocused(false)}
          className={clsx('w-6 h-6 absolute opacity-0', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
          disabled={disabled}
        />
        <div
          className={clsx(
            'w-5 h-5 rounded-sm border border-[rgba(51, 51, 51, .7)] bg-white flex items-center justify-center',
            focused && 'shadow-md',
            className
          )}
          onClick={disabled ? undefined : () => onChange(!checked)}>
          {checked && (
            <svg
              version="1.1"
              id="Ebene_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="16px"
              height="11.9px"
              viewBox="0 0 16 11.9"
              xmlSpace="preserve">
              <g>
                <path
                  d="M0.2,6.5c-0.3-0.3-0.3-0.8,0-1.1l1.1-1.1C1.6,4,2.1,4,2.4,4.3L6,7.7l7.5-7.5c0.3-0.3,0.8-0.3,1.1,0l1.1,1.1
             c0.3,0.3,0.3,0.8,0,1.1l-9.2,9.2c-0.3,0.3-0.8,0.3-1.1,0L0.2,6.5z"
                />
              </g>
            </svg>
          )}
        </div>
      </div>
      <div>{label}</div>
    </div>
  );
};

export default Checkbox;
