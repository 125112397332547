import { useQuery, useMutation } from '@tanstack/react-query';
import { UseQueryTypeHelper, UseMutationTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';

export enum SystemQueryKeys {
  GET_CONFIG = 'SYSTEM_GET_CONFIG',
}

export const useGetServerConfig = (options?: UseQueryTypeHelper<typeof trpc.system.getConfig.query>) =>
  useQuery([SystemQueryKeys.GET_CONFIG], () => trpc.system.getConfig.query(), {
    placeholderData: {
      dataValidity: 180,
      vatRate: 19,
      paypalClientId: '',
      maintenanceEnabled: false,
      maintenanceReason: '',
    },
    ...options,
  });

export const useTrackVisit = (
  options?: UseMutationTypeHelper<
    typeof trpc.system.trackUser.mutate,
    Parameters<typeof trpc.system.trackUser.mutate>[0]
  >
) => useMutation((data) => trpc.system.trackUser.mutate(data), options);
