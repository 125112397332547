import { AdvertiserType, Salutation } from '@prisma/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import { AdvertiserQueryKeys, useGetOwnAdvertiser, useUpdateBaseData } from '../../hooks/query/Advertiser';
import { TRPCClientError } from '@trpc/client';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useGetServerConfig } from '../../hooks/query/System';
import { MainOutletContext } from '../../MainOutlet';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';

type Field =
  | 'businessName'
  | 'businessAppendix'
  | 'salutation'
  | 'firstName'
  | 'lastName'
  | 'street'
  | 'zip'
  | 'city'
  | 'country'
  | 'phone';
const useDashboardBaseDataController = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { service } = useOutletContext<MainOutletContext>();

  const [initializedData, setInitializedData] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>('');
  const [businessNameAppendix, setBusinessNameAppendix] = useState<string>('');
  const [salutation, setSalutation] = useState<Salutation | null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<keyof typeof Constants.COUNTRY_LIST>('DE');
  const [phone, setPhone] = useState<string>('');
  const [error, setError] = useState<string[] | null>(null);
  const [errorFields, setErrorFields] = useState<Field[]>([]);
  const [success, setSuccess] = useState<boolean>(false);

  const { data: serverConfig } = useGetServerConfig();
  const { data: advertiser } = useGetOwnAdvertiser({
    onSuccess: (data) => {
      if (initializedData) return;
      setBusinessName(data.baseBusinessName ?? '');
      setBusinessNameAppendix(data.baseBusinessAppendix ?? '');
      setSalutation(data.baseSalutation);
      setFirstName(data.baseFirstName);
      setLastName(data.baseLastName);
      setStreet(data.baseStreet);
      setZip(data.baseZip);
      setCity(data.baseCity);
      setCountry(data.baseCountry as keyof typeof Constants.COUNTRY_LIST);
      setPhone(data.basePhone);
      setInitializedData(true);
    },
  });
  const { mutate: updateBaseData, isLoading: isSaving } = useUpdateBaseData({
    onSuccess: (data) => {
      setSuccess(true);
      setError(null);
      setErrorFields([]);
      queryClient.setQueryData([AdvertiserQueryKeys.GET_OWN], data);
    },
    onError: (e) => {
      const errors = new Set<string>();
      if (e instanceof TRPCClientError && e.data?.zodError) {
        for (const issue of e.data.zodError.issues) {
          if (issue.path[0] === 'salutation') errors.add('Bitte wählen Sie eine Anrede aus.');
          if (issue.path[0] === 'firstName') errors.add('Bitte geben Sie Ihren Vornamen ein.');
          if (issue.path[0] === 'lastName') errors.add('Bitte geben Sie Ihren Nachnamen ein.');
          if (issue.path[0] === 'street') errors.add('Bitte geben Sie Ihre Straße ein.');
          if (issue.path[0] === 'zip') errors.add('Bitte geben Sie Ihre Postleitzahl ein.');
          if (issue.path[0] === 'city') errors.add('Bitte geben Sie Ihren Wohnort ein.');
          if (issue.path[0] === 'phone') {
            if (issue.code === 'too_small') errors.add('Bitte geben Sie Ihre Telefonnummer ein.');
            else errors.add('Die angegebene Telefonnummer ist ungültig.');
          }
          if (issue.path[0] === 'email') {
            if (issue.code === 'too_small') errors.add('Bitte geben Sie Ihre E-Mail-Adresse ein.');
            else errors.add('Die angegebene E-Mail-Adresse ist ungültig.');
          }
        }

        if (errors.size > 0) {
          setError(Array.from(errors));
          setErrorFields(Array.from(errors).map((e) => e.split(' ')[1] as Field));
          return;
        }
      } else {
        setError([transformTRPCErrorToMessage(e)]);
        setErrorFields([]);
      }
    },
  });

  const requiresUserCheck = useMemo(
    () =>
      !!advertiser?.lastUpdatedBaseAt &&
      dayjs(advertiser?.lastUpdatedBaseAt) < dayjs().subtract(serverConfig?.dataValidity || 180, 'day'),
    [advertiser, serverConfig?.dataValidity]
  );

  useEffect(() => {
    if (error || success) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error, success]);

  const handleBack = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    // check if phone contains anything else than +, 0-9, (, ), -, /, space
    if (!/^[+0-9() -/]+$/.test(phone)) {
      setError(['Die angegebene Telefonnummer ist ungültig.']);
      setErrorFields(['phone']);
      return;
    }

    if (!advertiser || !salutation) return;
    setSuccess(false);
    updateBaseData({
      advertiserId: advertiser.id,
      businessName: advertiser?.type === AdvertiserType.COMPANY ? businessName.trim() : undefined,
      businessAppendix:
        advertiser?.type === AdvertiserType.COMPANY
          ? businessNameAppendix.trim().length
            ? businessNameAppendix.trim()
            : null
          : undefined,
      salutation,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      street: street.trim(),
      zip: zip.trim(),
      city: city.trim(),
      country,
      phone: Helpers.parsePhoneNumber(phone),
    });
  };

  return {
    advertiser,
    service,
    requiresUserCheck,
    salutationOptions: Constants.SALUTATION_OPTIONS,
    businessName,
    setBusinessName,
    businessNameAppendix,
    setBusinessNameAppendix,
    salutation,
    setSalutation,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    street,
    setStreet,
    zip,
    setZip,
    city,
    setCity,
    country,
    setCountry,
    phone,
    setPhone,
    email: advertiser?.email ?? '',
    error,
    errorFields,
    success,
    isSaving,
    maintenanceMode: serverConfig?.maintenanceEnabled ? serverConfig.maintenanceReason : false,
    handleBack,
    handleSubmit,
  };
};

export default useDashboardBaseDataController;
