import Button from '../components/Button';
import useConfirmEmailChangeController from '../controllers/ConfirmEmailChange';

const ConfirmEmailChangeView: React.FC = () => {
  const controller = useConfirmEmailChangeController();

  return (
    <div className="w-full lg:w-3/4">
      {controller.success && (
        <div>
          <p>
            <strong>Das hat geklappt!</strong>
          </p>
          <p>Ihre neue E-Mail-Adresse ist nun aktiv und ab sofort zum Einloggen erforderlich.</p>
        </div>
      )}
      {controller.error && <div>{controller.error}</div>}
      <Button onClick={controller.handleGoToLogin} title="Login" className="mt-12" />
    </div>
  );
};

export default ConfirmEmailChangeView;
