import { Constants } from '@jobmatic/shared/utils';
import BaseGeoservice, { Place, PlaceType } from './Base';
import { trpcGenerator } from '@jobmatic/frontend/trpc';

type MapboxPlaceType =
  | 'country'
  | 'region'
  | 'postcode'
  | 'district'
  | 'place'
  | 'locality'
  | 'neighborhood'
  | 'address'
  | 'poi'
  | 'poi.landmark';
export default class MapboxGeoservice implements BaseGeoservice {
  private apiBaseUrl: string | undefined;

  constructor(apiBaseUrl?: string) {
    this.apiBaseUrl = apiBaseUrl;
  }

  private parsePlaceTypeToMapbox(type: PlaceType): MapboxPlaceType {
    switch (type) {
      case 'city':
        return 'place';
      case 'region':
        return 'region';
      default:
        throw new Error(`Unknown place type ${type}`);
    }
  }

  private parseMapboxToPlaceType(type: MapboxPlaceType): PlaceType {
    switch (type) {
      case 'place':
        return 'city';
      case 'region':
        return 'region';
      default:
        return 'unknown';
    }
  }

  public async search(
    query: string,
    {
      proximity,
      filter,
      filterCountry,
    }: {
      proximity?: { lat: number; lon: number };
      filter?: PlaceType[];
      filterCountry?: keyof typeof Constants.COUNTRY_LIST;
    }
  ): Promise<Place[]> {
    const trpc = trpcGenerator({ apiBaseUrl: this.apiBaseUrl });
    const result = await trpc.mapbox.geocode.query({
      proximity,
      filter: filter?.map((type) => this.parsePlaceTypeToMapbox(type)),
      filterCountry,
      query,
    });

    return result.features
      .filter((feature) => feature.bbox !== undefined)
      .map((feature) => ({
        id: feature.id,
        longName: feature.place_name,
        shortName: feature.text,
        coordinates: { lng: feature.center[0], lat: feature.center[1] },
        // feature.bbox is [ minX,minY,maxX,maxY ]
        bbox: {
          ne: { lng: feature.bbox![2], lat: feature.bbox![3] },
          sw: { lng: feature.bbox![0], lat: feature.bbox![1] },
        },
        context:
          feature.context?.map((context) => ({
            id: context.id,
            type: this.parseMapboxToPlaceType(context.id.split('.')[0] as MapboxPlaceType),
            name: context.text,
          })) ?? [],
      }));
  }
}
