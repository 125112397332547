import { Editor, EditorContent, Extension, Extensions, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import Link from '@tiptap/extension-link';
import Bold from '@tiptap/extension-bold';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import CharacterCount from '@tiptap/extension-character-count';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import { Tooltip } from 'react-tooltip';
import { Helpers } from '@jobmatic/shared/utils';
import { Plugin } from '@tiptap/pm/state';

const CustomBulletList = BulletList.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Alt-3': () => this.editor.commands.toggleBulletList(),
    };
  },
});

const MsWordPasteHandlerExtension = Extension.create({
  name: 'msWordPasteHandler',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          transformPastedHTML(html) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');

            const msoListParagraphs = doc.querySelectorAll(
              '.MsoListParagraph, .MsoListParagraphCxSpFirst, .MsoListParagraphCxSpMiddle, .MsoListParagraphCxSpLast'
            );

            if (msoListParagraphs.length) {
              let listStack: HTMLElement[] = [];
              let listType = 'ul';

              msoListParagraphs.forEach((p) => {
                if (p.classList.contains('MsoListParagraphCxSpFirst')) {
                  listStack = [];

                  const listMarkerText = p.querySelector('span > span')?.textContent ?? p.textContent;

                  listType = /^\d+\.?/.test(listMarkerText ?? '') ? 'ol' : 'ul';
                }

                // const styleString = p.getAttribute('style');
                // const matches = styleString?.match(/mso-list:[^;]*level(\d+)/);
                // const level = parseInt(matches?.[1] ?? '0', 10);
                // we don't allow nested lists for now
                const level = 1;

                while (level > listStack.length) {
                  const newList = doc.createElement(listType);

                  if (listStack.length > 0) {
                    listStack[listStack.length - 1].appendChild(newList);
                  }
                  listStack.push(newList);
                }

                while (level < listStack.length) {
                  listStack.pop();
                }

                p.childNodes.forEach((node) => {
                  if (node.nodeType === Node.TEXT_NODE && !!node.textContent?.trim().length) {
                    const li = doc.createElement('li');

                    // remove starting "·", "•", "-", "o", "\d+." and "a-z."
                    li.innerHTML = (node.textContent?.trim() ?? '').replace(/^([\u00B7o]|•|-|[\da-z]+[.)])/, '');
                    if (li.innerHTML.trim().length > 0) {
                      listStack[listStack.length - 1].appendChild(li);
                      p.parentNode?.replaceChild(listStack[0], p);
                    }
                  }
                });
              });
            }

            return doc.body.innerHTML;
          },
        },
      }),
    ];
  },
});

const MenuBar: React.FC<{ editor: Editor | null }> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const canToggleBold = editor.can().chain().focus().toggleBold().run();
  const canToggleHeading = editor.can().chain().focus().toggleHeading({ level: 2 }).run();

  return (
    <div className="sticky hover-none:static top-0 z-10 bg-[#f0f0f0] p-2 flex flex-row gap-2 [&>button]:bg-white [&>button]:rounded [&>button]:py-1 [&>button]:px-2 rounded-t-md">
      <button
        onClick={() => {
          editor.chain().focus().toggleBold().run();
          if (editor.isActive('heading', { level: 2 })) {
            editor.chain().focus().toggleHeading({ level: 2 }).run();
          }
        }}
        disabled={!canToggleBold}
        className={clsx(
          'text-sm',
          editor.isActive('bold') && '!bg-[rgba(0,0,0,0.7)] opacity-80 text-white',
          !canToggleBold && 'opacity-50 cursor-not-allowed'
        )}>
        Fett
      </button>
      <button
        onClick={() => {
          editor.chain().focus().toggleHeading({ level: 2 }).run();
          if (editor.isActive('bold')) {
            editor.chain().focus().toggleBold().run();
          }
        }}
        disabled={!canToggleHeading}
        className={clsx(
          'text-sm',
          editor.isActive('heading', { level: 2 }) && '!bg-[rgba(0,0,0,0.7)] opacity-80 text-white',
          !canToggleHeading && 'opacity-50 cursor-not-allowed'
        )}>
        Überschrift
      </button>
      {/*<button
        onClick={() => {
          if (!editor.isActive('link')) {
            const url = window.prompt('URL');
            if (url) {
              editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
              return;
            }
          }
          editor.chain().focus().unsetLink().run();
        }}
        className={clsx('opacity-80 text-sm', editor.isActive('link') && '!bg-[rgba(0,0,0,0.7)] opacity-80 text-white')}>
        Link
      </button>*/}
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={clsx('text-sm', editor.isActive('bulletList') && '!bg-[rgba(0,0,0,0.7)] opacity-80 text-white')}>
        Liste
      </button>
    </div>
  );
};

export interface WYSIWYGEditorRef {
  editor: Editor | null;
}
interface WYSIWYGEditorProps {
  value: string;
  onChange: (value: string, { characters, plainText }: { characters: number; plainText: string }) => void;
  onBreakCharacterLimit?: (event: 'paste' | 'input') => void;
  maxLength?: number;
  maxPasteLength?: number;
  className?: string;
  showUrlTooltips?: boolean;
}
export const WYSIWYGEditor = forwardRef<WYSIWYGEditorRef, WYSIWYGEditorProps>(
  ({ value, onChange, maxLength, onBreakCharacterLimit, className, showUrlTooltips }, ref) => {
    const [focused, setFocused] = useState(false);
    const [aTagTooltips, setATagTooltips] = useState<string[]>([]);
    const extensions: Extensions = [
      Document,
      Paragraph,
      Text,
      TextStyle,
      Link.configure({
        protocols: ['mailto', 'tel'], // http & https are enabled by default
        HTMLAttributes: {
          target: '_blank',
          rel: 'noopener nofollow',
        },
        openOnClick: !('ontouchstart' in window),
      }),
      Bold,
      Heading,
      CustomBulletList,
      ListItem,
      MsWordPasteHandlerExtension,
      HardBreak,
      History,
    ];
    if (maxLength !== undefined) {
      extensions.push(
        CharacterCount.configure({
          limit: maxLength,
        })
      );
    }

    const editor = useEditor({
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false),
      editorProps: {
        attributes: {
          class:
            'prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl min-h-[16rem] rounded-md px-3 py-2 focus:outline-none bg-white placeholder:text-[#d3d3d3] [&>h2:first-child]:mt-0 [&>h2]:text-xl [&>h2]:font-bold [&>h2]:my-2 [&_strong]:opacity-90 [&_ul]:list-disc [&_ul]:ml-6 [&_li>p]:inline [&_a]:underline [&_a]:cursor-pointer',
        },
        transformPastedHTML: Helpers.sanitizeHTML,
        handlePaste(view, event, slice) {
          const selectionLength = view.state.selection.ranges[0].$to.pos - view.state.selection.ranges[0].$from.pos;
          if (maxLength !== undefined && view.state.doc.textContent.length + slice.size - selectionLength > maxLength) {
            onBreakCharacterLimit?.('paste');
          }
        },
        handleTextInput(view, from, to, text) {
          if (maxLength !== undefined && view.state.doc.textContent.length + text.length > maxLength) {
            onBreakCharacterLimit?.('input');
          }
        },
      },
      extensions,
      content: value,
      onUpdate: ({ editor }) => {
        onChange(Helpers.sanitizeHTML(editor.getHTML()), {
          characters: editor.storage.characterCount.characters(),
          plainText: editor.state.doc.textBetween(0, editor.state.doc.content.size, ' '),
        });
      },
    });

    useImperativeHandle(ref, () => ({ editor }), [editor]);

    useEffect(() => {
      // get all a tags and set their hrefs as tooltips
      const html = value;
      const urls = html.match(/<a[^>]*href="([^"]*)"[^>]*>([\s\S]*?)<\/a>/gi)?.map((aTag) => {
        return (aTag.match(/<a[^>]*href="([^"]*)"[^>]*>([\s\S]*?)<\/a>/i)?.[1] ?? '').replace('&amp;', '&');
      });
      setATagTooltips(Array.from(new Set(urls ?? []).values()));
    }, [value]);

    return (
      <div className={clsx('border border-[rgba(0,0,0,0.25)] rounded-md', focused && 'shadow-md', className)}>
        <MenuBar editor={editor} />
        <EditorContent editor={editor} />
        {showUrlTooltips &&
          aTagTooltips.map((url) => (
            <Tooltip
              anchorSelect={`a[href="${url}"]`}
              key={url}
              style={{ zIndex: 9999, maxWidth: window.screen.width > 1024 ? '40rem' : window.screen.width - 10 }}
              openOnClick={'ontouchstart' in window}
              clickable={'ontouchstart' in window}>
              <span onClick={'ontouchstart' in window ? () => window.open(url, '_blank') : undefined}>{url}</span>
            </Tooltip>
          ))}
      </div>
    );
  }
);
