import { AdvertiserType } from '@prisma/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import { AdvertiserQueryKeys, useGetOwnAdvertiser, useUpdateInvoiceData } from '../../hooks/query/Advertiser';
import { TRPCClientError } from '@trpc/client';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useGetServerConfig } from '../../hooks/query/System';
import { MainOutletContext } from '../../MainOutlet';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';

type Field = 'businessName' | 'businessAppendix' | 'street' | 'zip' | 'city' | 'country' | 'vatId' | 'email';
const useDashboardInvoiceDataController = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { service } = useOutletContext<MainOutletContext>();
  const [businessName, setBusinessName] = useState<string>('');
  const [businessNameAppendix, setBusinessNameAppendix] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<keyof typeof Constants.COUNTRY_LIST>('DE');
  const [vatId, setVatId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string[] | null>(null);
  const [errorFields, setErrorFields] = useState<Field[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [initializedData, setInitializedData] = useState<boolean>(false);

  const { data: serverConfig } = useGetServerConfig();
  const { data: advertiser } = useGetOwnAdvertiser({
    onSuccess: (data) => {
      if (initializedData) return;
      if (data.type !== AdvertiserType.COMPANY) {
        navigate('/fehler?code=404');
        return;
      }
      setBusinessName(data.invoiceBusinessName ?? '');
      setBusinessNameAppendix(data.invoiceBusinessAppendix ?? '');
      setStreet(data.invoiceStreet ?? '');
      setZip(data.invoiceZip ?? '');
      setCity(data.invoiceCity ?? '');
      setCountry((data.invoiceCountry ?? 'DE') as keyof typeof Constants.COUNTRY_LIST);
      setVatId(data.invoiceVatId ?? '');
      setEmail(data.invoiceEmail ?? '');
      setInitializedData(true);
    },
  });
  const { mutate: updateInvoiceData, isLoading: isSaving } = useUpdateInvoiceData({
    onSuccess: (data) => {
      setSuccess(true);
      setError(null);
      setErrorFields([]);
      queryClient.setQueryData([AdvertiserQueryKeys.GET_OWN], data);
    },
    onError: (e) => {
      const errors = new Set<string>();
      if (e instanceof TRPCClientError && e.data?.zodError) {
        for (const issue of e.data.zodError.issues) {
          if (issue.path[0] === 'businessName') errors.add('Der Unternehmensname ist ungültig.');
          if (issue.path[0] === 'street') errors.add('Bitte geben Sie Ihre Straße ein.');
          if (issue.path[0] === 'zip') errors.add('Bitte geben Sie Ihre Postleitzahl ein.');
          if (issue.path[0] === 'city') errors.add('Bitte geben Sie Ihren Wohnort ein.');
          if (issue.path[0] === 'phone') {
            if (issue.code === 'too_small') errors.add('Bitte geben Sie Ihre Telefonnummer ein.');
            else errors.add('Die angegebene Telefonnummer ist ungültig.');
          }
          if (issue.path[0] === 'email') {
            if (issue.code === 'too_small') errors.add('Bitte geben Sie Ihre E-Mail-Adresse ein.');
            else errors.add('Die angegebene E-Mail-Adresse ist ungültig.');
          }
        }

        if (errors.size > 0) {
          setError(Array.from(errors));
          setErrorFields(e.data.zodError.issues.map((issue: any) => issue.path[0] as Field));
          return;
        }
      } else {
        setError([transformTRPCErrorToMessage(e)]);
        setErrorFields([]);
      }
    },
  });

  const requiresUserCheck = useMemo(
    () =>
      !!advertiser?.lastUpdatedInvoiceAt &&
      dayjs(advertiser?.lastUpdatedInvoiceAt) < dayjs().subtract(serverConfig?.dataValidity || 180, 'day'),
    [advertiser, serverConfig?.dataValidity]
  );

  const handleBack = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);

  useEffect(() => {
    if (error || success) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error, success]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!advertiser) return;
    setSuccess(false);
    updateInvoiceData({
      advertiserId: advertiser.id,
      businessName: businessName.trim(),
      businessAppendix: businessNameAppendix.trim().length ? businessNameAppendix.trim() : undefined,
      street: street.trim(),
      zip: zip.trim(),
      city: city.trim(),
      vatId: Constants.EU_COUNTRY_CODES.includes(country)
        ? !advertiser.invoiceVatId?.length && vatId.trim().length
          ? vatId.trim()
          : undefined
        : null,
      email: email.trim(),
    });
  };

  return {
    advertiser,
    service,
    requiresUserCheck,
    businessName,
    setBusinessName,
    businessNameAppendix,
    setBusinessNameAppendix,
    street,
    setStreet,
    zip,
    setZip,
    city,
    setCity,
    country,
    setCountry,
    vatId,
    setVatId,
    email,
    setEmail,
    error,
    errorFields,
    success,
    isSaving,
    maintenanceMode: serverConfig?.maintenanceEnabled ? serverConfig.maintenanceReason : false,
    handleBack,
    handleSubmit,
  };
};

export default useDashboardInvoiceDataController;
