import { useQuery } from '@tanstack/react-query';
import { UseQueryTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';

export enum ServiceQueryKeys {
  GET_CURRENT = 'SERVICE_GET_CURRENT',
}

export const useGetCurrentService = (options?: UseQueryTypeHelper<typeof trpc.service.getCurrent.query>) =>
  useQuery([ServiceQueryKeys.GET_CURRENT], () => trpc.service.getCurrent.query(), options);
