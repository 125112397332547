import type { AppRouter } from '@jobmatic/backend/trpc';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { FetchEsque } from '@trpc/client/dist/internals/types';
import superjson from 'superjson';

export const trpcGenerator = (options?: {
  apiBaseUrl?: string;
  includeCredentials?: boolean;
  fetchPolyfill?: FetchEsque;
}) => {
  let { apiBaseUrl, includeCredentials, fetchPolyfill } = options ?? {};

  if (!apiBaseUrl) {
    apiBaseUrl = process.env['NX_API_BASE_URL'] ?? 'http://localhost:8000';
  }
  if (typeof includeCredentials === 'undefined') {
    includeCredentials = true;
  }
  if (typeof fetchPolyfill === 'undefined') {
    fetchPolyfill = global.fetch ?? require('node-fetch');
  }
  if (!fetchPolyfill) {
    throw new Error('No fetch polyfill found');
  }

  return createTRPCProxyClient<AppRouter>({
    transformer: superjson,
    links: [
      httpBatchLink({
        url: apiBaseUrl,
        fetch(url, options) {
          return fetchPolyfill!(url, {
            ...options,
            credentials: includeCredentials ? 'include' : 'omit',
          });
        },
      }),
    ],
  });
};
