import { useEffect, useState } from 'react';
import { useLogin } from '../hooks/query/Auth';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { Helpers } from '@jobmatic/shared/utils';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { MainOutletContext } from '../MainOutlet';
import { TRPCClientError } from '@trpc/client';
import { useRequestNewActivationEmail } from '../hooks/query/Advertiser';

const useLoginController = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { service } = useOutletContext<MainOutletContext>();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<(string | React.ReactNode)[] | null>(null);
  const [activated, setActivated] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const { mutate: login, isLoading: isLoggingIn } = useLogin({
    onError: (e) => {
      if (e instanceof TRPCClientError && e.data?.apiError) {
        const errorCode = e.data.apiError.code as keyof typeof ErrorMessages;
        if (errorCode === 'account_not_activated') {
          setError([
            <>
              <p>
                <strong>Sie sind bereits registriert</strong>, aber Sie haben Ihren Account{' '}
                <strong>noch nicht aktiviert</strong>!
              </p>
              <p className="!mb-0">
                Falls Sie die E-Mail zur Aktivierung nicht erhalten haben, können Sie{' '}
                <span onClick={() => requestNewActivationEmail({ email })} className="underline cursor-pointer">
                  diese erneut anfordern
                </span>
                .
              </p>
            </>,
          ]);
          return;
        }
        if (errorCode === 'generic_support') {
          setError([
            <>
              Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den{' '}
              <a className="underline cursor-pointer" href={`${service.url}/kontakt`} target="_blank" rel="noreferrer">
                Support
              </a>
              .
            </>,
          ]);
          return;
        }
      }
      setError([transformTRPCErrorToMessage(e)]);
    },
    onSuccess: () => {
      navigate('/dashboard');
    },
  });

  const { mutate: requestNewActivationEmail } = useRequestNewActivationEmail({
    onSuccess: () => {
      navigate(`/registrieren?resent=${encodeURIComponent(email)}`);
    },
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
  });

  useEffect(() => {
    if (searchParams.get('activated') !== null) {
      if (searchParams.get('activated') === 'false') setError([ErrorMessages.account_activation_error]);
      else setActivated(true);
    }

    if (searchParams.get('authOtp') !== null) {
      const authOtp = searchParams.get('authOtp');
      if (authOtp) {
        login({ oneTimeLoginToken: authOtp });
      }
    }
  }, [searchParams, login]);

  const handleCancel = () => {
    window.location.href = service.url;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ email, password });
    return false;
  };

  useEffect(() => {
    if (error) {
      const note = document.getElementById('note');
      if (note) {
        const y = note.getBoundingClientRect().top + window.scrollY - 100;
        Helpers.scrollToTop({ y });
      }
    }
  }, [error]);

  return {
    service,
    email,
    setEmail,
    password,
    setPassword,
    activated,
    passwordVisible,
    setPasswordVisible,
    handleCancel,
    handleSubmit,
    error,
    isLoggingIn,
  };
};

export default useLoginController;
