import useDashboardJobsEditController from '../../../controllers/Dashboard/Jobs/Edit';
import Button from '../../../components/Button';
import Note from '../../../components/Note';
import JobEditor, { JobEditorFields } from '../../../components/JobEditor';
import clsx from 'clsx';

const DashboardJobsEditView = () => {
  const controller = useDashboardJobsEditController();

  if (!controller.service || !controller.job) return null; // TODO: Loading state
  return (
    <div className="w-full lg:w-[90%]">
      <div className="font-bold">Bearbeiten Sie hier Ihre Anzeige.</div>
      {controller.success && <Note type="success" message="Ihre Änderungen wurden gespeichert." className="mt-8" />}
      {controller.error.length > 0 && (
        <Note
          type="error"
          message={controller.error.map((e) => (
            <p>{e}</p>
          ))}
          className="mt-8"
        />
      )}
      {controller.advertiser && (
        <div className="mt-16">
          <JobEditor
            onBreakEditorCharacterLimit={controller.handleBreakDescriptionCharacterLimit}
            errorFields={controller.errorFields as (keyof JobEditorFields)[]}
            state={controller.jobEditorState}
            onChange={controller.handleJobEditorStateChange}
            editorRef={controller.editorRef}
            advertiser={controller.advertiser}
            overrideDisplayedAddress={{
              name: `${controller.job.businessName}${
                controller.job.businessAppendix ? `\n${controller.job.businessAppendix}` : ''
              }`,
              address: `${controller.job.street}\n${controller.job.zip} ${controller.job.city}`,
            }}
            logo={controller.logo ?? undefined}
            hotJob={controller.job.isHotjob}
            hideHotJobNoteOnLogo
            hideCountry={controller.service.forcedCountry !== null}
            hideRemote={controller.service.forcedRemote !== null}
          />
        </div>
      )}
      <div className="flex flex-row justify-between mt-12 w-full">
        <Button
          title="zurück"
          onClick={controller.handleBack}
          kind="solid-secondary"
          disabled={controller.isUpdating}
        />
        <Button
          title="speichern"
          onClick={controller.handleSave}
          disabled={controller.isUpdating}
          className={clsx(controller.isUpdating && '!cursor-wait')}
        />
      </div>
    </div>
  );
};

export default DashboardJobsEditView;
