import clsx from 'clsx';
import { useState } from 'react';

interface RadioButtonsProps {
  name: string;
  buttons: {
    label: string;
    value: string;
    checked: boolean;
  }[];
  onChange: (value: string) => void;
  className?: string;
  containerClassName?: string;
}

const RadioButtons: React.FC<RadioButtonsProps> = ({ name, buttons, onChange, containerClassName, className }) => {
  const [focused, setFocused] = useState<string | null>(null);
  return (
    <div className="flex flex-col gap-2">
      {buttons.map((button, idx) => (
        <div
          className={clsx('flex flex-row items-start gap-2 relative cursor-pointer', containerClassName)}
          onClick={() => onChange(button.value)}
          key={idx}>
          <div className="relative cursor-pointer">
            <input
              type="radio"
              value={button.value}
              checked={button.checked}
              onChange={(e) => onChange(e.target.value)}
              onFocus={() => setFocused(button.value)}
              onBlur={() => setFocused(null)}
              className="w-6 h-6 absolute opacity-0"
              name={name}
            />
            <div
              className={clsx(
                'w-5 h-5 rounded-full border border-[rgba(51, 51, 51, .7)] bg-white cursor-pointer flex items-center justify-center',
                focused === button.value && 'shadow-md',
                className
              )}
              onClick={() => onChange(button.value)}>
              {button.checked && <div className="w-2.5 h-2.5 rounded-full bg-black cursor-pointer"></div>}
            </div>
          </div>
          <div>{button.label}</div>
        </div>
      ))}
    </div>
  );
};

export default RadioButtons;
