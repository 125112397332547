import clsx from 'clsx';
import { forwardRef } from 'react';

interface InputProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
  onChange?: (value: string) => void;
  append?: React.ReactNode;
  containerClassName?: string;
}
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ onChange, className, disabled, append, containerClassName, ...rest }, ref) => {
    return (
      <div className={clsx('relative', containerClassName)}>
        <input
          ref={ref}
          {...rest}
          disabled={disabled}
          className={clsx(
            'w-full border border-[rgba(0,0,0,0.25)] rounded-md px-3 py-2 focus:outline-none focus:shadow-md h-11 bg-white placeholder:text-[#d3d3d3]',
            disabled && '!border-[#0000001F] !bg-white !text-[#00000055] pointer-events-none',
            className
          )}
          onChange={
            disabled
              ? () => {
                  /* noop */
                }
              : (e) => onChange?.(e.target.value)
          }
        />
        {append && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-4 opacity-30 hover:opacity-100 transition">
            {append}
          </div>
        )}
      </div>
    );
  }
);

export default Input;
