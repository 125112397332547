import { useMutation, useQuery } from '@tanstack/react-query';
import { UseMutationTypeHelper, UseQueryTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';
import { TRPCClientError } from '@trpc/client';

export enum AdvertiserQueryKeys {
  GET_OWN = 'ADVERTISER_GET_OWN',
  GET_LOGO = 'ADVERTISER_GET_LOGO',
}

export const useCreateAdvertiser = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.create.mutate,
    Parameters<typeof trpc.advertiser.create.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.create.mutate(data), options);

export const useRequestNewActivationEmail = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.requestNewActivation.mutate,
    Parameters<typeof trpc.advertiser.requestNewActivation.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.requestNewActivation.mutate(data), options);

export const useGetOwnAdvertiser = (options?: UseQueryTypeHelper<typeof trpc.advertiser.getOwn.query>) =>
  useQuery([AdvertiserQueryKeys.GET_OWN], () => trpc.advertiser.getOwn.query(), options);

export const useChangePassword = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.changePassword.mutate,
    Parameters<typeof trpc.advertiser.changePassword.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.changePassword.mutate(data), options);

export const useUpdateBaseData = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.updateBaseData.mutate,
    Parameters<typeof trpc.advertiser.updateBaseData.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.updateBaseData.mutate(data), options);

export const useUpdateInvoiceData = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.updateInvoiceData.mutate,
    Parameters<typeof trpc.advertiser.updateInvoiceData.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.updateInvoiceData.mutate(data), options);

export const useConfirmEmailChange = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.confirmEmailChange.mutate,
    Parameters<typeof trpc.advertiser.confirmEmailChange.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.confirmEmailChange.mutate(data), options);

export const useUploadLogo = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.uploadLogo.mutate,
    Parameters<typeof trpc.advertiser.uploadLogo.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.uploadLogo.mutate(data), options);

export const useDeleteLogo = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.deleteLogo.mutate,
    Parameters<typeof trpc.advertiser.deleteLogo.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.deleteLogo.mutate(data), options);

export const useGetLogo = (
  advertiserId: number,
  options?: UseQueryTypeHelper<typeof trpc.advertiser.getLogo.query | (() => null)>
) =>
  useQuery(
    [AdvertiserQueryKeys.GET_LOGO, advertiserId],
    async () => {
      try {
        return await trpc.advertiser.getLogo.query({ advertiserId });
      } catch (error) {
        if (error instanceof TRPCClientError && error.data?.code === 'NOT_FOUND') return null;
        throw error;
      }
    },
    options
  );

export const useDeleteAccount = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.delete.mutate,
    Parameters<typeof trpc.advertiser.delete.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.delete.mutate(data), options);
