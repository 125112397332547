import { useMutation } from '@tanstack/react-query';
import { UseMutationTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';
import { TRPCClientError } from '@trpc/client';

export const useLogin = (
  options?: UseMutationTypeHelper<typeof trpc.auth.login.mutate, Parameters<typeof trpc.auth.login.mutate>[0]>
) => useMutation((data) => trpc.auth.login.mutate(data), options);

export const useVerifySession = (options?: UseMutationTypeHelper<typeof trpc.auth.check.mutate, void>) =>
  useMutation(() => trpc.auth.check.mutate(), {
    retry: (failureCount, error) => {
      if (error instanceof TRPCClientError && error.data?.code === 'UNAUTHORIZED') {
        return false;
      }
      return failureCount < 3;
    },
    ...options,
  });

export const useSendForgotPasswordEmail = (
  options?: UseMutationTypeHelper<typeof trpc.auth.forgotPassword.mutate, { email: string }>
) => useMutation((data) => trpc.auth.forgotPassword.mutate(data), options);

export const useActivateAccount = (
  options?: UseMutationTypeHelper<typeof trpc.auth.activate.mutate, { token: string }>
) => useMutation((data) => trpc.auth.activate.mutate(data), options);
